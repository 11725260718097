import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {getGridData} from '../../actions'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';

import LineChart from '../../components/line_chart'
import StatsDisplay from '../../components/stats_display'
import StatsHeader from '../../components/stats_header'
import LinearProgress from "@material-ui/core/LinearProgress";
import {theme_color} from './../../tempfile'

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: theme_color
  },
  barColorPrimary: {
    backgroundColor: "#fff"
  }
})(LinearProgress);

class StatsDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filters: { }
    };
  }


  componentDidMount(){
    this.props.getGridData("stats", {});
  }

  render(){
    return (
      <div style={{flex: 1, width: '100%'}}>
          {this.props.stats ?
            <div>
              <h1 style={{color: '#3C4858'}}>Status Summary</h1>
              <StatsHeader/>
              <h1 style={{color: '#3C4858'}}>Recent Updates</h1>
              <StatsDisplay/>
              {this.props.employee.type > 1 &&
                <div style={{height: 500, width: '100%', marginBottom: 100}}>
                  <h1 style={{color: '#3C4858'}}>Jobs Summary</h1>
                  <LineChart data={this.props.stats.job_stats}/>
                </div>}
            </div>
          :<ColorLinearProgress styles={{margin: 30}} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stats: state.get.stats,
    alerts: state.get.alerts,
    last_update_stats: state.get.stats_update_time,
    employee: state.auth.employee
  }
}

export default connect(
  mapStateToProps,
  {getGridData}
)(StatsDashboard)
