import {
  UPDATE_COMPLETED,
  SWITCH_SCREEN,
  EMAIL_SENT,
  TOGGLE_BATCH_ACTIONS,
  TOGGLE_PDF,
  TOGGLE_BILLING,
  ENABLE_BATCH_ACTIONS,
  UPDATE_SELECTED_ROWS,
  ENABLE_INVOICE_ACTIONS,
	ENABLE_PAYMENT_ACTIONS,
	ENABLE_STATEMENT_ACTIONS,
	ENABLE_AFFIDAVIT_ACTIONS,
	ENABLE_EFILING_ACTIONS,
  ENABLE_DOC_UPLOAD_ACTIONS,
  ENABLE_DOC_DISPLAY_MODAL,
  ENABLE_JOB_CHARGES_MODAL,
  ENABLE_PRODUCTS_MODAL,
	DISABLE_ACTIONS,
  APPROVE_CHARGES,
  UPDATE_INDEX,
  ENABLE_VENDOR_PRICING_MODAL,
  INVOICE_PREVIEW_ON,
  CLEAR_DOC,
  SUBMIT_PAYMENT_DONE,
  ENABLE_EXCEL_MODAL,
  DUPLICATE_JOB,
  ENABLE_AFFIDAVIT_UPLOAD,
  CREATE_AMMENDMENT,
  ENABLE_ADDRESS_MODAL,
  CANCEL_SELECTION,
  ENABLE_EMAIL_MODAL,
  ASSIGN_JOBS_MODAL,
  SERVE_DOC_MODAL,
  OPEN_JOBS_PDF,
  OPEN_SEND_ALERT,
  OPEN_CLIENT_SETTINGS,
  ADD_FILTERS,
  DOCUMENTS_RETURNED,
  ADD_ROW
} from '../actions/types'

import React from 'react';

const INITIAL_STATE = {
	isUpdating: false,
  currentScreen: 0,
  emailSent: false,
  batch_actions_on: false,
  pdf_on: false,
  billing_chart: true,
  filters: {},
  rows_selected: null,
  batch_edit_menu_on: false,
  doc_upload_menu_on: false,
  efiling_menu_on: false,
  affidavit_menu_on: false,
  invoice_menu_on: false,
  payment_menu_on: false,
  statement_menu_on: false,
  job_charges_modal_on: false,
  products_modal_on: false,
  doc_modal_on: false,
  documents: null,
  current_jobs: null,
  current_clients: null,
  doc_url: null,
  vendor_pricing_modal_on: false,
  index: 0,
  invoice_preview_on: false,
  excel_modal_on: false,
  upload_job_modal_on: false,
  upload_case_modal_on: false,
  duplicate_id: null,
  affidavit_upload_on: false,
  address_modal_on: false,
  email_modal_on: false,
  email_type: null,
  assign_jobs_modal_on: false,
  serve_doc_modal_on: false,
  jobs_pdf_on: false,
  jobs_doc: null,
  open_send_alert: false,
  send_alert_info: null,
  client_settings_open: false,
  myRef: null,
  add_row_id: null
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
    case ADD_ROW:
      return { ...state, add_row_id: action.payload};
    case UPDATE_COMPLETED:
      return { ...state, isUpdating: false };
    case ADD_FILTERS:
      return {...state, filters: action.payload};
    case SWITCH_SCREEN:
      return {...state, currentScreen: action.payload.index, isUpdating: true, filters: action.payload.filters, batch_actions_on: false}
    case EMAIL_SENT:
      return { ...state, emailSent: action.payload };
    case TOGGLE_BATCH_ACTIONS:
      return { ...state, batch_actions_on: !state.batch_actions_on };
    case TOGGLE_PDF:
      return { ...state, pdf_on: !state.pdf_on, doc_url: action.payload };
    case TOGGLE_BILLING:
      return { ...state, billing_chart: !state.billing_chart };
    case UPDATE_SELECTED_ROWS:
      return { ...state, rows_selected: action.payload };
    case ENABLE_BATCH_ACTIONS:
      return { ...state, batch_edit_menu_on: true };
    case ENABLE_INVOICE_ACTIONS:
      return { ...state, invoice_menu_on: true, doc_url: null };
    case ENABLE_PAYMENT_ACTIONS:
      return { ...state, payment_menu_on: true };
    case ENABLE_STATEMENT_ACTIONS:
      return { ...state, statement_menu_on: true };
    case ENABLE_AFFIDAVIT_ACTIONS:
      return { ...state, affidavit_menu_on: true, current_clients: action.payload };
    case ENABLE_EFILING_ACTIONS:
      return { ...state, efiling_menu_on: true };
    case ENABLE_DOC_UPLOAD_ACTIONS:
      return { ...state, doc_upload_menu_on: true, current_jobs: action.payload };
    case ENABLE_DOC_DISPLAY_MODAL:
      return { ...state, doc_modal_on: true, doc_url: action.payload };
    case ENABLE_JOB_CHARGES_MODAL:
      console.log("index set to ")
      console.log(action.payload.ref.current)
      return { ...state, job_charges_modal_on: true, index: action.payload.index, myRef: action.payload.ref.current };
    case ENABLE_PRODUCTS_MODAL:
      return { ...state, products_modal_on: true };
    case DISABLE_ACTIONS:
      if(action.payload !== null && action.payload !== undefined){
        if(action.payload == "docs"){
          return { ...state, doc_upload_menu_on: false }
        }
      }else{
        return { ...state, current_jobs: null, current_clients: null, assign_jobs_modal_on: false, email_modal_on: false,
          affidavit_upload_on: false, address_modal_on: false, upload_job_modal_on: false, upload_case_modal_on: false,
          excel_modal_on: false, vendor_pricing_modal_on: false, products_modal_on: false, job_charges_modal_on: false,
          doc_modal_on: false, batch_edit_menu_on: false, invoice_menu_on: false, payment_menu_on: false, statement_menu_on: false,
          affidavit_menu_on: false, efiling_menu_on: false, doc_upload_menu_on: false, serve_doc_modal_on: false,
          jobs_pdf_on: false, jobs_doc: null, index: 0, documents: null,
        open_send_alert: false, send_alert_info: null, client_settings_open: false, myRef: null, index: 0};
      }
    case APPROVE_CHARGES:
      return { ...state };
    case UPDATE_INDEX:
      return { ...state, index: action.payload };
    case ENABLE_VENDOR_PRICING_MODAL:
      return { ...state, vendor_pricing_modal_on: true, index: action.payload};
    case INVOICE_PREVIEW_ON:
      return { ...state, invoice_preview_on: true, doc_url: action.payload};
    case CLEAR_DOC:
      return { ...state, doc_url: null}
    case SUBMIT_PAYMENT_DONE:
      return { ...state, payment_menu_on: false}
    case ENABLE_EXCEL_MODAL:
      return { ...state, excel_modal_on: true}
    case DUPLICATE_JOB:
      return { ...state, duplicate_id: action.payload}
    case ENABLE_AFFIDAVIT_UPLOAD:
      return { ...state, affidavit_upload_on: true}
    case CREATE_AMMENDMENT:
      return { ...state };
    case CANCEL_SELECTION:
      return { ...state, rows_selected: null };
    case ENABLE_ADDRESS_MODAL:
      return { ...state, address_modal_on: true}
    case ENABLE_EMAIL_MODAL:
      return { ...state, email_modal_on: true, email_type: action.payload}
    case ASSIGN_JOBS_MODAL:
      console.log("assign modal opened");
      return { ...state, assign_jobs_modal_on: true}
    case SERVE_DOC_MODAL:
      return { ...state, serve_doc_modal_on: true}
    case OPEN_JOBS_PDF:
      console.log("opening jobs pdf")
      return { ...state, jobs_pdf_on: true, jobs_doc: action.payload}
    case OPEN_SEND_ALERT:
      return { ...state, open_send_alert: true, send_alert_info: action.payload}
    case OPEN_CLIENT_SETTINGS:
      console.log("opened client settings")
      return { ...state, client_settings_open: true, index: action.payload}
    case DOCUMENTS_RETURNED:
			return { ...state, documents: action.payload};
    default:
			return { ...state };
  }
}
