import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

export function LabelledSelect({rowData, options, value}) {

  return(
    <div style={{marginTop: -20}}>
      <p style={{color:'gray', marginBottom: -2}}>Required<sup>*</sup></p>
      <Select
        value={{value: value, label: value}}
        onChange={e => rowData.onChange(e.value)}
        options={options}
     />
    </div>
  );
}

export function OptionalSelect({rowData, options, value}) {

  return(
    <div style={{marginTop: -20}}>
      <p style={{color:'gray', marginBottom: -2}}>Optional<sup>*</sup></p>
      <Select
        value={{value: value, label: value}}
        onChange={e => rowData.onChange(e.value)}
        options={options}
     />
    </div>
  );
}

export function LabelledCreatableSelect({rowData, options, value}) {
  return(
    <div style={{marginTop: -20}}>
      <p style={{color:'gray', marginBottom: -2}}>Required<sup>*</sup></p>
      <CreatableSelect
          value={{value: value, label: value}}
          onChange={e => rowData.onChange(e.value)}
          placeholder="Required"
          options={options}
       />
    </div>
  );

}

export function TooltipTypography({data}){
  return (
    <div>
      <Tooltip title={data ? data : ''}><Typography noWrap>{data ? data : ''}</Typography></Tooltip>
    </div>
  );
}
