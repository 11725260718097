import React, {Component} from "react";
// Import the useDropzone hooks from react-dropzone
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import FileViewer from 'react-file-viewer';
import {theme_color} from '../tempfile'

var mime = require('mime-types')

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return theme_color;
    }
    return '#eeeeee';
}

const excelFile = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  height: 1000;
  width: 1000;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

class DragAndDrop extends Component {
  constructor() {
    super();
    this.state = {
      files: []
    };
  }

  onDrop = (files) => {
    this.props.onDrop(files)
    this.setState({files: [...this.state.files, ...files]})
  };

  render() {
    const files = this.state.files.map(file => (
      <div style={{alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginLeft: 10, marginRight: 10}}>
        {console.log(file.type)}
          <p>{file.name.length > 20 ? file.name.substring(0,20) : file.name}</p>
          <p>{(file.size / 1000000).toFixed(2)} MB</p>
          <FileViewer
            fileType={mime.extension(file.type)}
            filePath={URL.createObjectURL(file)}
            errorComponent={null}
            onError={null}
            style={{maxHeight: file.type == excelFile ? 1000 : 200, maxWidth: file.type == excelFile ? 1000 : 200}}
          />
      </div>
    ));

    return (
      <Dropzone onDrop={this.onDrop} accept={this.props.accept}>
        {({getRootProps, getInputProps}) => (
          <section>
            <Container {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <br/><br/><br/><br/><br/><br/><br/>
              <h1>Drag 'n' drop some files here, or click to select files.</h1>
              <br/><br/><br/><br/><br/><br/><br/>
            </Container>
            <section>
              <div style={{overflowY: 'scroll', display: 'flex', flexDirection: 'horizontal', alignItems: 'center', justifyContent: 'center'}}>{files}</div>
            </section>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default DragAndDrop;
