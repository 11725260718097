import React, { Component } from 'react';
import { connect } from 'react-redux'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withSnackbar, closeSnackbar } from 'notistack';
import { createMuiTheme } from '@material-ui/core/styles';
import {addData, deleteData, editData, updateIndex,enterModal,editAffidavitRules} from '../actions'
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Checkbox,
    Link,
    TextField,
    Container,
    CircularProgress,
    MenuItem,
    GridItem,
    GridContainer,
    InputLabel,
    FormControl,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import {theme_color} from '../tempfile'

import Modal from '@material-ui/core/Modal';

import Error from '@material-ui/icons/Error';
import * as Yup from 'yup';

import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';

import { forwardRef } from 'react';

import PDFDisplay from "components/pdf_display";

import UploadDialog from "components/upload_dialog"

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik } from 'formik';
import {rule_types, iconColor} from '../tempfile'
import {LabelledSelect, LabelledCreatableSelect} from 'components/select_with_label';
import MaterialTable from 'material-table';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon
} from 'components/table_icons';

import Divider from '@material-ui/core/Divider';

export const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
  }
});

const BlueCheckbox = withStyles({
  root: {
    color: iconColor,
    '&$checked': {
      color: iconColor,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class ClientModals extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  handleChange = (label, event) => {
    console.log(label)
    let changes = {};
    changes[label] = !this.props.clients[this.props.index][label] ? "1" : "0";
    this.props.editData("client_settings", changes, [this.props.clients[this.props.index].client_id]);
  }


  handleClose = () => {
    this.setState({  });
    this.props.enterModal("dismiss");
  }

  handleKeyPress = (event) => {
    if(event.which === 37){ // Left
      console.log("left")
      if(this.props.index > 0){
        console.log(this.props.index-1)
        this.props.updateIndex(this.props.index-1);
      }
    }else if(event.which === 39){ // Right
      console.log("right")
      if(this.props.clients.length - 1 > this.props.index){
        console.log(this.props.index+1)
        this.props.updateIndex(this.props.index+1);
      }
    }
  }


  render(){

    return(
      <div>
        {/* Client Settings */}
        <Modal
            open={this.props.client_settings_open}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paper}>
              <div style={{width: 400, marginTop: 20}}>

                <h2>Client Settings</h2>
                {this.props.clients && this.props.clients[this.props.index] ?
                  <div>
                    <p><b>Client ID:</b> {this.props.clients[this.props.index].client_id}</p>
                    <p><b>Company:</b> {this.props.clients[this.props.index].company}</p>
                    <p><b>Attorney:</b> {this.props.clients[this.props.index].name}</p>

                    <h3 style={{marginBottom: 20}}>Email Preferences</h3>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <BlueCheckbox checked={this.props.clients[this.props.index].order_confirmation_email} onChange={(event) => this.handleChange('order_confirmation_email', event)} value="order_confirmation_email" />
                        }
                        label="Order Confirmation"
                      />
                      <FormControlLabel
                        control={
                          <BlueCheckbox checked={this.props.clients[this.props.index].alert_email} onChange={(event) => this.handleChange('alert_email', event)} value="alert_email" />
                        }
                        label="Alerts"
                      />
                      <FormControlLabel
                        control={
                          <BlueCheckbox checked={this.props.clients[this.props.index].job_done_email} onChange={(event) => this.handleChange('job_done_email', event)} value="job_done_email" />
                        }
                        label="Job Done"
                      />
                      <FormControlLabel
                        control={
                          <BlueCheckbox checked={this.props.clients[this.props.index].invoice_email} onChange={(event) => this.handleChange('invoice_email', event)} value="invoice_email" />
                        }
                        label="Invoices"
                      />
                      <FormControlLabel
                        control={
                          <BlueCheckbox checked={this.props.clients[this.props.index].statement_email} onChange={(event) => this.handleChange('statement_email', event)} value="statement_email" />
                        }
                        label="Statements"
                      />
                    </FormGroup>
                  </div>
                  :null}
                  <h3 style={{marginBottom: 20}}>Affidavit Rules</h3>
                  </div>
                <div>
                {this.props.clients && this.props.clients[this.props.index] ?
                    <div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                      <ThemeProvider theme={theme}>
                      <MaterialTable
                        icons={TableIcons}
                        title={"Rules"}
                        columns={[
                          {"title": "Doc Name", "field": "doc_type",
                          "headerStyle": {minWidth: 150}, "cellStyle": {minWidth: 150},
                          "editComponent": rowData => <TextField
                                                        value={rowData.rowData.doc_type}
                                                        onChange={e => rowData.onChange(e.target.value)}
                                                        required
                                                        id="standard-required"
                                                        label="Required"
                                                     />
                          },
                          {"title": "Rule", "field": "rule_id",
                          "headerStyle": {minWidth: 150}, "cellStyle": {minWidth: 150},
                          "editComponent": rowData => <LabelledSelect
                                                    rowData={rowData}
                                                    options={rule_types.map(x => {return {value: x, label: x}})}
                                                    value={rowData.rowData.rule_id}
                                                 />
                          },
                        ]}
                        data={this.props.clients[this.props.index].rules}
                        options={{
                          addRowPosition: 'first',
                          exportButton: true,
                          filtering: true,
                          search: false,
                          pageSize: 10,
                          pageSizeOptions: [10, 20],
                          headerStyle: {
                            padding: '0.5rem',
                            backgroundColor: theme_color,
                            color: 'white',
                          }
                        }}
                        editable={{
                          onRowAdd: newData =>
                            new Promise(resolve => {
                              setTimeout(() => {
                                resolve();
                                console.log(newData);
                                this.props.editAffidavitRules("add", newData, this.props.clients[this.props.index].client_id).then((res) => {
                                  if(res){
                                    this.props.enqueueSnackbar('Row added successfully', {variant: 'success'})
                                  }else{
                                    this.props.enqueueSnackbar('Could not add row', {variant: 'error'})
                                  }
                                });
                              }, 600);
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                              setTimeout(() => {
                                resolve();
                                console.log(newData)
                                // array needed because editing endpoint requires batch editing elsewhere
                                this.props.editAffidavitRules("edit", {...newData, old_doc_type: oldData.doc_type}, this.props.clients[this.props.index].client_id).then((res) => {
                                  if(res){
                                    this.props.enqueueSnackbar('Row edited successfully', {variant: 'success'})
                                  }else{
                                    this.props.enqueueSnackbar('Could not edit row', {variant: 'error'})
                                  }
                                });
                              }, 600);
                            }),
                          onRowDelete: oldData =>
                            new Promise(resolve => {
                              setTimeout(() => {
                                resolve();
                                console.log(oldData);
                                this.props.editAffidavitRules("delete", oldData, this.props.clients[this.props.index].client_id).then((res) => {
                                  if(res){
                                    this.props.enqueueSnackbar('Row deleted successfully', {variant: 'success'})
                                  }else{
                                    this.props.enqueueSnackbar('Could not delete row', {variant: 'error'})
                                  }
                                });
                              }, 600);
                            })
                        }}
                        icons={TableIcons}
                      />
                      </ThemeProvider>
                    </div>
                    :null}
                  </div>
            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.get.clients,
    index: state.update.index,
    client_settings_open: state.update.client_settings_open
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {addData, deleteData, editData, updateIndex, enterModal,editAffidavitRules}
)(ClientModals))

const contStyles = {
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 600,
    height: 600,
    backgroundColor: '#fff',
    border: '2px solid #000',
    overflowY: 'scroll',
  },
  form: {
    margin: 30,
    minWidth: 120,
  },
  button: {
    marginTop: 60
  },
  dnd: {
    width: 550,
    height: 100,
    margin: 10
  }
}
