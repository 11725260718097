import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {getGridData, addData, deleteData, editData, allowPwdReset,enterModal,checkEmail} from '../../actions'
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    RenewPwdIcon,
    BellIcon,
    AnnouncementIcon
} from 'components/table_icons';
import {rec_types, binary_types, client_types} from '../../tempfile'
import {LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ClientModals from 'components/client_modals'

function isValidEmailAddress(address) {
    if(address === undefined){return false}
    return !! address.match(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const minWidth1 = 150;

class ClientDashboard extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();


    this.state = {
      title: "Clients",
      temp: false,
      columns: [
        {"title": "Name", "field": "name",
        "filterFn" : x => `name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.name : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.name}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.name == ""}
                                   />
        },
        {"title": "Company", "field": "company",
        "filterFn" : x => `company LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.company : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledCreatableSelect
                                  rowData={rowData}
                                  value={rowData.rowData.company}
                                  options={this.props.clients.map(x => x.company).filter(onlyUnique).map((client) => {return {value: client, label: client}})}
                               />
        },
        {"title": "Type", "field": "type",
        "filterFn" : x => {
          const types = client_types.filter(c => c.includes(x)).map(c => client_types.indexOf(c)).join(',');
          return types.length > 0 ? `type IN (${types})` : 'type=-1';
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.type : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData =>  <LabelledSelect
                                  value={rowData.rowData.type}
                                  rowData={rowData}
                                  options={client_types.map((type) => {return {value: type, label: type}})}
                               />
        },
        {"title": "Email", "field": "email",
        "filterFn" : x => `email LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.email : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.email}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.email == "" || !isValidEmailAddress(rowData.rowData.email) || this.props.clients.filter(x => x.email === rowData.rowData.email && x.client_id !== rowData.rowData.client_id).length > 0}
                                   />
        },
        {"title": "Ref. Number", "field": "ref_number",
        "filterFn" : x => `ref_number LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.ref_number : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.ref_number}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Suffix", "field": "suffix",
        "filterFn" : x => `title LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.suffix : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.suffix}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Phone", "field": "phone",
        "filterFn" : x => `phone LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.phone : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.phone}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Address 1", "field": "address1",
        filtering: false,
        render: rowData => <TooltipTypography data={rowData.address1}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.address1}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Address 2", "field": "address2",
        filtering: false,
        render: rowData => <TooltipTypography data={rowData.address2}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.address2}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Address 3", "field": "address3",
        filtering: false,
        render: rowData => <TooltipTypography data={rowData.address3}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.address3}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "City", "field": "city",
        filtering: false,
        render: rowData => <TooltipTypography data={rowData.city}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.city}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "State", "field": "state",
        filtering: false,
        render: rowData => <TooltipTypography data={rowData.state}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.state}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Zipcode", "field": "zipcode",
        filtering: false,
        render: rowData => <TooltipTypography data={rowData.zipcode}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.zipcode}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Address Type", "field": "address_type",
        filtering: false,
        render: rowData => <TooltipTypography data={rowData.address_type}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData =>  <Select
                                  value={{value: rowData.rowData.address_type != undefined ? rowData.rowData.address_type : "", label: rowData.rowData.address_type != undefined ? rowData.rowData.address_type : ""}}
                                  onChange={e => rowData.onChange(e.value)}
                                  options={rec_types.map((type) => {return {value: type, label: type}})}
                               />
        },
        {"title": "FTP User", "field": "ftp_user",
        "filterFn" : x => `ftp_user LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.ftp_user : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.ftp_user}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "FTP URL", "field": "ftp_url",
        "filterFn" : x => `ftp_url LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.ftp_url : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.ftp_url}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "FTP Port", "field": "ftp_port",
        "filterFn" : x => `ftp_port LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.ftp_port : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.ftp_port}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "FTP Password", "field": "ftp_password",
        "filterFn" : x => `ftp_password LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.ftp_password : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.ftp_password}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Client ID", "field": "client_id", editable: false, defaultSort: 'desc',
        "filterFn" : x => `client_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.client_id : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}
      }
      ],
      filters: {},
      actions: [
        row => ({
            icon: () => {
                return <RenewPwdIcon />;
            },
            tooltip: 'Allow password reset?',
            onClick: (e, c) => {
                this.props.allowPwdReset({type: "client", id: c.client_id}).then((res) => {
                  if(res){
                    this.myRef.current.onQueryChange();
                    this.props.enqueueSnackbar('Password reset allowed', {variant: 'success'})
                  }else{
                    this.props.enqueueSnackbar('Could not allow pwd reset', {variant: 'error'})
                  }
                })
            },
            hidden: row.pwd_reset_on
        }),
        row => ({
            icon: () => {
                return <AnnouncementIcon />;
            },
            tooltip: 'Edit client settings',
            onClick: (e, c) => {
                this.props.enterModal("client_settings", this.props.clients.map(x => x.client_id).indexOf(c.client_id));
            },
        }),
      ]
    };
  }

  componentDidMount(){
    this.props.getGridData("clients", this.state.filters);
  }

  render(){

    return (
      <div>
      {this.props.clients ?
        <MaterialGrid
          title={this.state.title}
          columns={this.state.columns}
          add={true}
          remove={this.props.employee.type > 1}
          edit={true}
          refresh={true}
          id_column="client_id"
          data_type="clients"
          actions={this.state.actions}
          tableRef={this.myRef}
        /> : null}
        <ClientModals/>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    clients: state.get.clients,
    employee: state.auth.employee
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, allowPwdReset,enterModal,checkEmail}
)(ClientDashboard))
