import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import {getGridData, addData, deleteData, editData, toggleBatchActions, togglePDFOn, toggleInvoiceOrStatement, enterModal, addPayment, submitPayment} from '../actions'

import DragAndDrop from './drag_and_drop'

import Modal from '@material-ui/core/Modal';

import GridItem from "./Grid/GridItem.js";
import GridContainer from "./Grid/GridContainer.js";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';

import NumberFormat from 'react-number-format';

import { forwardRef } from 'react';

import PDFDisplay from "components/pdf_display";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import MaterialTable, { MTableCell } from 'material-table';
import MaterialGrid from 'components/material_grid';
import {theme_color} from '../tempfile'

import { ThemeProvider } from '@material-ui/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import {tableIcons, theme} from 'components/material_grid';
import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon,
    DoneActionIcon
} from 'components/table_icons';
import { withSnackbar, closeSnackbar } from 'notistack';
import {LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';

import CircularProgress from '@material-ui/core/CircularProgress';

import Select2 from 'react-select';
const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

var dateFormat = require('dateformat');

const today = new Date();

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class BillingModals extends Component {

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      error_message: "",
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      message2: "",
      buttonMessage: "Make changes",
      statementButtonMessage: "Generate Now",
      paymentButtonMessage: "Submit Payments",
      filteredInvoices: [],
      invoiceCompanyFieldText: "",
      startDate: null,
      endDate: null,
      paymentTotal: 0,
      temp_invoices: this.props.invoices,
      check_num: "",
      check_date: new Date(),
      loading: false
    };

  }

  handleClose = () => {
    this.setState({
      error_message: "",
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      message2: "",
      buttonMessage: "Make changes",
      statementButtonMessage: "Generate Now",
      paymentButtonMessage: "Submit Payments",
      filteredInvoices: [],
      invoiceCompanyFieldText: "",
      startDate: null,
      endDate: null,
      paymentTotal: 0,
      temp_invoices: this.props.invoices,
      check_num: "",
      check_date: new Date(),
      loading: false
    })
    this.props.enterModal("dismiss");
  }

  handleSelectChange = (event) => {
    this.setState({dropdown_value: event.target.value});
  };

  handleInputChange = (event) => {
    this.setState({input_value: event.target.value});
  }

  changeCheckNum = e => this.setState({check_num: e.target.value})
  changeCheckDate = e => this.setState({check_date: e})

  generateStatementClicked = () => {
    if(this.state.statementButtonMessage == "Generate Now"){
      this.setState({statementButtonMessage: "Confirm", message: `You are about to generate a statement for ${this.state.invoiceCompanyFieldText} covering the period ${new Date(this.state.startDate).toLocaleString("en-US", {dateStyle: "long"})} to ${new Date(this.state.endDate).toLocaleString("en-US", {dateStyle: "long"})}. Do you wish to confirm?`})
    }else{
      let client_id = -1;
      for(let i = 0; i < this.props.clients.length; i++){
        console.log("compare")
        console.log(this.props.clients[i].company)
        console.log(this.state.invoiceCompanyFieldText)
        if(this.props.clients[i].company === this.state.invoiceCompanyFieldText){
          client_id = this.props.clients[i].client_id;
          break;
        }
      }
      this.setState({loading: true})
      this.props.addData("statements", {"start": dateFormat(new Date(this.state.startDate), "yyyy-mm-dd'T'HH:mm'Z'"), "end": dateFormat(new Date(this.state.endDate), "yyyy-mm-dd'T'HH:mm'Z'"), "client_id": client_id}).then((res) => {
        if(res){
          this.props.tableRef2.current.onQueryChange();
          this.props.enqueueSnackbar(`Statement generated.`, {variant: 'success'})
          this.props.enterModal('dismiss');
        }else{
          this.props.enqueueSnackbar(`Statement generation failed: Nothing to include.`, {variant: 'error'})
        }
        this.setState({loading: false})
      })
      this.setState({statementButtonMessage: "Generate Now"})
    }
  }

  filterInvoices = (event) => {
    console.log("filter")
    console.log(this.state.invoiceCompanyFieldText)
    this.setState({invoiceCompanyFieldText: event.target.value}, () => {
      if(this.props.invoices){
        var filtered_invoices = this.props.invoices.filter((invoice) => {
          console.log(invoice.company, ': ', invoice.company == this.state.invoiceCompanyFieldText && invoice.total_remaining > 0);
          return (invoice.parent_id != undefined && this.props.invoices.filter((inv2) => invoice.parent_id == inv2.id && inv2.company == this.state.invoiceCompanyFieldText && inv2.total_remaining > 0).length > 0) || (invoice.company == this.state.invoiceCompanyFieldText && invoice.total_remaining > 0)
        })
        console.log('filtered invoices: ', filtered_invoices)
        this.setState({filteredInvoices: filtered_invoices, message: filtered_invoices.length > 0 ? `${filtered_invoices.filter((invoice) => invoice.type == "Invoice").length} invoice(s) with outstanding amounts for this firm.` : `No unpaid invoices for the selected firm.`})
      }
    })
  }


  onPaymentChange = (event) => {
    let filtered_invoices = this.state.filteredInvoices;
    filtered_invoices[event.target.index] = event.target.value*100;
    this.setState({filteredInvoices: filtered_invoices});
  }

  confirmPaymentClicked = (event) => {
    const invoice_payments = this.props.invoices.filter((invoice) => invoice.type == "Invoice").map((invoice) => invoice.payment);
    console.log('payments: ', invoice_payments);
    const total_payment = invoice_payments.reduce((a,b) => {return a + b});
    if(this.state.paymentButtonMessage == "Submit Payments" && total_payment > 0){
      this.setState({paymentButtonMessage: "Confirm", message2: `You are about to submit a payment for the total of $${(total_payment/100.0).toFixed(2)} across ${invoice_payments.filter((payment) => payment > 0).length} invoices. Do you wish to confirm?`})
    }else{
      this.props.submitPayment(this.props.invoices.filter(x => x.payment > 0), total_payment, this.state.check_num, this.state.check_date.toLocaleString("en-US", {dateStyle: "short"})).then((res) => {
        if(res){
          this.props.tableRef.current.onQueryChange();
          this.props.enqueueSnackbar(`Payment for $${(total_payment/100.0).toFixed(2)} submitted.`, {variant: 'success'})
        }else{
          this.props.enqueueSnackbar(`Payment for $${(total_payment/100.0).toFixed(2)} failed.`, {variant: 'error'})
        }
      })
    }

  }

  setFieldValue = (type, value) => {
    if(type == "startDate"){
      this.setState({"startDate": value})
    }else{
      this.setState({"endDate": value})
    }
  }

  handleSelectChange = (event) => {
    this.setState({dropdown_value: event.target.value});
  };

  render(){
    const unpaidInvoiceClients = this.props.invoices ? this.props.invoices.filter(x => x.total_remaining > 0 && x.client_id != undefined).map(x => x.client_id) : [];
    console.log('unpaid list: ', unpaidInvoiceClients);

    return(
      <div>
        {/* Record Payments */}
        <Modal
            open={this.props.payment_menu_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperXL}>
                <div style={{zIndex: 999}}>
                  <h3 style={{margin: 10}}>Select a client firm with open or past due invoices</h3>
                  <Select2
                    value={{value: this.state.invoiceCompanyFieldText, label: this.state.invoiceCompanyFieldText}}
                    onChange={(event) => {console.log(event);this.setState({invoiceCompanyFieldText: event.value}, ()=> this.filterInvoices({target: {value: this.state.invoiceCompanyFieldText}}))}}
                    options={this.props.clients ? this.props.clients.filter(x => unpaidInvoiceClients.includes(x.client_id)).map(x => x.company).filter(onlyUnique).map((client) => {return {value: client, label: client}}) : []}
                    styles={[selectStyles, {marginBottom: 30}]}
                   />
                   <p>{this.state.message}</p>
                </div>
                {this.props.invoices !== null && this.props.invoices !== undefined ?
                  <div style={{zIndex: 100, marginTop: 15}}>
                  <div style={{flex: 1}}>
                  <MaterialTable
                    columns={[
                      {"title": "Type", "field": "type"},
                      {"title": "Number", "field": "id"},
                      {"title": "Balance", "field": "total_remaining", render: rowData => `$${(rowData.total_remaining/100.0).toFixed(2)}`},
                      {"title": "Auto-Fill", "field": "total_remaining", render: rowData => <button style={{borderWidth:0}} onClick={() => {
                        this.props.addPayment({id: rowData.id, type: rowData.type, payment: (rowData.total_remaining)}).then(() => {
                          this.filterInvoices({target: {value: this.state.invoiceCompanyFieldText}})
                        })
                      }}><DoneActionIcon/></button>},
                      {"title": "New Payment", "field": "payment", render: rowData => <TextField
                        value={(rowData.payment/100.0).toFixed(2)}
                        onChange={(event) => {
                                    this.props.addPayment({id: rowData.id, type: rowData.type, payment: Number(event.target.value)*100}).then(() => {
                                      this.filterInvoices({target: {value: this.state.invoiceCompanyFieldText}})
                                    })
                                  }}
                        style={{backgroundColor: 'transparent'}}
                      />}
                    ]}
                    title={"Payment Record"}
                    data={this.state.filteredInvoices}
                    options={{
                      addRowPosition: 'first',
                      editable: false,
                      pageSize: 15,
                      pageSizeOptions: [10, 20, 50],
                      search: false,
                      toolbar: true,
                      selection: false,
                      exportButton: true,
                      filtering: false,
                      actionsHeaderIndex: 1,
                      headerStyle: {
                        width: '100%',
                        backgroundColor: theme_color,
                        color: 'white',
                      },
                      toolbarButtonAlignment: 'left',
                    }}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.parent_id)}
                    icons={TableIcons}
                  />
                  </div>
                  <FormControl style={contStyles.form}>
                    <TextField
                         name="check_num"
                         label="Check number"
                         fullWidth
                         margin="normal"
                         variant="outlined"
                         value={this.state.check_num}
                         onChange={this.changeCheckNum}
                     />
                     <MuiPickersUtilsProvider utils={DateFnsUtils}>
                       <DatePicker
                           name="startDate"
                           autoOk
                           variant="inline"
                           inputVariant="outlined"
                           label="Check Date"
                           format="yyyy-MM-dd"
                           margin="normal"
                           maxDate={today}
                           fullWidth
                           autoOk
                           value={this.state.checkDate}
                           onChange={this.changeCheckDate}
                       />
                       </MuiPickersUtilsProvider>
                  <h2>{`Total:$ ${this.props.invoices.length > 0 ? (this.props.invoices.filter((invoice) => invoice.type == "Invoice").map((invoice) => invoice.payment).reduce((a,b) => {return a + b})/100.0).toFixed(2) : 0.00}`}</h2>
                  <p>{this.state.message2}</p>
                  <Button variant="outlined"
                    disabled={this.state.check_num=="" || this.state.check_date == "" || this.props.invoices.filter(x => x.payment > 0).length == 0}
                    onClick={this.confirmPaymentClicked}>
                    {this.state.paymentButtonMessage}
                  </Button>
                  </FormControl>
                  </div>
                  :null}
            </div>
        </Modal>

        {/* Edit Products */}
        <Modal
            open={this.props.products_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperXL}>
                <MaterialGrid
                  title={"Products"}
                  columns={[
                    {"title": "Category", "field": "category",
                    "headerStyle": {width: 100}, "cellStyle": {width: 100},
                    render: rowData => <TooltipTypography data={rowData ? rowData.category : ''}/>,
                    "filterFn" : x => `category LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`},
                    {"title": "Name", "field": "name",
                    "headerStyle": {width: 200}, "cellStyle": {width: 200},
                    render: rowData => <TooltipTypography data={rowData ? rowData.name : ''}/>,
                    "filterFn" : x => `name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`},
                    {"title": "Default Price", "field": "default_price", render: rowData => `$${(rowData.default_price/100.0).toFixed(2)}`,
                    filtering: false,
                    editComponent: rowData => <TextField
                          name="default_price"
                          label="Default Price"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          value={(rowData.rowData.default_price/100.0).toFixed(2)}
                          onChange={e => rowData.onChange(parseInt(e.target.value*100))}
                      />}
                  ]}
                  data={this.props.products}
                  add={true}
                  remove={true}
                  edit={true}
                  refresh={true}
                  id_column="id"
                  data_type="products"
                  actions={null}
                  tableRef={this.myRef}
                />
            </div>
        </Modal>

        {/* Generate Statement */}
        <Modal
            open={this.props.statement_menu_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paper}>
              <h2>Generate Statement</h2>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl style={contStyles.form}>
              {this.state.loading ? <CircularProgress /> : <div>
                <p>Period</p>
                <DatePicker
                    name="startDate"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    margin="normal"
                    maxDate={today}
                    fullWidth
                    autoOk
                    value={this.state.startDate}
                    onChange={value => {
                        this.setFieldValue('startDate', value);
                    }}
                />
                <DatePicker
                    name="endDate"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="End Date"
                    format="MM/dd/yyyy"
                    margin="normal"
                    minDate={new Date(this.state.startDate)}
                    maxDate={today}
                    fullWidth
                    autoOk
                    value={this.state.endDate}
                    onChange={value => {
                        this.setFieldValue('endDate', value);
                    }}
                />
                {this.props.clients ?
                  <div>
                    <p>Firm</p>
                    <Select2
                      value={{value: this.state.invoiceCompanyFieldText, label: this.state.invoiceCompanyFieldText}}
                      onChange={(event) => {this.setState({invoiceCompanyFieldText: event.value})}}
                      options={this.props.clients.map(x => x.company).filter(onlyUnique).map((client) => {return {value: client, label: client}})}
                      styles={[selectStyles, {marginBottom: 30}]}
                   />
                  </div>
                :null}
                <p>{this.state.message}</p>
                </div>}
                <Button variant="outlined" style={{marginBottom: 60}} onClick={this.generateStatementClicked}>
                  {this.state.statementButtonMessage}
                </Button>
              </FormControl>
              </MuiPickersUtilsProvider>
            </div>
        </Modal>

        {/* Open PDF of Doc */}
        <Modal
            open={this.props.doc_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperXL}>
              <h2>{this.props.mode ? "Invoice" : "Statement"}</h2>
                <PDFDisplay width={700}/>
            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentScreen: state.update.currentScreen,
    doc_modal_on: state.update.doc_modal_on,
    mode: state.update.billing_chart,
    rows_selected: state.update.rows_selected,
    payment_menu_on: state.update.payment_menu_on,
    statement_menu_on: state.update.statement_menu_on,
    products_modal_on: state.update.products_modal_on,
    jobs: state.get.jobs,
    invoices: state.get.invoices,
    statements: state.get.statements,
    clients: state.get.clients,
    products: state.get.products,
    email_modal_on: state.update.email_modal_on,
    email_type: state.update.email_type
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, toggleBatchActions, togglePDFOn, toggleInvoiceOrStatement, enterModal, addPayment, submitPayment}
)(BillingModals))

const contStyles = {
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 500,
    height: 550,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paper2: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: '80%',
    height: '90%',
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paperXL: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 800,
    height: 600,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  form: {
    margin: 30,
    minWidth: 120,
  },
  button: {
    marginTop: 60
  }
}
