import {combineReducers} from 'redux';
import AuthenticationReducer from './authentication_reducer';
import UpdateReducer from './update_reducer';
import GetReducer from './get_reducer';
import AddReducer from './add_reducer';
import DeleteReducer from './delete_reducer';
import EditReducer from './edit_reducer';

export default combineReducers({
	auth: AuthenticationReducer,
	update: UpdateReducer,
	get: GetReducer,
	add: AddReducer,
	delete: DeleteReducer,
	edit: EditReducer
});
