import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import {getGridData, addData, deleteData, editData, toggleBatchActions, togglePDFOn, toggleInvoiceOrStatement, enterModal} from '../actions'

import Modal from '@material-ui/core/Modal';

import GridItem from "./Grid/GridItem.js";
import GridContainer from "./Grid/GridContainer.js";
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';
import { withSnackbar, closeSnackbar } from 'notistack';

import { forwardRef } from 'react';
import {rec_types} from '../tempfile'

import Select from 'react-select';

class BatchEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error_message: "",
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      buttonMessage: "Make changes",
    };

  }

  componentDidMount(){}

  getIDColumn(){
    switch(this.props.id_column){
      case 0:
        return null;
      case 1:
        return "case_id";
      case 2:
        return "job_id";
      case 3:
        return "report_id";
      case 4:
        return "client_id";
      case 5:
        return "employee_id";
      case 6:
        return "vendor_id";
      case 7:
        return this.props.mode ? "rec_id" : "statement_id";
      case 8:
        return "doc_parsing_id";
    }
  }

  getColumns = () => {
    switch(this.props.currentScreen){
      case 2:
        return [
          {"title": "Job ID", "field": "job_id", "editable": false},
          {"title": "Serve By", "field": "due_date"},
          {"title": "Vendor", "field": "vendor", options: this.props.vendors ? this.props.vendors.map((v)=> {return {value: v.company, label: v.company}}) : []},
          {"title": "Recipient", "field": "rec_name"},
          {"title": "Case Ref.", "field": "case_ref"},
          {"title": "Priority", "field": "priority", options: this.props.codes.priority ? this.props.codes.priority.map((p)=> {return {value: p.name, label: p.name}}) : []},
          {"title": "Status", "field": "job_status", "editable": false},
          {"title": "Invoice Status", "field": "invoice_status", "editable": false},
          {"title": "Affidavit Status", "field": "affidavit_status", "editable": false},
          {"title": "Attempts", "field": "attempts",  "editable": false},
          {"title": "Client Ref.", "field": "client_ref"},
          {"title": "Received", "field": "received_date", "editable": false},
          {"title": "Last Updated", "field": "last_update", "editable": false},
          {"title": "Assigned", "field": "assigned", "editable": false},
          {"title": "Acknowledged", "field": "acknowledged", "editable": false},
          {"title": "Server", "field": "server_name"},
          {"title": "Instructions", "field": "instructions"},
          {"title": "Document Type", "field": "doc_type"},
          {"title": "Case", "field": "case_id", "editable": false, options: this.props.cases ? this.props.cases.map((c)=> {return {value: c.case_id, label: `${c.case_id}: ${c.plt_name} vs. ${c.lead_defendant}`}}) : []},
          {"title": "Address 1", "field": "address1"},
          {"title": "Address 2", "field": "address2"},
          {"title": "Address 3", "field": "address3"},
          {"title": "City", "field": "city"},
          {"title": "State", "field": "state"},
          {"title": "Zip Code", "field": "zip_code"},
          {"title": "Address Type", "field": "type", options: rec_types.map((type) => {return {value: type, label: type}})},
        ];
      case 1:
        return [
          //{"title": "Client Company", "field": "client_company", options: this.props.clients.map((c)=> {return {value: c.company, label: c.company}})},
          {"title": "Attorney", "field": "attorney", options: this.props.clients ? this.props.clients.map((c)=> {return {value: c.name, label: c.name}}) : []},
          {"title": "Client Ref.", "field": "client_ref"},
          {"title": "Plaintiff", "field": "plt_name"},
          {"title": "Case No.", "field": "case_no"},
          {"title": "Lead Defendant", "field": "lead_defendant"},
          {"title": "Court", "field": "court_name", options: this.props.courts ? this.props.courts.map((court)=> {return {value: court.name, label: court.name}}) : []},
          {"title": "Client Billing", "field": "client_billing", "editable":false},
          {"title": "County Name", "field": "county_name", "editable":false},
          {"title": "State", "field": "state", "editable":false},
          {"title": "Jurisdiction", "field": "jurisdiction", "editable": true},
          {"title": "Ammendment", "field": "ammendment", "editable":false},
          {"title": "Contact Name", "field": "contact_name"},
          {"title": "Contact Email", "field": "contact_email"},
          {"title": "Date Filed", "field": "date_filed"}
        ];
      default:
        return [];
    }
  }


  handleClose = () => {

    this.state = {
      error_message: "",
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      buttonMessage: "Make changes",
    };

    this.props.enterModal("dismiss");
  }

  handleSelectChange = (event) => this.setState({dropdown_value: event.label});
  handleInputChange = (value) => this.setState({input_value: value});


  makeEdit = () => {
    if(this.state.buttonMessage == "Make changes"){
      this.setState({buttonMessage: "Confirm", message: `You are about to change column ${this.state.dropdown_value} to value \"${this.state.input_value}\" on ${this.props.rows_selected.length} rows. Do you wish to continue?`})
    }else{
      const changes = {[this.getColumns().filter(col => col.title == this.state.dropdown_value)[0].field]: this.state.input_value}
      const id_column = this.props.currentScreen == 1 ? "case_id" : "job_id";
      const ids = this.props.rows_selected.map((row) => {return row[id_column]})
      const type = this.props.currentScreen == 1 ? "cases" : "jobs";
      console.log('changes', changes)
      this.props.editData(type, changes, ids).then(res => {
        if(res){
          this.props.enqueueSnackbar('Batch update successful', {variant: 'success'})
        }else{
          this.props.enqueueSnackbar('Batch update unsuccessful', {variant: 'error'})
        }
        this.handleClose();
      })
    }
  }


  render(){

    /*
    if(this.props.currentScreen == 0){
      const check = setInterval(() => {
        if(this.props.alerts !== null && this.props.alerts.length > 0){
  				clearInterval(check);
          let my_keys = Object.keys(this.props.alerts[0])
          my_keys.splice(my_keys.indexOf("alert_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }*/

    if(this.props.currentScreen == 1){
      const check = setInterval(() => {
        if(this.props.cases !== null && this.props.cases.length > 0){
  				clearInterval(check);
          let my_keys = Object.keys(this.props.cases[0])
          my_keys.splice(my_keys.indexOf("case_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }

    if(this.props.currentScreen == 2){
      const check = setInterval(() => {
        if(this.props.jobs !== null && this.props.jobs.length > 0){
  				clearInterval(check);
          let my_keys = Object.keys(this.props.jobs[0])
          my_keys.splice(my_keys.indexOf("job_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }

    /*if(this.props.currentScreen == 3){
      const check = setInterval(() => {
        if(this.props.reports !== null && this.props.reports.length > 0){
  				clearInterval(check);
          let my_keys = Object.keys(this.props.reports[0])
          my_keys.splice(my_keys.indexOf("report_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }

    if(this.props.currentScreen == 4){
      const check = setInterval(() => {
        if(this.props.clients !== null && this.props.clients.length > 0){
  				clearInterval(check);
          let my_keys = Object.keys(this.props.clients[0])
          my_keys.splice(my_keys.indexOf("client_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }

    if(this.props.currentScreen == 5){
      const check = setInterval(() => {
        if(this.props.employees !== null && this.props.employees.length > 0){
  				clearInterval(check);
          var my_keys = Object.keys(this.props.employees[0])
          my_keys.splice(my_keys.indexOf("employee_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }

    if(this.props.currentScreen == 6){
      const check = setInterval(() => {
        if(this.props.vendors !== null && this.props.vendors.length > 0){
  				clearInterval(check);
          let my_keys = Object.keys(this.props.vendors[0])
          my_keys.splice(my_keys.indexOf("vendor_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }

    if(this.props.currentScreen == 7){
      const check = setInterval(() => {
        if(this.props.invoices !== null && this.props.invoices.length > 0){
          clearInterval(check);
          let my_keys = Object.keys(this.props.invoices[0])
          my_keys.splice(my_keys.indexOf("rec_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }

    if(this.props.currentScreen == 8){
      const check = setInterval(() => {
        if(this.props.doc_parsing !== null && this.props.doc_parsing.length > 0){
  				clearInterval(check);
          let my_keys = Object.keys(this.props.doc_parsing[0])
          my_keys.splice(my_keys.indexOf("doc_id"), 1);
          my_keys.splice(my_keys.indexOf("tableData"), 1);
          this.setState({keys: my_keys});
        }
      }, 100);
    }*/

    return(
      <Modal
          open={this.props.batch_edit_menu_on}
          onClose={this.handleClose}
          style={contStyles.container}
        >
          <div style={contStyles.paper}>
            <div style={contStyles.title}>
              <h2>Select the column to edit and its new value</h2>
            </div>
            <FormControl>
              <p>Column</p>
                <Select
                  value={{value: this.state.dropdown_value, label: this.state.dropdown_value}}
                  onChange={this.handleSelectChange}
                  label="Column"
                  style={{width: 300}}
                  options={this.getColumns().filter(col => col.editable == undefined).map((col) => {return{value: col.field, label: col.title}})}
                />
              {this.state.dropdown_value !== "" ?
              this.getColumns().filter(col => col.title == this.state.dropdown_value)[0] &&
              this.getColumns().filter(col => col.title == this.state.dropdown_value)[0].options !== undefined ?
                <div>
                  <p>Column</p>
                  <Select
                    value={{value: this.state.input_value, label: this.state.input_value}}
                    onChange={(event) => this.handleInputChange(event.value)}
                    label="Column"
                    style={{width: 300}}
                    options={this.getColumns().filter(col => col.title == this.state.dropdown_value)[0].options}
                  />
                </div>
                :
                <TextField
                  label="Value"
                  value={this.state.input_value}
                  onChange={(event) => this.handleInputChange(event.target.value)}
                  margin="normal"
                  style={{width: 300}}
                />
                :
                null}
              <p style={{width: 300}}>{this.state.message}</p>
              <Button variant="outlined" style={{width: 300}} onClick={this.makeEdit} disabled={this.state.input_value == ""}>
                {this.state.buttonMessage}
              </Button>
            </FormControl>
          </div>
        </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    batch_edit_menu_on: state.update.batch_edit_menu_on,
    currentScreen: state.update.currentScreen,
    pdf_on: state.update.pdf_on,
    mode: state.update.billing_chart,
    rows_selected: state.update.rows_selected,

    jobs: state.get.jobs,
    cases: state.get.cases,
    reports: state.get.reports,
    doc_parsing: state.get.doc_parsing,
    alerts: state.get.alerts,
    employees: state.get.employees,
    clients: state.get.clients,
    vendors: state.get.vendors,
    courts: state.get.courts,
    products: state.get.products,
    invoices: state.get.invoices,
    statements: state.get.statements,
    codes: state.get.codes,
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, toggleBatchActions, togglePDFOn, toggleInvoiceOrStatement, enterModal}
)(BatchEditModal))

const contStyles = {
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 550,
    height: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
  },
  title: {
    height: 100,
  },
  body:{
    height: 100,
  },
}
