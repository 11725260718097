import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {TextField, Popover, Typography, Box} from '@material-ui/core';
import {getGridData, addData, editData} from '../../actions'
import {LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon,
    AssignmentTurnedInIcon
} from 'components/table_icons';
import Select from 'react-select';

import MouseOverPopover from 'components/popover_on_hover'

import {report_types, NYC_Zipcodes} from '../../tempfile'

const minWidth1 = 150;

class ReportDashboard extends Component {

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      title: "Attempts",
      anchorEl: null,
      columns: [
        {"title": "ID", "field": "report_id", defaultSort: 'desc', editable: false,
        "filterFn" : x => `report_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.report_id}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Type", "field": "type", editable: false,
        "filterFn" : x => `type= ${"Successful".includes(x) ? 1 : 0}`,
        render: rowData => <TooltipTypography data={rowData.type}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <Select
                                  value={{value: rowData.rowData.type, label: rowData.rowData.type}}
                                  onChange={e => rowData.onChange(e.value)}
                                  options={report_types.map((x)=> {return {value: x, label: x}})}
                               />
        },
        {"title": "Caption", "field": "caption",
        "filterFn" : x => `caption LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.caption}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "GPS Location", "field": "gps_location", editable: false,
        "filterFn" : x => `gps_location LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <MouseOverPopover rowData={rowData}/>
        },
        {"title": "Doc. Recipient", "field": "actual_receiver",
        "filterFn" : x => `actual_receiver LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.actual_receiver}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Job ID", "field": "job_id",
        "filterFn" : x => `job_id LIKE '%${x.replace(/^[a-z]+$/i, '')}%'`,
        "defaultFilter": this.props.filters["job_id"] !== undefined ? this.props.filters["job_id"] : null,
        render: rowData => <TooltipTypography data={rowData.job_id}/>},
        {"title": "Completion Date", "field": "completion_date", editable: false,
        "filterFn" : x => `completion_date LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.completion_date}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Relationship", "field": "relationship",
        "filterFn" : x => `relationship LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.relationship}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Age", "field": "age",
        "filterFn" : x => `age LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.age}/>},
        {"title": "Gender", "field": "gender",
        "filterFn" : x => `gender LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.gender}/>},
        {"title": "Ethnicity", "field": "ethnicity",
        "filterFn" : x => `ethnicity LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.ethnicity}/>,
        },
        {"title": "Weight", "field": "weight",
        "filterFn" : x => `weight LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.weight}/>},
        {"title": "Height", "field": "height",
        "filterFn" : x => `height LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.height}/>},
        {"title": "Hair", "field": "hair",
        "filterFn" : x => `hair LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.hair}/>},
        {"title": "Eyes", "field": "eyes",
        "filterFn" : x => `eyes LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.eyes}/>
        },
        {"title": "Military", "field": "military",
        "filterFn" : x => `military LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.military}/>
        },
        {"title": "Other Details", "field": "other_details",
        "filterFn" : x => `other_details LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.other_details}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Server Name", "field": "server_name", editable: false,
        "filterFn" : x => `server_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.server_name}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Server License", "field": "server_license", editable: false,
        "filterFn" : x => `server_license LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.server_license}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Log Date", "field": "log_date", editable: false,
        "filterFn" : x => `log_date LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.log_date}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
      ],
      filters: {},
      actions: []
    };
    console.log("constructor")
  }

  componentDidMount(){
    this.props.getGridData("reports", this.state.filters);
    if(Object.entries(this.props.filters).length > 0){
      this.setState({columns: [...this.state.columns]})
    }
    console.log("mount")
  }


  handlePopoverOpen = event => {
    console.log("hover on")
    console.log(event)
    this.setState({anchorEl: event.currentTarget});
  };

  handlePopoverClose = () => {
    console.log("hover off")
    console.log(this.state.anchorEl)
    console.log(Boolean(this.state.anchorEl))
    this.setState({anchorEl: null});
  };

  render(){

    return (
      <div>
        <MaterialGrid
          title={this.state.title}
          columns={this.state.columns}
          add={false}
          remove={this.props.employee.type > 1}
          edit={true}
          refresh={true}
          id_column="report_id"
          data_type="reports"
          actions={this.state.actions}
          tableRef={this.myRef}
        />
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    reports: state.get.reports,
    filters: state.update.filters,
    employee: state.auth.employee
  }
}

export default connect(
  mapStateToProps,
  {getGridData, addData, editData}
)(ReportDashboard)
