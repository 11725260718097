import React, {useCallback} from 'react';
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, SvgIcon, CssBaseline,Toolbar, ListItem,MenuItem, Divider, ListItemIcon, ListItemText, IconButton, Drawer,Typography,List, Grid, Button, Box } from '@material-ui/core/';
import LinearProgress from "@material-ui/core/LinearProgress";

import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import CourtIcon from '@material-ui/icons/AccountBalance';
import WorkIcon from '@material-ui/icons/Work';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import MemoryIcon from '@material-ui/icons/Memory';

import AssignmentIcon from '@material-ui/icons/Assignment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import BatchActionView from './batch_actions'
import BatchEditModal from './batch_edit_modal'
import {app_user} from './../tempfile'

import styled from 'styled-components';
import * as legalex_logo from '../images/legalex_logo.png';
import * as nh_logo from '../images/nh_logo.png';

import {logout, switchDashboard} from '../actions'
import {theme_color} from '../tempfile'

const drawerWidth = 240;

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: theme_color
  },
  barColorPrimary: {
    backgroundColor: "#fff"
  }
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  appBar: {
    height: 60,
    color: theme_color,
    backgroundColor:"#fff",
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
  },
  title: {
    marginLeft: 50
  },
  logoutButton: {
    color: theme_color,
    borderColor: theme_color,
    justifyContent: 'flex-end'
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    marginTop: 30,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme_color
  },
  margin: {
    margin: theme.spacing(1)
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
  },
}));

const LogoWrapper = styled.img`
    width: ${props => (props.size === 'sm' ? '10rem' : '15rem')};
    padding: 0.5rem 0;
`;

function HamburgerMenu({employee, currentScreen, loadingScreen, apiToken, dispatch, children, doc_upload_menu_on, doc_parsing}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loggedOut, setLoggedOut] = React.useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleLogout() {
    console.log("logging out");
    dispatch(logout(apiToken));
    setLoggedOut(true);
  }

  function renderRedirect() {
    if (loggedOut) {
      return <Redirect to='/login' />;
    }
  }

  function loadingOn(){
    if(loadingScreen){
      console.log("loading")
      return <ColorLinearProgress className={classes.margin} />;
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    // Loop through accepted files
    acceptedFiles.map(file => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function(e) {
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
      };
      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  function handleMenuClick(index){
    if(currentScreen !== index){
      dispatch(switchDashboard(index, {}))
    }
  }

  return (
    <div className={classes.root}>
      {renderRedirect()}
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
        <Grid justify="space-between" alignItems="center" container>
        <Box
            display="flex"
            flexDirection="row"
            p={3}
            alignItems="center"
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
            <LogoWrapper size='sm' src={app_user == "nh" ? nh_logo : legalex_logo} className={clsx(classes.logo)} />
            <Typography variant="h6" className={clsx(classes.title)} >
              Welcome {employee.name}
            </Typography>
            </Box>
            <Box
              pr={3}
              textAlign="right"
            >
            <Button onClick={handleLogout} variant="outlined" className={clsx(classes.logoutButton)} >
              Logout
            </Button>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <MenuItem button key='Home' onClick={() => handleMenuClick(0)} selected={currentScreen==0}>
            <ListItemIcon><HomeIcon/></ListItemIcon>
            <ListItemText primary='Home' />
          </MenuItem>
        </List>
        <Divider />
        <List>
          <MenuItem button key='Cases' onClick={() => handleMenuClick(1)} selected={currentScreen==1}>
            <ListItemIcon>
              <CourtIcon />
            </ListItemIcon>
            <ListItemText primary='Cases' />
          </MenuItem>
          <MenuItem button key='Jobs' onClick={() => handleMenuClick(2)} selected={currentScreen==2}>
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText primary='Jobs' />
          </MenuItem>
          <MenuItem button key='Attempts' onClick={() => handleMenuClick(3)} selected={currentScreen==3}>
            <ListItemIcon>
              <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary='Attempts' />
          </MenuItem>
        </List>
        <Divider />
        <Divider />
        <List>
          <MenuItem button key='Clients' onClick={() => handleMenuClick(4)} selected={currentScreen==4}>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary='Clients' />
          </MenuItem>
          {employee.type == 2 &&
          <MenuItem button key='Employees' onClick={() => handleMenuClick(5)} selected={currentScreen==5}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary='Employees' />
          </MenuItem>}
          {employee.type > 0 &&
          <MenuItem button key='Vendors' onClick={() => handleMenuClick(6)} selected={currentScreen==6}>
            <ListItemIcon>
              <MotorcycleIcon/>
            </ListItemIcon>
            <ListItemText primary='Vendors' />
          </MenuItem>}
        </List>
        {employee.type > 0 &&
        <div>
        <Divider />
        <List>
          <MenuItem button key='Accounting' onClick={() => handleMenuClick(7)} selected={currentScreen==7}>
            <ListItemIcon><AccountBalanceWalletIcon/></ListItemIcon>
            <ListItemText primary='Accounting' />
          </MenuItem>
        </List></div>}
        <Divider />
        <List>
          <MenuItem button key='Doc Parsing' onClick={() => handleMenuClick(8)} selected={currentScreen==8}>
            <div style={{color: 'gray'}}>{doc_parsing ? doc_parsing.length : ''}</div>
            <ListItemIcon><MemoryIcon/></ListItemIcon>
            <ListItemText primary='Doc Parsing' />
          </MenuItem>
        </List>
      </Drawer>
      <main className={classes.content}>
          <div className={classes.toolbar} />
          {currentScreen == 0 ? null : <BatchActionView/>}
          {loadingOn()}
          {<BatchEditModal screen_no={currentScreen}/>}
          {children !== null ? children : null}
      </main>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken,
    employee: state.auth.employee,
    currentScreen: state.update.currentScreen,
    loadingScreen: state.update.isUpdating,
    doc_upload_menu_on: state.update.doc_upload_menu_on,
    doc_parsing: state.get.doc_parsing,
  }
}

HamburgerMenu.defaultProps = {
  children: null,
  loadingScreen: null,
};

export default connect(
  mapStateToProps
)(HamburgerMenu)
