import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {getGridData, addData, deleteData, editData, switchDashboard, enterModal, duplicateJob, hardRefresh, getClients, getVendors, addFilters, autoAddRow} from '../../actions'
import {LabelledSelect, LabelledCreatableSelect, OptionalSelect, TooltipTypography} from 'components/select_with_label';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {theme_color} from './../../tempfile'

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon,
    PaymentIcon,
    ForwardIcon,
    AssignmentTurnedInIcon,
    FolderIcon,
    DoneActionIcon,
    DoubleArrowIcon,
    CodeIcon,
    MoreVertIcon,
    VisibilityIcon,
    AlertIcon
} from 'components/table_icons';


import JobModals from 'components/job_modals'


import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {rec_types} from '../../tempfile'


import Barcode from 'components/barcode'
const moment = require('moment');

const downloadCode = (job_id) => {
  const canvas = document.getElementById("job-code");
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = job_id + "_code.png";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const StyledMenu = withStyles({
  paper: {
    border: '1px solid ' + theme_color,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme_color,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const minWidth = 150;
const minWidth1 = 150;
const minWidth2 = 500;

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class JobDashboard extends Component {

  tableRef = React.createRef();


  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      anchorEl: null,
      add_case_id: null,
      title: "Jobs",
      current_job: null,
      current_row: null,
      columns: [
        {"title": "Job ID", "field": "job_id", "editable": false, defaultSort: 'desc',
        "filterFn" : x => `job_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.job_id : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Client", "field": "client", "editable": false,
        "filterFn" : x => {
          const client_ids = this.props.clients.filter(c => c.company.includes(x)).map(c => c.client_id).join(',');
          return `client_id IN (${client_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.client : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Received", "field": "received_date", "editable": false,
        "filterFn" : x => `creation_date LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.received_date : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Serve By", "field": "due_date",
        "filterFn" : x => `due_date LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        defaultFilter: this.props.filters["due_date"] !== undefined ? this.props.filters["due_date"] : null,
        render: rowData => <TooltipTypography data={rowData ? rowData.due_date : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData =>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                  name="Serve by"
                                  variant="inline"
                                  inputVariant="outlined"
                                  label="Serve By Date"
                                  format="MMM dd, yyyy"
                                  margin="normal"
                                  autoComplete="completion"
                                  fullWidth
                                  value={rowData.rowData.due_date}
                                  autoOk
                                  onChange={e => {
                                    return rowData.onChange(moment(e).format('MMM DD, YYYY'))
                                  }}
                                  error={false}
                              />
                            </MuiPickersUtilsProvider>
        },
        {"title": "Status", "field": "job_status", "editable": false,
        "filterFn" : x => {
          const status = this.props.codes.status.filter(c => c.name.includes(x)).map(c => c.code_id).join(',');
          return `job_status IN (${status})`;
        },
        "defaultFilter": this.props.filters["job_status"] !== undefined ? this.props.filters["job_status"] : null,
        render: rowData => <TooltipTypography data={rowData ? rowData.job_status : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Recipient", "field": "rec_name",
        "defaultFilter": this.props.filters["rec_name"] !== undefined ? this.props.filters["rec_name"] : null,
        "filterFn" : x => `rec_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.rec_name : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledCreatableSelect
                                  rowData={rowData}
                                  options={this.props.jobs.map(p => p.rec_name).filter(onlyUnique).map(p => {return {value: p, label: p}})}
                                  value={rowData.rowData.rec_name}
                               />
        },
        {"title": "Vendor", "field": "vendor",
        "filterFn" : x => {
          const vendor_ids = this.props.vendors.filter(c => c.company.includes(x)).map(c => c.vendor_id).join(',');
          return `vendor IN (${vendor_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.vendor : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <OptionalSelect
              rowData={rowData}
              options={this.props.vendors.map((v)=> {return {value: v.company, label: v.company}})}
              value={rowData.rowData.vendor}
           />
        },
        {"title": "Attempts", "field": "attempts",  "editable": false,
        "filterFn" : x => `attempts LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {minWidth: 50}, "cellStyle": {minWidth: 50},
        render: rowData => <TooltipTypography data={rowData ? rowData.attempts : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Address 1", "field": "address1",
        "filterFn" : x => {
          const address_ids = this.props.addresses.filter(c => c.address1.includes(x)).map(c => c.address_id).join(',');
          return `address_id IN (${address_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.address1 : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.address1}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.address1 == ""}
                                   />
        },
        {"title": "Address 2", "field": "address2",
        "filterFn" : x => {
          const address_ids = this.props.addresses.filter(c => c.address2.includes(x)).map(c => c.address_id).join(',');
          return `address_id IN (${address_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.address2 : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.address2}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Address 3", "field": "address3",
        "filterFn" : x => {
          const address_ids = this.props.addresses.filter(c => c.address3.includes(x)).map(c => c.address_id).join(',');
          return `address_id IN (${address_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.address3 : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.address3}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "City", "field": "city",
        "filterFn" : x => {
          const address_ids = this.props.addresses.filter(c => c.city.includes(x)).map(c => c.address_id).join(',');
          return `address_id IN (${address_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.city : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.city}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.city == ""}
                                   />
        },
        {"title": "State", "field": "state",
        "filterFn" : x => {
          const address_ids = this.props.addresses.filter(c => c.state.includes(x)).map(c => c.address_id).join(',');
          return `address_id IN (${address_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.state : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.state}
                                      onChange={e => rowData.onChange(e.target.value.substring(0,Math.min(e.target.value.length, 2)))}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.state == ""}
                                   />
        },
        {"title": "Zip Code", "field": "zip_code",
        "filterFn" : x => {
          const address_ids = this.props.addresses.filter(c => c.zip_code.includes(x)).map(c => c.address_id).join(',');
          return `address_id IN (${address_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.zip_code : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.zip_code}
                                      onChange={e => rowData.onChange(e.target.value.substring(0,Math.min(e.target.value.length, 5)))}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.zip_code == ""}
                                   />
        },
        {"title": "Document Type", "field": "doc_type",
        "filterFn" : x => `doc_type LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.doc_type : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledCreatableSelect
                                  rowData={rowData}
                                  options={this.props.jobs.map(p => p.doc_type).filter(onlyUnique).map(p => {return {value: p, label: p}})}
                                  value={rowData.rowData.doc_type}
                               />
        },
        {"title": "Instructions", "field": "instructions",
        "filterFn" : x => `instructions LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.instructions : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.instructions}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Notes", "field": "notes",
        "filterFn" : x => `notes LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.notes : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.notes}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Case", "field": "case_id",
        "filterFn" : x => `case_id LIKE '%${x.replace(/^[a-z]+$/i, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.case_id ? rowData.case_id : ''}/>,
        "headerStyle": {minWidth: minWidth1*1.5}, "cellStyle": {minWidth: minWidth1*1.5},
        "defaultFilter": this.props.filters["case_id"] !== undefined ? this.props.filters["case_id"] : null,
        "editComponent": rowData => {
          if(this.state.add_case_id != null) {
            rowData.onChange(this.state.add_case_id)
            this.setState({add_case_id: null});
          }  
          return <LabelledSelect
                    rowData={rowData}
                    options={this.props.cases.map((c)=> {return {value: c.case_id, label: `${c.case_id}: ${c.plt_name} vs. ${c.lead_defendant}`}})}
                    value={rowData.rowData.case_id}
                 />
          }
        },
        {"title": "Attorney", "field": "attorney", "editable": false,
        "filterFn" : x => {
          const client_ids = this.props.clients.filter(c => c.name.includes(x)).map(c => c.client_id).join(',');
          return `client_id IN (${client_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.attorney : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Priority", "field": "priority",
        "filterFn" : x => {
          const priorities = this.props.codes.priority.filter(c => c.name.includes(x)).map(c => c.code_id).join(',');
          return `priority IN (${priorities})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.priority : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledSelect
                                  rowData={rowData}
                                  options={this.props.codes.priority.map((p)=> {return {value: p.name, label: p.name}})}
                                  value={rowData.rowData.priority}
                               />
        },
        {"title": "Invoice Status", "field": "invoice_status",
        "filterFn" : x => {
          const status = this.props.codes.status.filter(c => c.name.includes(x)).map(c => c.code_id).join(',');
          return `invoice_status IN (${status})`;
        },
        "editable": false, "defaultFilter": this.props.filters["invoice_status"] !== undefined ? this.props.filters["invoice_status"] : null,
        render: rowData => <TooltipTypography data={rowData ? rowData.invoice_status : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Affidavit Status", "field": "affidavit_status", "editable": false,
        "filterFn" : x => {
          const status = this.props.codes.status.filter(c => c.name.includes(x)).map(c => c.code_id).join(',');
          return `affidavit_status IN (${status})`;
        },
        "defaultFilter": this.props.filters["affidavit_status"] !== undefined ? this.props.filters["affidavit_status"] : null,
        render: rowData => <TooltipTypography data={rowData ? rowData.affidavit_status : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},

        {"title": "Assigned", "field": "assigned", "editable": false,
        "filterFn" : x => `assigned LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.assigned : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Acknowledged", "field": "acknowledged", "editable": false,
        "filterFn" : x => `acknowledged LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.acknowledged : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},

        {"title": "Address Type", "field": "type",
        "filterFn" : x => {
          const address_ids = this.props.addresses.filter(c => c.type == ("Company".includes(x) ? 0 : 1)).map(c => c.address_id).join(',');
          return `address_id IN (${address_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.type : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData =>  <LabelledSelect
                                  rowData={rowData}
                                  options={rec_types.map((type) => {return {value: type, label: type}})}
                                  value={rowData.rowData.type}
                               />
        },
        {"title": "Last Updated", "field": "last_update", "editable": false,
        "filterFn" : x => `last_update LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.last_update : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
      ],
      actions: [
          row => ({
              icon: () =>  <MoreVertIcon/>,
              tooltip: 'More Options',
              onClick: (e, c) => {
                  console.log(e);
                  this.setState({currentRow: c})
                  this.handleClick(e)
              },
              hidden: false
          }),
      ]
    };
  }

  handleClick = (event) => {
    console.log(event.currentTarget)
    this.setState({anchorEl: event.currentTarget})
  }

  handleClose = () => {
    this.setState({anchorEl: null})
  }

  componentDidMount(){
    //console.log("this gets called")
    //console.log(this.props.cases)
    this.props.getVendors({})
    this.props.getClients({})

    if(Object.entries(this.props.filters).length > 0){
      this.setState({columns: [...this.state.columns]})
    }
    //console.log("end of method")
  }

  render(){

    // This is the anchor to add start the add row function automatically for a case that is selected
    if(this.props.add_row_id != null){
      console.log("ADD ROW CALLED")
      const check = setInterval(() => {
        if(this.myRef.current != null && this.props.jobs != null){
  				clearInterval(check);
          console.log(this.myRef.current)
          if(this.props.add_row_id != null){
            this.setState({add_case_id: this.props.add_row_id}, () => {
              this.myRef.current.setState({showAddRow: true})
              this.props.autoAddRow(null);
            })
          }
        }
      }, 200);
    }
    console.log("INFO: ", this.state.add_case_id)

    return (
      <div>
        <MaterialGrid
          title={this.state.title}
          columns={this.state.columns}
          add={true}
          remove={this.props.employee.type > 1}
          edit={true}
          refresh={true}
          id_column="job_id"
          data_type="jobs"
          actions={this.state.actions}
          tableRef={this.myRef}
        />
        <JobModals tableRef={this.myRef}/>
        <div style={{display: "none"}}> // hidden div
        {this.state.current_job ?
           <Barcode value={this.state.current_job}/>
         :null}
       </div>
       <div>
         <StyledMenu
           id="customized-menu"
           anchorEl={this.state.anchorEl}
           keepMounted
           open={Boolean(this.state.anchorEl)}
           onClose={this.handleClose}
         >
           <StyledMenuItem onClick={() => this.props.enterModal("docs", [this.state.currentRow])}>
             <ListItemIcon>
               <UploadIcon />
             </ListItemIcon>
             <ListItemText primary="Upload Document" />
           </StyledMenuItem>
           <StyledMenuItem onClick={() => {
             this.props.getGridData("job_docs", this.state.currentRow.job_id)
             this.props.enterModal("serve_doc_view", this.props.jobs.indexOf(this.state.currentRow))
             console.log("hello")
           }}>
             <ListItemIcon>
               <VisibilityIcon />
             </ListItemIcon>
             <ListItemText primary='View Docs' />
           </StyledMenuItem>
           <StyledMenuItem onClick={() => this.props.switchDashboard(3, {"job_id": this.state.currentRow.job_id.toString()})}>
             <ListItemIcon>
               <ForwardIcon/>
             </ListItemIcon>
             <ListItemText primary='See Attempts' />
           </StyledMenuItem>
           {this.state.currentRow && this.state.currentRow.invoice_url ?
           <StyledMenuItem onClick={() => this.props.enterModal("display", this.state.currentRow.invoice_url)}>
             <ListItemIcon>
               <InvoiceIcon />
             </ListItemIcon>
             <ListItemText primary='Open Invoice' />
           </StyledMenuItem> : null}
           {this.props.employee.type > 0 &&
           <StyledMenuItem onClick={() => this.props.enterModal("job_charges", {index: this.props.jobs.indexOf(this.state.currentRow), ref: this.myRef})}>
             <ListItemIcon>
               {this.state.currentRow && this.state.currentRow.charges_ready ? <DoneActionIcon /> : <PaymentIcon />}
             </ListItemIcon>
             <ListItemText primary={this.state.currentRow && this.state.currentRow.charges_ready ? 'Job Ready for Invoicing' : 'Open Job Billing'} />
           </StyledMenuItem>}
           <StyledMenuItem onClick={() =>
             this.props.duplicateJob(this.state.currentRow.job_id).then((res) => {
               if(res){
                 // add filters so duplication can be seen clearly
                 this.props.switchDashboard(0, {})
                 this.props.switchDashboard(2, {"case_id": this.state.currentRow.case_id.toString()})
                 this.props.enqueueSnackbar('Job duplicated', {variant: 'success'})
               }else{
                 this.props.enqueueSnackbar('Could not duplicate job', {variant: 'error'})
               }
             })
           }>
             <ListItemIcon>
               <DoubleArrowIcon />
             </ListItemIcon>
             <ListItemText primary='Duplicate Job' />
           </StyledMenuItem>
           <StyledMenuItem onClick={() => this.setState({current_job: this.state.currentRow.job_id}, () => {
             downloadCode(this.state.currentRow.job_id);
             this.props.enqueueSnackbar('QR Code Downloaded', {variant: 'success'})
          })}>
             <ListItemIcon>
               <CodeIcon />
             </ListItemIcon>
             <ListItemText primary='Download Code' />
         </StyledMenuItem>
         <StyledMenuItem onClick={() => this.setState({current_job: this.state.currentRow.job_id}, () => {
           const client_id = this.props.cases.filter(x => x.case_id == this.state.currentRow.case_id)[0].client_id;
           this.props.enterModal("open_send_alert", {type: "job", refId: this.state.currentRow.job_id, client_id: client_id});
        })}>
           <ListItemIcon>
             <AlertIcon />
           </ListItemIcon>
           <ListItemText primary='Add Alert' />
         </StyledMenuItem>
       </StyledMenu>
       </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    jobs: state.get.jobs,
    vendors: state.get.vendors,
    codes: state.get.codes,
    filters: state.update.filters,
    cases: state.get.cases,
    clients: state.get.clients,
    currentScreen: state.update.currentScreen,
    employee: state.auth.employee,
    addresses: state.get.addresses,
    add_row_id: state.update.add_row_id
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, switchDashboard, enterModal, duplicateJob, hardRefresh, getClients, getVendors,addFilters, autoAddRow}
)(JobDashboard))
