import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {getGridData, addData, deleteData, editData, toggleBatchActions, toggleInvoiceOrStatement, enterModal, switchDashboard, cancelSelection, generateAffidavits, massPrintAffidavits, massPrintDocsToServe} from '../actions'

import Modal from '@material-ui/core/Modal';
import { withSnackbar } from 'notistack';
import {theme_color} from '../tempfile'

import { forwardRef } from 'react';

class BatchActionView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      batch_toggle_message: "Multi-Selection",
      isShowingMessage: false,
      files: [],
      toggleInvoiceMessage: "Switch to Accounting",
      options_message: "Show all actions"
    };
  }

  batchClick = () => {
    this.props.toggleBatchActions();
    if(this.props.batch_actions_on){
      this.setState({batch_toggle_message: "Multi-Selection"})
      this.props.cancelSelection()
    }else{
      this.setState({batch_toggle_message: "Single Row Selection"})
    }
  }

  toggleInvoice = () => {
    this.props.toggleInvoiceOrStatement();
    console.log("pressed");
    if(this.props.mode){
      this.setState({toggleInvoiceMessage: "Switch to Invoicing"})
    }else{
      this.setState({toggleInvoiceMessage: "Switch to Accounting"})
    }
  }

  editAddressOn = () => {
    console.log("hello")
    this.props.enterModal("address");
  }

  assignJobs = () => {
    console.log("hello")
    this.props.enterModal("assign");
  }

  editProductsCalled = () => {
    this.props.enterModal("products");
  }

  promptBatchEditor = () => {
    this.props.enterModal("batch");
  }

  generateInvoices = () =>{
    this.props.enterModal("invoice");
  }

  generateStatements = () =>{
    this.props.enterModal("statement");
  }

  enterPayments = () =>{
    this.props.enterModal("payment");
  }

  uploadExcel = () => {
    this.props.enterModal("excel");
  }

  uploadAffidavits = () => {
    this.props.enterModal("affidavit_upload")
  }

  enterEmailInvoices = () => {
    this.props.enterModal("email_invoices")
  }

  enterEmailStatements = () => {
    this.props.enterModal("email_statements")
  }

  generateNewAffidavits = () => {

    const valid_jobs = this.props.rows_selected.filter(x => {
      if(x.job_status === "Received"){
        this.props.enqueueSnackbar(`Job ${x.job_id} has not been served or attempted.`, {variant: 'error'});
        return false;
      }else{
        return true;
      }
    })

    if(valid_jobs.length > 0){
      this.props.generateAffidavits({job_ids: valid_jobs.map((row) => row.job_id)});
    }else{
      this.props.enqueueSnackbar(`None of the jobs selected can generate affidavits!`, {variant: 'error'});
    }
  }

  massPrintAffidavits = () => {

    const filtered_rows_selected = this.props.rows_selected.filter(x => {
      if(x.affidavit_status == "Not Generated"){
        this.props.enqueueSnackbar(`Job ${x.job_id} has no affidavit`, {variant: 'error'});
        return false;
      }else{
        return true;
      }
    })
    if(filtered_rows_selected.length > 0){
      this.props.massPrintAffidavits(filtered_rows_selected).then(res => {
        if(res){
          this.props.enqueueSnackbar(`${filtered_rows_selected.length} affidavits sent to print!`, {variant: 'success'});
        }else{
          this.props.enqueueSnackbar(`Could not print ${filtered_rows_selected.length} affidavits`, {variant: 'error'});
        }
      });
    }else{
      this.props.enqueueSnackbar(`No jobs selected had affidavits!`, {variant: 'error'});
    }
  }

  printDocsToServe = () => {
    const filtered_rows_selected = this.props.rows_selected.filter(x => {
      if(x.vendor == ""){
        this.props.enqueueSnackbar(`Job ${x.job_id} has no vendor assigned`, {variant: 'error'});
        return false;
      }else{
        return true;
      }
    })
    if(filtered_rows_selected.length > 0){
      this.props.massPrintDocsToServe(filtered_rows_selected).then(res => {
        if(res){
          this.props.enqueueSnackbar(`${filtered_rows_selected.length} jobs sent to print!`, {variant: 'success'});
        }else{
          this.props.enqueueSnackbar(`Could not print ${filtered_rows_selected.length} jobs`, {variant: 'error'});
        }
      });
    }else{
      this.props.enqueueSnackbar(`No jobs selected have been assigned!`, {variant: 'error'});
    }
  }

  render(){

    return (
      <div>
      {this.props.currentScreen == 1 || this.props.currentScreen == 2 || this.props.currentScreen == 7 ?
      <div style={contStyles.main}>
        <p style={contStyles.title}>Special Actions: </p>
        {this.props.currentScreen == 1 || this.props.currentScreen == 2 ?
          <Button variant="outlined" style={contStyles.button} onClick={this.batchClick}>
            {this.state.batch_toggle_message}
          </Button>
        :null}
        {(this.props.currentScreen == 1 || this.props.currentScreen == 2) && this.props.batch_actions_on && !this.props.batch_edit_menu_on && this.props.rows_selected && this.props.rows_selected.length > 0 ?
          <Button variant="outlined" style={contStyles.button} onClick={this.promptBatchEditor}>
            Edit Selected Rows
          </Button>
        :null}
        {this.props.currentScreen == 2 && this.props.batch_actions_on && !this.props.batch_edit_menu_on && this.props.rows_selected && this.props.rows_selected.length > 0 ?
          <Button variant="outlined" style={contStyles.button} onClick={this.editAddressOn}>
            Edit Address
          </Button>
        :null}
        {this.props.currentScreen == 2 && this.props.batch_actions_on && !this.props.batch_edit_menu_on && this.props.rows_selected && this.props.rows_selected.length > 0 ?
          <Button variant="outlined" style={contStyles.button} onClick={this.printDocsToServe}>
            Mass Print Docs to Serve
          </Button>
        :null}
        {this.props.currentScreen == 2 && this.props.batch_actions_on && !this.props.batch_edit_menu_on && this.props.rows_selected && this.props.rows_selected.length > 0 ?
          <Button variant="outlined" style={contStyles.button} onClick={this.generateNewAffidavits}>
            Generate Affidavits
          </Button>
        :null}
        {this.props.currentScreen == 2 && this.props.batch_actions_on && !this.props.batch_edit_menu_on && this.props.rows_selected && this.props.rows_selected.length > 0 ?
          <Button variant="outlined" style={contStyles.button} onClick={this.massPrintAffidavits}>
            Mass Print Affidavits
          </Button>
        :null}
        {this.props.currentScreen == 1 ?
        <Button variant="outlined" style={contStyles.button} onClick={this.uploadExcel}>
          Upload Excel
        </Button>
        : null}
        {this.props.currentScreen == 2 && this.props.employee.type > 0 ?
        <Button variant="outlined" style={contStyles.button} onClick={this.generateInvoices}>
          Generate Available Invoices
        </Button>
        : null}
        {this.props.currentScreen == 2 ?
        <Button variant="outlined" style={contStyles.button} onClick={this.assignJobs}>
          Assign New Jobs
        </Button>
        : null}
        {this.props.currentScreen == 2 ?
        <Button variant="outlined" style={contStyles.button} onClick={this.uploadAffidavits}>
          Upload Affidavits
        </Button>
        : null}
        {this.props.currentScreen == 7 ?
          <Button variant="outlined" style={contStyles.button} onClick={this.toggleInvoice}>
            {this.state.toggleInvoiceMessage}
          </Button>
          :null
        }
        {this.props.currentScreen == 7 && this.props.mode ?
        <Button variant="outlined" style={contStyles.button} onClick={this.enterPayments}>
          Enter Payments
        </Button>
        : null}
        {/*this.props.currentScreen == 7 && this.props.mode ?
        <Button variant="outlined" style={contStyles.button} onClick={this.enterEmailInvoices}>
          Email Invoices
        </Button>
        : null*/}
        {this.props.currentScreen == 7 && this.props.mode ?
        <Button variant="outlined" style={contStyles.button} onClick={this.editProductsCalled}>
          Edit Products
        </Button>
        : null}
        {this.props.currentScreen == 7 && !this.props.mode ?
        <Button variant="outlined" style={contStyles.button} onClick={this.generateStatements}>
          Generate Statement
        </Button>
        : null}
        {/*this.props.currentScreen == 7 && !this.props.mode ?
        <Button variant="outlined" style={contStyles.button} onClick={this.enterEmailStatements}>
          Email Statements
        </Button>
        : null*/}
      </div>:null}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    batch_actions_on: state.update.batch_actions_on,
    batch_edit_menu_on: state.update.batch_edit_menu_on,
    currentScreen: state.update.currentScreen,
    pdf_on: state.update.pdf_on,
    mode: state.update.billing_chart,
    rows_selected: state.update.rows_selected,
    employee: state.auth.employee
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, toggleBatchActions,toggleInvoiceOrStatement, enterModal, switchDashboard, cancelSelection, generateAffidavits, massPrintAffidavits, massPrintDocsToServe}
)(BatchActionView))

const contStyles = {
  main: {
    flex: 1,
    display: 'flex',
    height: 50,
    width: 'auto',
    marginBottom: 5,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  button: {
    backgroundColor: '#fff',
    margin: 10,
    color: theme_color,
    borderColor: theme_color,
  },
  title: {
    color: theme_color,
    fontSize: 14,
    marginLeft: 20
  }
}
