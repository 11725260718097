import {
	JOBS_EDITED,
  CASES_EDITED,
  REPORTS_EDITED,
  DOC_PARSING_EDITED,
  ALERTS_EDITED,
  EMPLOYEES_EDITED,
  CLIENTS_EDITED,
  VENDORS_EDITED,
  COURTS_EDITED,
  PRODUCTS_EDITED,
  INVOICES_EDITED,
  STATEMENTS_EDITED,
	EDIT_SELECTION,
	CHARGES_EDITED,
	VENDOR_PRICING_EDITED,
	SOFT_REFRESH,
} from './types'

import axios from 'axios';
import { ServerPath, client_types, employee_types, binary_types, rec_types, report_types, rule_types } from '../tempfile';
import {getClients} from '.';

export const editData = (type, changes, ids) => {
	return (dispatch, getState) => {
		console.log(type)
		console.log(changes)
		console.log(ids)
		switch (type){
			case "jobs":
        return dispatch(editJobs(changes, ids));
				break;
			case "job_address":
        return dispatch(editJobAddress(changes, ids));
				break;
			case "cases":
        return dispatch(editCases(changes, ids));
				break;
			case "reports":
        return dispatch(editReports(changes, ids));
				break;
			case "employees":
        return dispatch(editEmployees(changes, ids));
				break;
			case "clients":
        return dispatch(editClients(changes, ids));
				break;
			case "client_settings":
				return dispatch(editClientSettings(changes, ids));
				break;
			case "vendors":
        return dispatch(editVendors(changes, ids));
				break;
			case "courts":
        return dispatch(editCourts(changes, ids));
				break;
			case "products":
        return dispatch(editProducts(changes, ids));
				break;
			case "charges":
				return dispatch(editCharges(changes, ids));
				break;
			case "doc_parsing":
				return dispatch(editDocParsing(changes, ids));
				break;
			case "vendor_pricing":
				return dispatch(editVendorPricing(changes, ids));
				break;
		}
  }
}


export const editJobs = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const { vendors, codes } = getState().get;

		delete changes.charges
		delete changes.reports

		if(Object.keys(changes).length == 0){
			return false;
		}

		if(changes.address_type != undefined){
			changes.address_type = rec_types.indexOf(changes.address_type).toString() + "";
		}

		if(changes.case_id != undefined){
			changes.case_id = changes.case_id + "";
		}

		if(changes.vendor != undefined) {
			changes.vendor = vendors.filter((v) => v.company == changes.vendor)[0].vendor_id.toString()
		}

		if(changes.priority != undefined) {
			changes.priority = codes.priority.filter((p) => p.name == changes.priority)[0].code_id.toString()
		}

		if(changes.rec_type != undefined) {
			changes.rec_type = rec_types.indexOf(changes.rec_type)
		}

		if(changes.type != undefined) {
			changes["address_type"] = rec_types.indexOf(changes.type) + "";
			delete changes.type;
		}

		const data = {
			token: apiToken,
			ids: ids,
			type: "job",
			changes: changes
		};
		console.log(data)
    return axios.post(ServerPath + `/api/editEntity`, data).then(response => {
				console.log(response)
        dispatch({ type: JOBS_EDITED, payload: response.data.success });
        return Promise.resolve(response.data.success);
    });
  }
}

export const editJobAddress = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(Object.keys(changes).length == 0){
			return false;
		}

		const data = {
			token: apiToken,
			...changes,
			job_ids: ids
		};
		console.log(data)
    return axios.post(ServerPath + `/api/changeAddress`, data).then(response => {
				console.log(response)
        dispatch({ type: JOBS_EDITED, payload: response.data.success });
        return Promise.resolve(response.data.success);
    });
  }
}

export const editCases = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const { clients, courts } = getState().get;

		if(Object.keys(changes).length == 0){
			return false;
		}

		if(changes.attorney != undefined){
			changes["client_id"] = clients.filter((c) => changes.attorney == c.name)[0].client_id.toString();
			delete changes["attorney"];
			delete changes["client_company"];
		}

		if(changes.case_no != undefined){
			changes["docket_num"] = changes.case_no;
			delete changes["case_no"];
		}

		if(changes.plt_type != undefined){
			changes["plt_type"] = rec_types.indexOf(changes.plt_type).toString();
		}

		if(changes.jurisdiction != undefined){
			changes["jurisdiction"] = changes["jurisdiction"] === "State" ? "1" : "0";
		}

		const data = {
			token: apiToken,
			ids: ids,
			type: "case",
			changes: changes,
		};
    return axios.post(ServerPath + `/api/editEntity`, data).then(response => {
			console.log(response)
      dispatch({ type: CASES_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const editReports = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(Object.keys(changes).length == 0){
			return false;
		}

		if(changes.type != undefined){
			changes["type"] = report_types.indexOf(changes.type).toString();
		}

		const data = {
			token: apiToken,
			ids: ids,
			type: "report",
			changes: changes,
		};
    return axios.post(ServerPath + `/api/editEntity`, data).then(response => {
			console.log(response)
      dispatch({ type: REPORTS_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const editEmployees = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(Object.keys(changes).length == 0){
			return false;
		}

		if(changes["type"] != undefined){
			changes["type"] = employee_types.indexOf(changes.type).toString();
		}

		const data = {
			token: apiToken,
			id: ids[0],
			type: "employee",
			changes: changes
		};

		console.log('data', data)
    return axios.post(ServerPath + `/api/editProfile`, data).then(response => {
			console.log(response)
      dispatch({ type: EMPLOYEES_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const editClients = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(Object.keys(changes).length == 0){
			return false;
		}

		if(changes["type"] != undefined){
			changes["type"] = client_types.indexOf(changes["type"]).toString();
		}

		if(changes.address_type != undefined){
			changes.address_type = rec_types.indexOf(changes.address_type).toString();
		}

		delete changes['invoice_email']
		delete changes['statement_email']
		delete changes['alert_email']
		delete changes['order_confirmation_email']
		delete changes['job_done_email']
		delete changes['rules']

		const data = {
			token: apiToken,
			id: ids[0],
			type: "client",
			changes: changes
		};
    return axios.post(ServerPath + `/api/editProfile`, data).then(response => {
			console.log(response)
			if(response.data.success){
      	dispatch({ type: CLIENTS_EDITED, payload: response.data.success });
			}
      return Promise.resolve(response.data.success);
    });
  }
}

export const editClientSettings = (changes, ids) => {

	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const { clients } = getState().get;

		if(Object.keys(changes).length == 0){
			return false;
		}

		const data = {
			token: apiToken,
			id: ids[0],
			type: "client",
			changes: changes
		};
		return axios.post(ServerPath + `/api/editProfile`, data).then(response => {
			console.log(response)
			if(response.data.success){
				dispatch({ type: CLIENTS_EDITED, payload: response.data.success });
				const keys = Object.keys(changes);
				console.log('keys', keys)
				for(let x = 0; x < keys.length; x++){
					changes[keys[x]] = changes[keys[x]] == "1" ? true : false;
				}

				console.log('actual changes: ', changes);
				let updated = [...clients];
				for(let i = 0; i < updated.length; i++){
					if(updated[i].client_id == ids[0]){
						updated[i] = {...updated[i], ...changes}
						console.log('edit made', updated[i]);

					}
				}
				dispatch({ type: SOFT_REFRESH, payload: {type: "clients", data: updated} });
			}
			return Promise.resolve(response.data.success);
		});
	}
}

export const editAffidavitRules = (type, changes, id) => {

	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const { clients } = getState().get;

		if(Object.keys(changes).length == 0){
			return false;
		}

		console.log('changes: ', changes);

		const newRule = rule_types.indexOf(changes.rule_id) + 1;

		const data = {
			token: apiToken,
			doc_type: changes.doc_type,
			change_type: type,
			client_id: id,
			rule_id: newRule
		};

		if(type == "edit"){
			data["old_doc_type"] = changes.old_doc_type;
		}

		console.log('editAffRules[data]: ', data);

		return axios.post(ServerPath + `/api/editAffidavitRules`, data).then(response => {
			console.log('editAffRules[resp]: ', response);
			if(response.data.success){
				dispatch(getClients({}));
			}
			return Promise.resolve(response.data.success);
		});
	}
}

export const editVendors = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		delete changes["vendor_pricings"]

		if(Object.keys(changes).length == 0){
			return false;
		}

		if(changes["contact"] != undefined){
			changes["contact_person"] = changes["contact"];
			delete changes["contact"];
		}

		if(changes["company"] != undefined){
			changes["name"] = changes["company"];
			delete changes["company"];
		}

		const data = {
			token: apiToken,
			id: ids[0],
			type: "vendor",
			changes: changes
		};

		console.log('editVendor: ', data)
    return axios.post(ServerPath + `/api/editProfile`, data).then(response => {
			console.log(response)
      dispatch({ type: VENDORS_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const editCourts = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(Object.keys(changes).length == 0){
			return false;
		}

		const data = {
			token: apiToken,
			ids: ids,
			type: "court",
			changes: changes
		};
    return axios.post(ServerPath + `/api/editEntity`, data).then(response => {
			console.log(response)
      dispatch({ type: COURTS_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}


export const editProducts = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(Object.keys(changes).length == 0){
			return false;
		}

		if(changes["default_price"] != undefined){
			changes["default_price"] = changes["default_price"].toString();
		}

		const data = {
			token: apiToken,
			ids: ids,
			type: "product",
			changes: changes
		};

		console.log('editProduct[data]: ', data);
    return axios.post(ServerPath + `/api/editEntity`, data).then(response => {
			console.log('editProduct[resp]: ', response)
      dispatch({ type: PRODUCTS_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const editCharges = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const charges_dict = {}

		if(Object.keys(changes).length == 0){
			return false;
		}

		const map_of_charges = changes.map((item) => {
			charges_dict[item.name] = item.value;
			return
		})

		const data = {
			token: apiToken,
			job_id: ids[0], // TODO: - generalise
			charges: charges_dict
		};
		console.log("data")
		console.log(data);
    return axios.post(ServerPath + `/api/editCharges`, data).then(response => {
			console.log(response)
      dispatch({ type: CHARGES_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const editDocParsing = (changes, ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(Object.keys(changes).length == 0){
			return false;
		}

		const data = {
			token: apiToken,
			ids: ids,
			type: "doc_parsing",
			changes: changes
		};

		console.log(data)
    return axios.post(ServerPath + `/api/editEntity`, data).then(response => {
			console.log(response)
      dispatch({ type: DOC_PARSING_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const editVendorPricing = (changes, id) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(Object.keys(changes).length == 0){
			return false;
		}

		const data = {
			token: apiToken,
			vendor_id: id,
			pricing: changes.pricing,
			zip_codes: changes.zip_codes
		};

		console.log(data)
    return axios.post(ServerPath + `/api/editVendorPricing`, data).then(response => {
			console.log(response)
      dispatch({ type: VENDOR_PRICING_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}
