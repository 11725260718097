import React, { Component } from 'react';
import { connect } from 'react-redux'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withSnackbar, closeSnackbar } from 'notistack';
import {theme_color} from '../tempfile'

import {addVendorPricing, editVendorPricing, deleteVendorPricing, getGridData, addData, deleteData, editData, toggleBatchActions, togglePDFOn, toggleInvoiceOrStatement, enterModal, approveCharges, updateIndex} from '../actions'

import {
    Button,
    Link,
    TextField,
    Container,
    CircularProgress,
    MenuItem,
    GridItem,
    GridContainer,
    InputLabel,
    FormControl
} from '@material-ui/core';

import Modal from '@material-ui/core/Modal';

import Error from '@material-ui/icons/Error';
import * as Yup from 'yup';


import { forwardRef } from 'react';

import PDFDisplay from "components/pdf_display";

import UploadDialog from "components/upload_dialog"

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table';
import { Formik } from 'formik';
import Select from 'react-select';
import { Creatable } from 'react-select-virtualized';
import CreatableSelect from 'react-select/creatable';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon
} from 'components/table_icons';

import Divider from '@material-ui/core/Divider';

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class VendorModals extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error_message: "",
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      buttonMessage: "Make changes",
      files: [],
      index: 0,
      has_options: false,
      county_selected: "",
      zipcodes_selected: [],
      mode: 0,
      options: [],
      counties: [],

      first_serve: "",
      second_serve: "",
      rush: "",
      same_day: "",
      copy_cost: "",
      copy_tier: ""
    };
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
    const interval = setInterval(() => {
      if(this.props.vendors && this.props.zipcodes){
        clearInterval(interval);
        const counties = this.props.zipcodes.map(x => x.county).filter(onlyUnique).map(x => {return {value: x, label: x}});
        this.setState({options: this.getOptions(true), counties: counties});
      }
    }, 200);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }


  handleClose = () => {
    this.setState({
      error_message: "",
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      buttonMessage: "Make changes",
      files: [],
      index: 0,
      has_options: false,
      county_selected: "",
      zipcodes_selected: [],
      mode: 0,
      options: [],
      counties: [],

      first_serve: "",
      second_serve: "",
      rush: "",
      same_day: "",
      copy_cost: "",
      copy_tier: ""
    });
    this.props.enterModal("dismiss");
  }

  handleInputChange = (event) => this.setState({input_value: event.target.value});
  handleZipcodeChange = (event) => {
    console.log('zipcode change: ', event);
    if(event.map(x => x.value).includes("All")){
      this.setState({zipcodes_selected: this.getOptions(false)});
    }else{
      this.setState({zipcodes_selected: event})
    }
  };
  changeMode = (event, val) => {
    this.setState({mode: val}, () => {
      this.setState({options: this.getOptions(true)});
    });
  }
  handleCountyChange = (event) => {
    this.setState({county_selected: event.value}, () => {
      this.setState({options: this.getOptions(true)})
    })
  };

  handleFirst = (event) => this.setState({first_serve: event.target.value.replace(/[^\d.-]/g, '')});
  handleSecond = (event) => this.setState({second_serve: event.target.value.replace(/[^\d.-]/g, '')});
  handleRush = (event) => this.setState({rush: event.target.value.replace(/[^\d.-]/g, '')});
  handleSameDay = (event) => this.setState({same_day: event.target.value.replace(/[^\d.-]/g, '')});
  handleCopy = (event) => this.setState({copy_cost: event.target.value.replace(/[^\d.-]/g, '')});
  handleQuota = (event) => this.setState({copy_tier: event.target.value.replace(/[^\d.-]/g, '')});

  handleKeyPress = (event) => {
    if(event.which === 37){ // Left
      console.log("left")
      if(this.props.index > 0){
        console.log(this.props.index-1)
        this.props.updateIndex(this.props.index-1);
      }
    }else if(event.which === 39){ // Right
      console.log("right")
      if(this.props.vendors.length - 1 > this.props.index){
        console.log(this.props.index+1)
        this.props.updateIndex(this.props.index+1);
      }
    }
  }

  getOptions = (all_included) => {
      const p = this.props.vendors[this.props.index].vendor_pricings;
      console.log('pricing: ', p)
      if(this.state.mode == 1){
        console.log("something")
        let res = p.map(x => {return {value: x.zip_code, label: x.zip_code}})
        console.log('before: ', res)
        if(all_included){
          res = [{value: "All", label: "All"}, ...res]
          console.log('after: ', res)
        }
        return res;
      }else{
        console.log(this.props.zipcodes)
        console.log('first pass: ', this.props.zipcodes.filter(y => !p.map(x => x.zip_code).includes(y.zip) && y.county == this.state.county_selected));
        let res = this.props.zipcodes.filter(y => !p.map(x => x.zip_code).includes(y.zip) && y.county == this.state.county_selected).map(x => x.zip).filter(onlyUnique).map(z => {return {value: z, label: z}})
        console.log('before: ', res)
        if(all_included){
          res = [{value: "All", label: "All"}, ...res]
          console.log('after: ', res)
        }
        return res;
      }
  }

  canAct = () => {

    console.log('button ready: ', this.state.zipcodes_selected.length == 0 ||
     this.state.first_serve == "" ||
     this.state.second_serve == "" ||
     this.state.rush == "" ||
     this.state.same_day == "" ||
     this.state.copy_cost == "" ||
     this.state.copy_tier == "")

    return this.state.zipcodes_selected.length == 0 ||
     this.state.first_serve == "" ||
     this.state.second_serve == "" ||
     this.state.rush == "" ||
     this.state.same_day == "" ||
     this.state.copy_cost == "" ||
     this.state.copy_tier == ""
  }

  deletePricings = () => {
    const zipcodes = this.state.zipcodes_selected.map(x => x.value);
    console.log("deleting")
    this.props.deleteVendorPricing({
      id: this.props.vendors[this.props.index].vendor_id,
      zip_codes: zipcodes
    }).then(res => {
      if(res){
        this.props.tableRef.current.onQueryChange();
        this.props.enqueueSnackbar(`${zipcodes.length} pricings deleted`, {variant: 'success'})
      }else{
        this.props.enqueueSnackbar(`${zipcodes.length} pricings could not be deleted`, {variant: 'error'})
      }
      this.handleClose();
    })
  }

  submitChargesUpdate = () => {

    console.log("submitting changes")

    const pricing = {
      first_serve: parseInt(parseFloat(this.state.first_serve)*100),
      second_serve: parseInt(parseFloat(this.state.second_serve)*100),
      rush: parseInt(parseFloat(this.state.rush)*100),
      same_day: parseInt(parseFloat(this.state.same_day)*100),
      copy_cost: parseInt(parseFloat(this.state.copy_cost)*100),
      copy_free_tier: parseInt(parseFloat(this.state.copy_tier)*100),
    }

    const zipcodes = this.state.zipcodes_selected.map(x => parseInt(x.value));

    if(this.state.mode == 0){
      this.props.addVendorPricing({
        zip_codes: zipcodes,
        pricing: pricing,
        vendor_id: this.props.vendors[this.props.index].vendor_id
      }).then(res => {
        if(res){
          this.props.tableRef.current.onQueryChange();
          this.props.enqueueSnackbar(`${zipcodes.length} pricings added`, {variant: 'success'})
        }else{
          this.props.enqueueSnackbar(`${zipcodes.length} pricings could not be added`, {variant: 'error'})
        }
        this.handleClose();
      })
    }else{
      this.props.editVendorPricing({
        zip_codes: zipcodes,
        pricing: pricing
      }, this.props.vendors[this.props.index].vendor_id).then(res => {
        if(res){
          this.props.tableRef.current.onQueryChange();
          this.props.enqueueSnackbar(`${zipcodes.length} pricings edited`, {variant: 'success'})
        }else{
          this.props.enqueueSnackbar(`${zipcodes.length} pricings could not be edited`, {variant: 'error'})
        }
        this.handleClose();
      })
    }
  }


  render(){

    const canAct = this.canAct();

    return(
      <div>
        {/* Vendor Charges */}
        <Modal
            open={this.props.vendor_pricing_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paper}>
              <div style={{width: 400, marginTop: 20}}>

                <h2>Vendor Fees</h2>
                {this.props.vendors && this.props.vendors[this.props.index] ?
                  <div>
                    <p><b>Vendor ID:</b> {this.props.vendors[this.props.index].vendor_id}</p>
                    <p><b>Company:</b>  {this.props.vendors[this.props.index].company}</p>
                     <ToggleButtonGroup
                        value={this.state.mode}
                        exclusive
                        onChange={this.changeMode}
                        aria-label="Mode"
                        style={{flex: 1}}
                      >
                        <ToggleButton value={0} style={{
                          color: this.state.mode == 0 ? theme_color : 'lightgray',
                          //borderColor: this.state.mode == 1 ? theme_color : 'lightgray'
                        }}>
                          Add
                        </ToggleButton>
                        <ToggleButton value={1} style={{
                          color: this.state.mode == 1 ? theme_color : 'lightgray',
                          //borderColor: this.state.mode == 0 ? theme_color : 'lightgray'
                        }}>
                          Edit
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {this.state.counties && this.state.mode == 0 ?
                      <div style={{flex: 1, marginTop: 10}}>
                        <Select
                            value={{value: this.state.county_selected, label: this.state.county_selected}}
                            onChange={this.handleCountyChange}
                            options={this.state.counties}
                            isMulti={false}
                            fullWidth
                            styles={selectStyles}
                            ignoreAccents={false}
                        />
                       </div>
                       : null}
                       <div style={{flex: 1, marginTop: 10}}>
                        <CreatableSelect
                            value={this.state.zipcodes_selected}
                            onChange={this.handleZipcodeChange}
                            options={this.state.options}
                            isMulti={true}
                            fullWidth
                            styles={selectStyles}
                            ignoreAccents={false}
                         />
                       </div>
                  </div>
                  :null}
                  <TextField
                      name="first_serve"
                      label="First Serve"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.first_serve}
                      onChange={this.handleFirst}
                  />
                  <TextField
                      name="second_serve"
                      label="Second Serve"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.second_serve}
                      onChange={this.handleSecond}
                  />
                  <TextField
                      name="rush"
                      label="Rush Fee"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      autoComplete="rush"
                      value={this.state.rush}
                      onChange={this.handleRush}
                  />
                  <TextField
                      name="same_day"
                      label="Same Day Fee"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.same_day}
                      onChange={this.handleSameDay}
                  />
                  <TextField
                      name="copy_cost"
                      label="Copy Cost (per page)"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.copy_cost}
                      onChange={this.handleCopy}
                  />
                  <TextField
                      name="copy_tier"
                      label="Number of free pages per job"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.copy_tier}
                      onChange={this.handleQuota}
                  />
                <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={canAct}
                    style={{backgroundColor: theme_color, marginTop: 30, marginBottom: 60}}
                    onClick={this.submitChargesUpdate}
                >
                  Submit Changes
                </Button>
                {this.state.mode == 1 && this.state.zipcodes_selected.length > 0 ?
                <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={this.deletePricings}
                    style={{backgroundColor: '#d11a2a', marginTop: 30, marginBottom: 60}}
                >
                Delete
                </Button>
                :null}
              </div>
            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vendors: state.get.vendors,
    vendor_pricing_modal_on: state.update.vendor_pricing_modal_on,
    products: state.get.products,
    index: state.update.index,
    zipcodes: state.get.zipcodes
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {addVendorPricing, editVendorPricing, deleteVendorPricing, getGridData, addData, deleteData, editData, toggleBatchActions, togglePDFOn, toggleInvoiceOrStatement, enterModal, approveCharges, updateIndex}
)(VendorModals))

const contStyles = {
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 600,
    height: 600,
    backgroundColor: '#fff',
    border: '2px solid #000',
    overflowY: 'scroll',
  },
  form: {
    margin: 30,
    minWidth: 120,
  },
  button: {
    marginTop: 60
  },
  dnd: {
    width: 550,
    height: 100,
    margin: 10
  }
}
