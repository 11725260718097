import {
	UPDATE_COMPLETED,
	SWITCH_SCREEN,
	EMAIL_SENT,
	TOGGLE_BATCH_ACTIONS,
	TOGGLE_PDF,
	TOGGLE_BILLING,
	ENABLE_BATCH_ACTIONS,
	UPDATE_SELECTED_ROWS,
	ENABLE_INVOICE_ACTIONS,
	ENABLE_PAYMENT_ACTIONS,
	ENABLE_STATEMENT_ACTIONS,
	ENABLE_AFFIDAVIT_ACTIONS,
	ENABLE_EFILING_ACTIONS,
	ENABLE_DOC_UPLOAD_ACTIONS,
	ENABLE_DOC_DISPLAY_MODAL,
	ENABLE_JOB_CHARGES_MODAL,
	ENABLE_PRODUCTS_MODAL,
	DISABLE_ACTIONS,
	APPROVE_CHARGES,
	PWD_RESET,
	UPDATE_INDEX,
	ENABLE_VENDOR_PRICING_MODAL,
	ADD_PAYMENT,
	SUBMIT_PAYMENT_DONE,
	ENABLE_EXCEL_MODAL,
	DUPLICATE_JOB,
	ENABLE_AFFIDAVIT_UPLOAD,
	CREATE_AMMENDMENT,
	ENABLE_ADDRESS_MODAL,
	CANCEL_SELECTION,
	ENABLE_EMAIL_MODAL,
	ASSIGN_JOBS_MODAL,
	COMPLETE_TASK,
	SERVE_DOC_MODAL,
	OPEN_JOBS_PDF,
	OPEN_SEND_ALERT,
	OPEN_CLIENT_SETTINGS,
	SOFT_REFRESH,
	ADD_FILTERS,
	ADD_ROW
} from './types'

import axios from 'axios';
import { Employee_ApiKey, ServerPath } from '../tempfile';

export const switchDashboard = (index, filters) => {
  return (dispatch, getState) => {
  	dispatch({ type: SWITCH_SCREEN, payload: {index: index, filters: filters} });
		console.log("dashboard switched")
		return Promise.resolve(true);
  }
};

export const completeUpdate = () => {
  return (dispatch) => {
  	dispatch({ type: UPDATE_COMPLETED});
		console.log("dashboard update completed")
  }
};

export const sendEmail = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			sender: item.sender,
			recipient: item.recipient,
			subject: item.subject,
			body_message: item.body_message,
			attachment_urls: item.attachment_urls,
		};
    return axios.post(ServerPath + `/api/sendEmail`, data).then(response => {
      dispatch({ type: EMAIL_SENT, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
};

export const uploadQRAffidavits = (item, type) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			bytes: item,
			type: type
		};

		console.log(data);
    return axios.post(ServerPath + `/api/addAffidavits`, data).then(response => {
			console.log(response)
      return Promise.resolve({success: response.data.success, message: response.data.message});
    });
  }
}

export const toggleBatchActions = () => {
  return (dispatch) => {
  	dispatch({ type: TOGGLE_BATCH_ACTIONS});
  }
};

export const cancelSelection = () => {
	return (dispatch) => {
  	dispatch({ type: CANCEL_SELECTION});
  }
}

export const togglePDFOn = (url) => {
	return (dispatch) => {
  	dispatch({ type: TOGGLE_PDF, payload: url});
  }
}

export const toggleInvoiceOrStatement = () => {
	return (dispatch) => {
  	dispatch({ type: TOGGLE_BILLING});
  }
}

export const enterModal = (key, val) => {
	return (dispatch) => {
		console.log("enter modal called")
		switch(key){
			case "batch":
				dispatch({ type: ENABLE_BATCH_ACTIONS});
				break;
			case "invoice":
				dispatch({ type: ENABLE_INVOICE_ACTIONS});
				break;
			case "payment":
				dispatch({ type: ENABLE_PAYMENT_ACTIONS});
				break;
			case "statement":
				dispatch({ type: ENABLE_STATEMENT_ACTIONS});
				break;
			case "affidavit":
				console.log(val)
				dispatch({ type: ENABLE_AFFIDAVIT_ACTIONS, payload: val});
				break;
			case "affidavit_upload":
				dispatch({ type: ENABLE_AFFIDAVIT_UPLOAD});
				break;
			case "e_filing":
				dispatch({ type: ENABLE_EFILING_ACTIONS});
				break;
			case "docs":
				dispatch({ type: ENABLE_DOC_UPLOAD_ACTIONS, payload: val});
				break;
			case "display":
				dispatch({ type: ENABLE_DOC_DISPLAY_MODAL, payload: val});
				break;
			case "job_charges":
				dispatch({ type: ENABLE_JOB_CHARGES_MODAL, payload: val});
				break;
			case "products":
				dispatch({ type: ENABLE_PRODUCTS_MODAL});
				break;
			case "dismiss":
				dispatch({ type: DISABLE_ACTIONS, payload: val})
				break;
			case "vendor_pricing":
				dispatch({ type: ENABLE_VENDOR_PRICING_MODAL, payload: val})
				break;
			case "excel":
				dispatch({ type: ENABLE_EXCEL_MODAL, payload: val})
				break;
			case "address":
				console.log("hello")
				dispatch({ type: ENABLE_ADDRESS_MODAL})
				break;
			case "email_invoices":
				dispatch({ type: ENABLE_EMAIL_MODAL, payload: "invoices"})
				break;
			case "email_statements":
				dispatch({ type: ENABLE_EMAIL_MODAL, payload: "statements"})
				break;
			case "email_affidavits":
				dispatch({ type: ENABLE_EMAIL_MODAL, payload: "affidavits"})
				break;
			case "assign":
				dispatch({ type: ASSIGN_JOBS_MODAL})
				break;
			case "serve_doc_view":
				dispatch({ type: SERVE_DOC_MODAL, payload: val})
				break;
			case "open_send_alert":
				dispatch({ type: OPEN_SEND_ALERT, payload: val})
				break;
			case "client_settings":
				dispatch({ type: OPEN_CLIENT_SETTINGS, payload: val})
				break;
		}
  }
}

export const updateRowSelection = (val) => {
	return (dispatch) => {
  	dispatch({ type: UPDATE_SELECTED_ROWS, payload: val});
  }
}

export const selectFiles = () => {
  return (dispatch) => {
		return new Promise((resolve, reject) => {
      var input = document.createElement('input');
      input.type = 'file';
      input.multiple = 'multiple';

      input.onchange = e => {
          const files = e.target.files;
          resolve(Array.from(files));
      };

      input.click();
  	});
	}
}

export const approveCharges = (val) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_id: val.job_id
		};
		console.log("[DATA] approveCharges", data)
    return axios.post(ServerPath + `/api/readyCharges`, data).then(response => {
			console.log("[RESPONSE] approveCharges", response)
      dispatch({ type: APPROVE_CHARGES, payload: val });
      return Promise.resolve(response.data.success);
    });
  }
}

export const allowPwdReset = (val) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			type: val.type,
			id: val.id
		};
    return axios.post(ServerPath + `/api/allowPwdReset`, data).then(response => {
			if(response.data.success){
      	dispatch({ type: PWD_RESET, payload: data });
			}
      return Promise.resolve(response.data.success);
    });
  }
}

export const updateIndex = (val) => {
	return (dispatch) => {
  	dispatch({ type: UPDATE_INDEX, payload: val});
  }
}


export const addPayment = (val) => {
	return (dispatch) => {
  	dispatch({ type: ADD_PAYMENT, payload: val});
		return Promise.resolve(true);
  }
}

export const submitPayment = (val, total, check_num, check_date) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			payments: val.map((item) => {return {id: item.id, type: item.type, payment: item.payment, parent_id: item.parent_id !== undefined ? item.parent_id : -1}}),
			total: total,
			check_num: check_num,
			check_date: check_date,
		};
		console.log("[data] submitPayment: ", data);
    return axios.post(ServerPath + `/api/submitPayment`, data).then(response => {
			console.log("[resp] submitPayment: ", response)
      dispatch({ type: SUBMIT_PAYMENT_DONE, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}


export const duplicateJob = (job_id) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_id: job_id
		};
		console.log("[data] duplicateJob: ", data);
    return axios.post(ServerPath + `/api/duplicateJob`, data).then(response => {
			console.log("[resp] duplicateJob: ", response)
      dispatch({ type: DUPLICATE_JOB, payload: response });
      return Promise.resolve(response.data.success);
    });
  }
}

export const createAmmendment = (case_id) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			case_id: case_id
		};
		console.log("[data] createAmmendment: ", data);
    return axios.post(ServerPath + `/api/createAmmendment`, data).then(response => {
			console.log("[resp] createAmmendment: ", response)
      dispatch({ type: CREATE_AMMENDMENT, payload: response });
      return Promise.resolve(response.data.success);
    });
  }
}

export const completeTask = (task_id) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			task_id: task_id
		};
		console.log("[data] completeTask: ", data);
    return axios.post(ServerPath + `/api/completeTask`, data).then(response => {
			console.log("[resp] completeTask: ", response)
      dispatch({ type: COMPLETE_TASK, payload: response });
      return Promise.resolve(response.data.success);
    });
  }
}

export const assignVendor = (job_ids, vendor_id) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: job_ids,
			vendor_id: vendor_id
		};
		console.log("[data] assignVendor: ", data);
		return axios.post(ServerPath + `/api/setJob`, data).then(response => {
			console.log("[resp] assignVendor: ", response)
			//dispatch({ type: SOFT_REFRESH, payload: {type: "jobs", data: data }});
			return Promise.resolve(response.data.success);
		});
	}
}

export const openJobsPDFViewer = (doc) => {
	return (dispatch, getState) => {
		dispatch({ type: OPEN_JOBS_PDF, payload: doc });
		return Promise.resolve(true);
	}
}

export const checkEmail = (email, type) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			email: email,
			type: type
		};
		console.log("[data] checkEmail: ", data);
		return axios.post(ServerPath + `/api/checkEmail`, data).then(response => {
			console.log("[resp] checkEmail: ", response)
			//dispatch({ type: SOFT_REFRESH, payload: {type: "jobs", data: data }});
			return Promise.resolve(response.data.success && response.data.available);
		});
	}
}

export const addFilters = (filters) => {
	return (dispatch, getState) => {
		dispatch({ type: ADD_FILTERS, payload: filters });
		return Promise.resolve(true);
	}
}

export const autoAddRow = (case_id) => {
	return (dispatch, getState) => {
		dispatch({ type: ADD_ROW, payload: case_id });
		return Promise.resolve(true);
	}
}
