import React, { Component } from 'react';
import {NYC_Zipcodes, GoogleMaps_API_Key} from '../tempfile'
import { Map, Marker, GoogleApiWrapper,InfoWindow } from 'google-maps-react';

const mapStyles = {
  width: '80%',
  height: '100%',
  paddingBottom: 10,
};

export class MapContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedPlace: {name: ''},
      activeMarker: null,
      showingInfoWindow: false
    }
  }


  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }



  render() {
    return (
      <Map
        google={this.props.google}
        zoom={10}
        style={mapStyles}
        initialCenter={{
         lat: (this.props.lat + this.props.lat2)/2,
         lng: (this.props.lng + this.props.lng2)/2
        }}
      >
        <Marker
          title={'Intended Address'}
          name={'Address'}
          position={{lat: this.props.lat, lng: this.props.lng}}
          onClick={this.onMarkerClick}
        />
        <Marker
          title={'GPS Stamp'}
          name={'GPS'}
          position={{lat: this.props.lat2, lng: this.props.lng2}}
          onClick={this.onMarkerClick}
        />

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
            <div>
              <h1>{this.state.selectedPlace.name}</h1>
            </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GoogleMaps_API_Key
})(MapContainer);
