import {
	JOBS_ADDED,
  CASES_ADDED,
  REPORTS_ADDED,
  ALERTS_ADDED,
  EMPLOYEES_ADDED,
  CLIENTS_ADDED,
  VENDORS_ADDED,
  PRODUCTS_ADDED,
  INVOICES_ADDED,
  STATEMENTS_ADDED,
	MESSAGES_ADDED,
	DOCS_ADDED,
	INVOICE_PREVIEW_ON,
	CLEAR_DOC,
	EXCEL_ADDED,
	AFFIDAVITS_ADDED,
	VENDOR_PRICING_ADDED
} from './types'

import axios from 'axios';
import { MIME_TYPES } from 'util/mime';

import {enterModal} from '.'

import { ServerPath, client_types, employee_types, binary_types, rec_types } from '../tempfile';

export const addData = (type, item) => {
	return (dispatch, getState) => {
		switch (type){
			case "jobs":
        return dispatch(addJobs(item));
				break;
			case "cases":
        return dispatch(addCases(item));
				break;
			case "reports":
        return dispatch(addReports(item));
				break;
			case "alerts":
        return dispatch(addAlerts(item));
				break;
			case "employees":
        return dispatch(addEmployees(item));
				break;
			case "clients":
        return dispatch(addClients(item));
				break;
			case "vendors":
        return dispatch(addVendors(item));
				break;
			case "products":
        return dispatch(addProducts(item));
				break;
			case "invoices":
        return dispatch(addInvoices(item));
				break;
			case "statements":
        return dispatch(addStatements(item));
				break;
			case "documents":
				return dispatch(addDocuments(item));
				break;
			case "messages":
				return dispatch(addMessage(item));
				break;
			case "excel":
				return dispatch(addExcel(item));
				break;
			case "affidavits":
				return dispatch(generateAffidavits(item));
				break;
			case "tasks":
				return dispatch(addTasks(item));
				break;
		}
  }
}

export const addJobs = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const { codes, vendors, cases } = getState().get;

		if(!(item.case_id != undefined &&
			item.priority != undefined &&
			item.doc_type != undefined &&
			item.rec_name != undefined &&
			item.address1 != undefined &&
			item.city != undefined &&
			item.state != undefined &&
			item.zip_code != undefined &&
			item.type != undefined)){
				console.log('something was undefined: ', item)
				return false;
		}

		const my_case = cases.filter(c => c.case_id == item.case_id)[0]

		const data = {
			token: apiToken,
			jobs: [{
				server_name: item.server_name ? item.server_name : "",
				due_date: item.due_date ? item.due_date : "",
				case_id: my_case.case_id,
				vendor: item.vendor ? vendors.filter((v) => v.company == item.vendor)[0].vendor_id : -1,
				priority: codes.priority.filter((p) => item.priority == p.name)[0].code_id,
				client_ref: item.client_ref ? item.client_ref : "",
				instructions: item.instructions ? item.instructions : "",
				client_id: my_case.client_id,

				doc_type: item.doc_type,
				rec_name: item.rec_name,

				address1: item.address1,
				address2: item.address2 ? item.address2 : "",
				address3: item.address3 ? item.address3 : "",
				city: item.city,
				state: item.state,
				zip_code: item.zip_code,
				type: rec_types.indexOf(item.type)
			}]
		};
		console.log(data);
    return axios.post(ServerPath + `/api/addJobs`, data).then(response => {
				console.log(response)
				dispatch({ type: JOBS_ADDED, payload: response.data.success });
        return Promise.resolve(response.data.success);
    });
  }
}

export const addCases = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const { clients, courts } = getState().get;

		if(!(item.court_name != undefined &&
			item.contact_name != undefined &&
			item.contact_email != undefined &&
			item.case_no != undefined &&
			item.attorney != undefined &&
			item.lead_defendant != undefined)){
				console.log("hello there")
				return false;
		}

		let my_court = null;

		if(courts.filter((court) => court.name == item.court_name).length > 0){
			my_court = courts.filter((court) => court.name == item.court_name)[0]
		}

		const data = {
			token: apiToken,
			client_id: clients.filter((client) => item.attorney == client.name)[0].client_id,
			cases: [{
				court_name: item.court_name ? item.court_name : (my_court ? my_court.name: ''),
				county_name: item.county_name ? item.county_name : (my_court ? my_court.county: ''),
				state: item.state ? item.state : (my_court ? my_court.state: ''),
				referral: -1,	// TODO
				contact_name: item.contact_name,
				contact_email: item.contact_email,
				docket_num: item.case_no,
				court_date: "", // TODO
				date_filed: item.date_filed ? item.date_filed : "",
				ammendment: 0,
				plt_name: item.plt_name,
				client_ref: item.client_ref ? item.client_ref : "",
				jurisdiction: item.jurisdiction ? (item.jurisdiction == "State" ? 0 : 1) : (my_court ? my_court.type: 0),
				lead_defendant: item.lead_defendant,
				district: item.district ? item.district : ""
			}]
		};
		console.log('addCase[data]: ', data);
    return axios.post(ServerPath + `/api/addCases`, data).then(response => {
			console.log(response)
			dispatch({ type: CASES_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addReports = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: item.job_ids,
			age: parseInt(item.age),
			weight: item.weight,
			height: item.height,
			hair: item.hair,
			eyes: item.eyes,
			ethnicity: item.ethnicity,
			gender: item.gender,
			relationship: item.relationship,
			other_details: item.comments,
			completion_date: item.completion,
			actual_receiver: item.recipient_name,
			gps_location: item.gps_location,
			address_id: item.address_id,
      type: item.type == "Successful" ? 1 : 0,
      caption: item.caption
		};
		console.log(data);
    return axios.post(ServerPath + `/api/addReport`, data).then(response => {
			console.log(response)
			dispatch({ type: REPORTS_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addAlerts = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			ref_id: item.ref_id,
			client_id: item.client_id,
			type: item.type,
			message: item.message
		};
		console.log(data);
    return axios.post(ServerPath + `/api/postAlert`, data).then(response => {
			console.log(response)
			dispatch({ type: ALERTS_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addTasks = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			task_id: -1,
			...item,
			completion: ""
		};
		console.log(data);
    return axios.post(ServerPath + `/api/addTask`, data).then(response => {
			console.log(response)
			dispatch({ type: ALERTS_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addEmployees = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			first_name: item.first_name,
			last_name: item.last_name,
			email: item.email,
			type: employee_types.indexOf(item.type),
			description: item.description ? item.description : "",
			location: item.location,
			pwd_reset_on: true,
		};

		console.log('addEmployee[data]: ', data);
    return axios.post(ServerPath + `/api/addEmployee`, data).then(response => {
			console.log('addEmployee[resp]: ', response)
			dispatch({ type: EMPLOYEES_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addClients = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			name: item.name,
			company: item.company,
			phone: item.phone ? item.phone : "",
			title: item.title ? item.title : "",
			is_active: true,
			type: client_types.indexOf(item.type),
			email: item.email ? item.email : "",
			ftp_user: item.ftp_user ? item.ftp_user : "",
			ftp_password: item.ftp_password ? item.ftp_password : "",
			ftp_port: item.ftp_port ? item.ftp_port : "",
			ftp_url: item.ftp_url ? item.ftp_url : "",
			address1: item.address1 ? item.address1 : "",
			address2: item.address2 ? item.address2 : "",
			address3: item.address3 ? item.address3 : "",
			address_type: item.address_type ? rec_types.indexOf(item.address_type) : parseInt("0"),
			city: item.city ? item.city : "",
			state: item.state ? item.state : "",
			zip_code: item.zipcode ? item.zipcode : "",
			mail_original_is_default: item.mail_original_is_default ? !!binary_types.indexOf(item.mail_original_is_default) : false,
			file_is_default: item.file_is_default ? !!binary_types.indexOf(item.file_is_default) : false,
			pwd_reset_on: true,
			ref_number: item.ref_number ? item.ref_number : ""
		};

		console.log('addClient[data]: ', data);
    return axios.post(ServerPath + `/api/addClient`, data).then(response => {
			console.log('addClient[resp]: ', response);
			console.log(response)
			dispatch({ type: CLIENTS_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addVendors = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			name: item.company,
			state: item.state ? item.state : "",
			city: item.city ? item.city : "",
			email: item.email ? item.email : "",
			phone: item.phone ? item.phone : "",
			contact_person: item.contact ? item.contact : "",
			identifier: item.identifier ? item.identifier : "",
			def_server: item.def_server ? item.def_server : "",
			def_license: item.def_license ? item.def_license : ""
		};

		console.log(data);
    return axios.post(ServerPath + `/api/addVendor`, data).then(response => {
			console.log(response)
			dispatch({ type: VENDORS_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addProducts = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			product: {
				category: item.category,
				name: item.name,
				default_price: parseInt(item.default_price)
			},
		};

		console.log('addProduct[data]: ', data);
    return axios.post(ServerPath + `/api/addProduct`, data).then(response => {
			console.log('addProduct[resp]: ', response)
			dispatch({ type: PRODUCTS_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addInvoices = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			is_preview: item.is_preview
		};

		if(item.type === "job"){
			data['job_id'] = item.id;
		}else if(item.type === "case"){
			data['case_id'] = item.id;
		}else if(item.type === "plaintiff"){
			data['one_doc'] = true;
			data['client_ref'] = item.id;
		}else{
			data['one_doc'] = true;
			data['client_id'] = item.id;
		}
		dispatch({ type: CLEAR_DOC });

		console.log(data);
    return axios.post(ServerPath + `/api/generateInvoice`, data).then(response => {
			console.log(response)
			dispatch({ type: INVOICES_ADDED, payload: response.data.success });
			if(response.data.success && item.is_preview){
				dispatch({ type: INVOICE_PREVIEW_ON, payload: response.data.file_locations[0] });
			}
      return Promise.resolve(response.data.success);
    });
  }
}

export const addStatements = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			client_id: item.client_id,
			start: item.start,
			end: item.end
		};

		console.log('[DATA] addStatements: ', data);
    return axios.post(ServerPath + `/api/generateStatement`, data).then(response => {
			console.log('[Response] addStatements: ', response)
			dispatch({ type: STATEMENTS_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const addDocuments = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		let data = {
			token: apiToken,
			type: item.type,
			ref_id: item.ref_id
		};

		return Promise.all(item.files.map(f => toBase64(f.rawFile))).then(b64Encoded => {
        const docs = {
            doc_list: [],
            doc_bytes: [],
            doc_types: [],
            doc_folders: []
        };
        item.files.forEach((f, i) => {
            const extension = MIME_TYPES.find(t => t.type === f.type).extension;
            docs.doc_list.push(f.name + extension);
            docs.doc_bytes.push(b64Encoded[i]);
            docs.doc_types.push(f.type);
            docs.doc_folders.push(f.destinationFolder);
        });

				data = {...data, ...docs};

				console.log('addDocuments[DATA] -> ', data)
        return axios.post(ServerPath + '/api/addDocuments', data).then(response => {
						console.log('addDocuments[RESP] -> ',response)
						dispatch({ type: DOCS_ADDED, payload: response.data.success });
			      return Promise.resolve(response.data.success);
        });
    });
  }
}

export const addMessage = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			recipient: item.recipient,
			message: item.message,
			subject_ref_id: item.subject_ref_id
		};

		console.log(data);
    return axios.post(ServerPath + `/api/addMessage`, data).then(response => {
			console.log(response)
			dispatch({ type: MESSAGES_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

const toBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let res = reader.result.split(',')[1];
            resolve(res);
        };
        reader.onerror = error => reject(error);
    });
};

export const addExcel = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			file_name: item.file_name,
			bytes: item.bytes
		};

		console.log(data);
    return axios.post(ServerPath + `/api/addExcel`, data).then(response => {
			console.log(response)
			dispatch({ type: EXCEL_ADDED, payload: response.data.success });
      return Promise.resolve({success: response.data.success, message: response.data.message});
    });
  }
}

export const generateAffidavits = (item) => {
  return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: item.job_ids,
		};

		console.log(data);
    return axios.post(ServerPath + `/api/generateAffidavits`, data).then(response => {
			console.log(response)
			dispatch({ type: AFFIDAVITS_ADDED, payload: response.data.success });
			if(item.job_ids.length > 5){
				downloadFile(response.data.zip, "affidavits.zip")
			}else{
				response.data.files.map((x,i) => {
					downloadFile(response.data.files[i], `${data.job_ids[i]}_Affidavit.pdf`)
				})
			}
      return Promise.resolve(response.data.success);
    });
  }
}

export const addVendorPricing = (item) => {
  return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			vendor_id: item.vendor_id,
			pricing: item.pricing,
			zip_codes: item.zip_codes
		};

		console.log(data);
    return axios.post(ServerPath + `/api/addVendorPricing`, data).then(response => {
			console.log(response)
			dispatch({ type: VENDOR_PRICING_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

const downloadFile = (bytes, fileName) =>  {
    const linkSource = `data:application/zip;base64,${bytes}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
