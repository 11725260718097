import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {getGridData, addData, deleteData, editData, allowPwdReset,checkEmail} from '../../actions'
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import {LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    RenewPwdIcon,
    BellIcon
} from 'components/table_icons';
import {employee_types} from '../../tempfile'

const minWidth1 = 150;

function isValidEmailAddress(address) {
  if(address === undefined){return false}
    return !! address.match(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
}

class EmployeeDashboard extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      title: "Employees",
      columns: [
        {"title": "First", "field": "first_name",
        "filterFn" : x => `first_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.first_name : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.first_name}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.first_name == ""}
                                   />
        },
        {"title": "Last", "field": "last_name",
        "filterFn" : x => `last_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.last_name : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.last_name}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.last_name == ""}
                                   />
        },
        {"title": "Type", "field": "type",
        "filterFn" : x => {
          const types = employee_types.filter(c => c.includes(x)).map(c => employee_types.indexOf(c)).join(',');
          return types.length > 0 ? `type IN (${types})` : 'type=-1';
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.type : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData =>  <LabelledSelect
                                  value={rowData.rowData.type}
                                  rowData={rowData}
                                  options={employee_types.map((type) => {return {value: type, label: type}})}
                               />
        },
        {"title": "Email", "field": "email",
        "filterFn" : x => `email LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.email : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.email}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.email == "" || !isValidEmailAddress(rowData.rowData.email) || this.props.employees.filter(x => x.email === rowData.rowData.email && x.employee_id !== rowData.rowData.employee_id).length > 0}
                                   />
        },
        {"title": "Description", "field": "description",
        "filterFn" : x => `description LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.description : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.description}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Location", "field": "location",
        "filterFn" : x => `location LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.location : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.location}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.location == ""}
                                   />
        },
        {"title": "Employee ID", "field": "employee_id", editable: false, defaultSort: 'desc',
        "filterFn" : x => `emp_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.employee_id : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},},
      ],
      filters: {},
      actions: [
        row => ({
            icon: () => {
                return <RenewPwdIcon/>;
            },
            tooltip: 'Allow password reset?',
            onClick: (e, c) => {
                this.props.allowPwdReset({type: "employee", id: c.employee_id}).then((res) => {
                  if(res){
                    this.myRef.current.onQueryChange();
                    this.props.enqueueSnackbar('Password reset allowed', {variant: 'success'})
                  }else{
                    this.props.enqueueSnackbar('Could not allow pwd reset', {variant: 'error'})
                  }
                })
            },
            hidden: row.pwd_reset_on
        }),
      ]
    };
  }

  componentDidMount(){
    this.props.getGridData("employees", this.state.filters);
  }

  render(){
    return (
      <div>
        <MaterialGrid
          title={this.state.title}
          columns={this.state.columns}
          add={true}
          remove={true}
          edit={true}
          refresh={true}
          id_column="employee_id"
          data_type="employees"
          actions={this.state.actions}
          tableRef={this.myRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.get.employees,
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, allowPwdReset,checkEmail}
)(EmployeeDashboard))
