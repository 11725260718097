import React from "react";
import { connect } from 'react-redux'

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "./Grid/GridItem.js";
import GridContainer from "./Grid/GridContainer.js";
import MaterialGrid from 'components/material_grid'
import Select from 'react-select'
import { withSnackbar, closeSnackbar } from 'notistack';

import {DoneActionIcon, AcknowledgeIcon} from 'components/table_icons';
import {completeTask, deleteAlerts, getGridData} from 'actions'

import {LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "../variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const minWidth1 = 200;


class StatsDisplay extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myRef2 = React.createRef();
    this.state = {
      columns: [
          {"title": "Message", "field": "message",
          render: rowData => <TooltipTypography data={rowData.message}/>,
          "filterFn" : x => `message LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
          "headerStyle": {minWidth: 2*minWidth1}, "cellStyle": {minWidth: 2*minWidth1}},
          {"title": "Type", "field": "type", "editable": false,
          "filterFn" : x => `type LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`},
          {"title": "Name", "field": "additional_info.name", "editable": false,
          render: rowData => <TooltipTypography data={rowData.additional_info.name}/>,
          filtering: false,
          "headerStyle": {minWidth: minWidth1}, "cellStyle": {minWidth: minWidth1}},
          {"title": "Company", "field": "additional_info.company", "editable": false,
          filtering: false,
          render: rowData => <TooltipTypography data={rowData.additional_info.company}/>,
          "headerStyle": {minWidth: minWidth1}, "cellStyle": {minWidth: minWidth1}},
        ],
        columns2: [
          {"title": "Action", "field": "action",
          "headerStyle": {minWidth: minWidth1}, "cellStyle": {minWidth: minWidth1},
          "filterFn" : x => `action LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`},
          {"title": "Priority", "field": "priority",
          "headerStyle": {minWidth: minWidth1}, "cellStyle": {minWidth: minWidth1},
          "filterFn" : x => `priority LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`},
          {"title": "Client", "field": "client_id",
          "headerStyle": {minWidth: minWidth1}, "cellStyle": {minWidth: minWidth1},
          "filterFn" : x => `client_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`},
          {"title": "Job", "field": "job_id",
          "headerStyle": {minWidth: minWidth1}, "cellStyle": {minWidth: minWidth1},
          "filterFn" : x => `job_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`}
        ],
        actions: [
          row => ({
              icon: () => {
                  return <AcknowledgeIcon/>;
              },
              tooltip: 'Mark alert as complete',
              onClick: (e, c) => {
                  this.props.deleteAlerts({id: c.alert_id}).then((res) => {
                    if(res){
                      this.myRef.current.onQueryChange();
                      this.props.enqueueSnackbar('Alert marked as completed', {variant: 'success'})
                    }else{
                      this.props.enqueueSnackbar('Alert could not be marked as completed', {variant: 'error'})
                    }
                  })
              },
          }),
        ],
        actions2: [
          row => ({
              icon: () =>  <DoneActionIcon/>,
              tooltip: 'Complete Task',
              onClick: (e, c) => {
                  console.log(e);
                  this.props.completeTask(c.task_id).then((res) => {
                    if(res){
                      this.myRef2.current.onQueryChange();
                      this.props.getGridData("stats", {});
                      this.props.enqueueSnackbar('Task Completed', {variant: 'success'})
                    }else{
                      this.props.enqueueSnackbar('Task could not be completed', {variant: 'error'})
                    }
                  })
              },
              hidden: row.max_priority != row.priority
          }),
        ]
      }
  }

  render(){
    return (
      <div>
        <h2>Clients</h2>
        <MaterialGrid
          title={"Alerts"}
          columns={this.state.columns}
          add={false}
          remove={false}
          edit={false}
          refresh={true}
          id_column="alert_id"
          data_type="alerts"
          actions={this.state.actions}
          pageSize={10}
          tableRef={this.myRef}
        />
        <h2>Affidavits</h2>
        <MaterialGrid
          title={"Outstanding Tasks"}
          columns={this.state.columns2}
          add={false}
          remove={false}
          edit={false}
          refresh={true}
          id_column="task_id"
          data_type="tasks"
          actions={this.state.actions2}
          pageSize={10}
          tableRef={this.myRef2}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken,
    stats: state.get.stats,
    last_update_stats: state.get.stats_update_time,
    alerts: state.get.alerts,
    tasks: state.get.tasks,
    jobs: state.get.jobs,
    clients: state.get.clients
  }
}

export default withSnackbar(connect(
  mapStateToProps, {completeTask, deleteAlerts, getGridData}
)(StatsDisplay))
