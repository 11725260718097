import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {getGridData, addData, deleteData, editData, enterModal} from '../../actions'
import {statement_email, invoice_email} from '../../variables/email_templates';
import {LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon,
    SendIcon,
    AssignmentTurnedInIcon
} from 'components/table_icons';

import PDFDisplay from "components/pdf_display";

import BillingModals from "components/billing_modals";

const minWidth1 = 150;

class BillingDashboard extends Component {

  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.myRef2 = React.createRef();

    this.state = {
      title: "Invoices",
      title2: "Statements",
      columns: [
        {"title": "Invoice No.", "field": "id", defaultSort: 'desc',
        "filterFn" : x => `rec_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.id}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Date Issued", "field": "date_issued",
        "filterFn" : x => `date_issued LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.date_issued}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Attorney", "field": "client",
        "filterFn" : x => `client LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.client}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Firm", "field": "company",
        "filterFn" : x => `company LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.company}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Client Ref.", "field": "client_ref",
        "filterFn" : x => `client_ref LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.client_ref}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},},
        {"title": "Total Invoiced", "field": "total_invoiced",
        "filterFn" : x => `total_invoiced LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={`$${(rowData.total_invoiced / 100.0).toFixed(2)}`}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Total Paid", "field": "total_paid",
        "filterFn" : x => `total_paid LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={`$${(rowData.total_paid / 100.0).toFixed(2)}`}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Total Remaining", "field": "total_remaining",
        filtering: false,
        render: rowData => <TooltipTypography data={`$${(rowData.total_remaining / 100.0).toFixed(2)}`}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Total Jobs Included", "field": "num_jobs",
        "filterFn" : x => `num_jobs LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.num_jobs}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
      ],
      columns2: [
        {"title": "Statement No.", "field": "statement_id", defaultSort: 'desc',
        "filterFn" : x => `statement_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.statement_id}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Date Issued", "field": "date_issued",
        "filterFn" : x => `date_issued LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.date_issued}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Firm", "field": "firm",
        "filterFn" : x => `firm LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.firm}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "Start Period", "field": "start_date",
        "filterFn" : x => `start_date LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.start_date}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
        {"title": "End Period", "field": "end_date",
        "filterFn" : x => `end_date LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData.end_date}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1}},
      ],
      actions1: [
        row => ({
          icon: () => {
              return <AssignmentTurnedInIcon />;
          },
          tooltip: 'Open PDF',
          onClick: (e, c) => {
              this.props.enterModal("display", c.url);
          }
      })
    ],
      actions2: [
        row => ({
          icon: () => {
              return <AssignmentTurnedInIcon />;
          },
          tooltip: 'Open PDF',
          onClick: (e, c) => {
              console.log(c.url)
              this.props.enterModal("display", c.url);
          }
      }),
    ],
    };
  }

  render(){

    console.log("mode")
    console.log(this.props.mode)

    return (
      <div>
      {this.props.mode ?
      <MaterialGrid
        title={this.state.title}
        columns={this.state.columns}
        add={false}
        remove={true}
        edit={false}
        refresh={true}
        id_column="id"
        data_type="invoices"
        actions={this.state.actions1}
        tableRef={this.myRef}
        />
        : null}
      {this.props.mode ? null:
      <MaterialGrid
        title={this.state.title2}
        columns={this.state.columns2}
        add={false}
        remove={true}
        edit={false}
        refresh={true}
        id_column="statement_id"
        data_type="statements"
        actions={this.state.actions2}
        tableRef={this.myRef2}
      />}

      <BillingModals tableRef={this.myRef} tableRef2={this.myRef2}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statements: state.get.statements,
    invoices: state.get.invoices,
    mode: state.update.billing_chart
  }
}

export default connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, enterModal}
)(BillingDashboard)
