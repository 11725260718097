import React, { useContext, Component } from 'react';
import { connect } from 'react-redux'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    TextField,
    MenuItem,
    CircularProgress
} from '@material-ui/core';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';

import {enterModal, addDocuments, selectFiles} from 'actions'

import { MIME_TYPES } from 'util/mime';



class UploadForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      types: [],
      uploadInProgress: false,
    };

  }

  destinationFolders = () => {
    return this.props.affidavit_menu_on ? [
        { name: 'affidavits', label: 'Affidavit' },
      ]:[
        { name: 'miscellaneous', label: 'Miscellaneous' },
        { name: 'docs_to_serve', label: 'Docs To Serve' },
        { name: 'affidavits', label: 'Affidavit' },
        { name: 'invoices', label: 'Invoice' }
    ];
  }

  handleClose = () => {
    this.setState({files: [], uploadInProgress: false})
    this.props.enterModal("dismiss", "docs");
  }

  onSelectFiles = () => {
    this.props.selectFiles().then(_files => {
      const newFiles = _files.map(f => {
          return {
              rawFile: f,
              name: f.name.split('.')[0],
              type: f.type,
              destinationFolder: 'affidavits'
          };
      })
      this.setState({files: newFiles});
    });
  }

  submitDocs = (values) => {
    this.setState({uploadInProgress: true});
    this.props.addDocuments({
      ...values,
      type: this.props.type,
      ref_id: this.props.current_jobs[0].job_id
    }).then((resp) => {
      this.handleClose();
      this.props.enqueueSnackbar('Files uploaded successfully.', {variant: 'success'});
    }).catch(e => {
      console.error(e);
      this.props.enqueueSnackbar('File upload failed.', {variant: 'error'});
    }).finally(() => {
      this.setState({uploadInProgress: false});
    });
  }

  render(){

    console.log(this.state.files)

    return(
      <>
          <Dialog
              open={this.props.doc_upload_menu_on}
              onClose={this.handleClose}
              fullWidth
          >
              <DialogTitle>Upload Files</DialogTitle>
              {this.state.uploadInProgress && (
                  <DialogContent>
                      <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height={200}
                      >
                          <CircularProgress></CircularProgress>
                      </Box>
                  </DialogContent>
              )}
              {!this.state.uploadInProgress && this.state.files.length === 0 && (
                  <DialogContent>
                      <DialogContentText>
                          Click the "Select Files" button to browse local
                          filesystem.
                      </DialogContentText>
                      <Box textAlign="center" p={2}>
                          <Button onClick={this.onSelectFiles} variant="contained">
                              Select Files
                          </Button>
                      </Box>
                  </DialogContent>
              )}
              {!this.state.uploadInProgress && this.state.files.length > 0 && (
                  <DialogContent>
                      <Formik
                          initialValues={{ files: this.state.files }}
                          onSubmit={values => this.submitDocs(values)}
                          render={props => {
                            return <form noValidate onSubmit={props.handleSubmit} id="upload-form">
                                {props.values.files.map((f, i) => (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box flex={2} p={1}>
                                            <TextField
                                                fullWidth
                                                name={`files[${i}].name`}
                                                label="Name"
                                                value={f.name}
                                                margin="normal"
                                                helperText={
                                                    f.name.length === 0 && 'File name is required'
                                                }
                                                error={f.name.length === 0}
                                                onChange={props.handleChange}
                                            />
                                        </Box>
                                        <Box flex={1} p={1}>
                                            <TextField
                                                fullWidth
                                                name={`files[${i}].type`}
                                                label="Type"
                                                value={f.type}
                                                margin="normal"
                                                select
                                                onChange={props.handleChange}
                                            >
                                                {MIME_TYPES.map((mt, j) => (
                                                    <MenuItem key={j} value={mt.type}>
                                                        {mt.extension}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                        <Box flex={2} p={1}>
                                            <TextField
                                                name={`files[${i}].destinationFolder`}
                                                fullWidth
                                                label="Destination folder"
                                                value={f.destinationFolder}
                                                margin="normal"
                                                select
                                                onChange={props.handleChange}
                                            >
                                                {this.destinationFolders().map((folder, j) => (
                                                    <MenuItem key={j} value={folder.name}>
                                                        {folder.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                    </Box>
                                ))}
                            </form>
                          }}

                      />
                  </DialogContent>
              )}
              <DialogActions>
                  <Box p={1} pr={0}>
                      <Button
                          onClick={this.handleClose}
                          color="primary"
                      >
                          Cancel
                      </Button>
                  </Box>
                  <Box p={1}>
                      {this.state.files.length > 0 && (
                          <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              form="upload-form"
                              autoFocus
                          >
                              Begin upload
                          </Button>
                      )}
                  </Box>
              </DialogActions>
          </Dialog>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken,
    doc_upload_menu_on: state.update.doc_upload_menu_on,
    current_jobs: state.update.current_jobs,
    affidavit_menu_on: state.update.affidavit_menu_on,
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {enterModal, addDocuments, selectFiles}
)(UploadForm))
