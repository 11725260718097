import {
	LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT_FAILED,
  LOGOUT_SUCCEEDED,
	EMPLOYEE_LOADED
} from './types'

import axios from 'axios';
import { Employee_ApiKey, ServerPath } from '../tempfile';


export const login = ({ username, password, clientId }) => {
  return (dispatch, getState) => {
    const requestBody = {
        API_key: Employee_ApiKey,
        user: username,
        password: password
    };

    if (clientId) {
        requestBody.client_id = clientId;
    }

		console.log('login[data]: ', requestBody);
    return axios.post(ServerPath + `/api/login`, requestBody).then(response => {
			console.log('login[resp]: ', response);
        if (!response.data.success) {
          dispatch({ type: LOGIN_FAILED });
          return Promise.resolve(false);
        }else{
          dispatch({ type: LOGIN_SUCCEEDED, payload: response.data.token });
					window.localStorage.setItem('username', JSON.stringify(username));
					window.localStorage.setItem('password', JSON.stringify(password));
					console.log("credentials saved")
          return Promise.resolve(true);
        }
    });
  }
};

export const renewToken = (token, expiration) => {
	return (dispatch, getState) => {
		if(token !== null && expiration && parseInt(expiration) > new Date().getTime()){
			dispatch({ type: LOGIN_SUCCEEDED, payload: token });
			return Promise.resolve(true);
		}else{
			dispatch({ type: LOGIN_FAILED });
			return Promise.resolve(false);
		}
	}
};

export const logout = (token) => {
	console.log("logout")
	console.log(token)
  return (dispatch) => {
    return axios.post(ServerPath + `/api/logout`, { token }).then(response => {
      if (!response.data.success) {
        dispatch({ type: LOGOUT_FAILED });
				console.log("log out failed")
        return Promise.resolve(false);
      }else{
        dispatch({ type: LOGOUT_SUCCEEDED });
				window.localStorage.removeItem('username');
				window.localStorage.removeItem('password');
				console.log("logged out")
        return Promise.resolve(true);
      }
    });
  }
};

export const getEmployee = () => {
  return (dispatch, getState) => {

		const {apiToken} = getState().auth;

		console.log("get employee")
		console.log(apiToken)

    return axios.post(ServerPath + `/api/getEmployee`, { token: apiToken }).then(response => {
			console.log(response)
      if (!response.data.success) {
				console.log("getEmployee failed")
        dispatch({ type: EMPLOYEE_LOADED, payload: null });
        return Promise.resolve(false);
      }else{
				console.log(response.data.employee)
        dispatch({ type: EMPLOYEE_LOADED, payload: response.data.employee });
        return Promise.resolve(true);
      }
    });
  }
};
