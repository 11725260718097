import React, { Component } from 'react';
import { connect } from 'react-redux'
import HamburgerMenu from '../../components/hamburger_menu'
import {login, getZipcodes, getEmployee, getCodes, getClients, getProducts,getCourts, getVendors, getCases, getAddresses, renewToken, getDocParsing} from '../../actions'
import { withRouter } from 'react-router-dom';

import CaseDashboard from '../dashboards/case_dashboard'
import ClientDashboard from '../dashboards/client_dashboard'
import EmployeeDashboard from '../dashboards/employee_dashboard'
import JobDashboard from '../dashboards/job_dashboard'
import DocParsingDashboard from '../dashboards/parsing_dashboard'
import ReportDashboard from '../dashboards/report_dashboard'
import StatsDashboard from '../dashboards/stats_dashboard'
import VendorDashboard from '../dashboards/vendor_dashboard'
import BillingDashboard from '../dashboards/billing_dashboard'

class MainDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hamburgerOpen: false,
      loading: null,
    };
  }

  componentDidMount(){

    window.addEventListener('popstate', function (event){
      console.log("pop")
      this.props.history.go(1);
    });

    console.log("main will load")
    const username = JSON.parse(window.localStorage.getItem('username')) || null;
    const password = JSON.parse(window.localStorage.getItem('password')) || null;
    this.props.login({username, password}).then((res) => {
      if(!res){
        this.props.history.push('/login');
      }
    });
    const smallWait = setInterval(() => {
      if(this.props.apiToken !== null && this.props.login_success){
				clearInterval(smallWait);
        this.props.getEmployee().then(()=>{
          this.props.getClients({});
          this.props.getCodes({});
          this.props.getProducts({});
          this.props.getCourts({});
          this.props.getVendors({});
          this.props.getCases({});
          this.props.getAddresses({});
          this.props.getZipcodes({});
          this.props.getDocParsing({});

        })
        document.addEventListener('keydown', this.handleKeyDown.bind(this))
      }
    }, 100);
  }

  handleKeyDown(e) {
    if (e.which === 113) {
      console.log("F2 Pressed");
    } else if (e.which === 114) {
      console.log("F3 Pressed");
    } else if (e.which === 115) {
      console.log("F4 Pressed");
    } else if (e.which === 116) {
      console.log("F5 Pressed");
    } else if (e.shiftKey && e.which === 117) {
      console.log("F6 + Shift Pressed");
    } else if (e.which === 118) {
      console.log("F7 Pressed");
    }
  }

  getCurrentScreen(){
    switch(this.props.currentScreen){
      case 0:
        return(<StatsDashboard/>)
      case 1:
        return(<CaseDashboard/>)
      case 2:
        return(<JobDashboard/>)
      case 3:
        return(<ReportDashboard/>)
      case 4:
        return(<ClientDashboard/>)
      case 5:
        return(<EmployeeDashboard/>)
      case 6:
        return(<VendorDashboard/>)
      case 7:
        return(<BillingDashboard/>)
      case 8:
        return(<DocParsingDashboard/>)
    }
  }

  render(){

    return (
      <div style={{justifyContent: 'center', alignItems: 'center'}}>
      {this.props.employee && this.props.codes ? <HamburgerMenu children={this.getCurrentScreen()}/> : null}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken,
    login_success: state.auth.login_success,
    employee: state.auth.employee,
    currentScreen: state.update.currentScreen,
    loadingScreen: state.update.isUpdating,
    codes: state.get.codes,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {login, getZipcodes, getEmployee, getCodes,getClients, getProducts,getCourts, getVendors, getCases, getAddresses, renewToken, getDocParsing}
)(MainDashboard))
