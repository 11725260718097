// AUTH
export const LOGIN_SUCCEEDED = "login_success";
export const LOGIN_FAILED = "login_fail";
export const LOGOUT_SUCCEEDED = "logout_success";
export const LOGOUT_FAILED = "logout_fail";
export const EMPLOYEE_LOADED = "employee_loaded";

// UPDATE
export const UPDATE_COMPLETED = "update_completed";
export const SWITCH_SCREEN = "switch_screen";
export const APPROVE_DOC_PARSING = "approve_doc_parsing";
export const EMAIL_SENT = "email_sent";
export const TOGGLE_BATCH_ACTIONS = "toggle_batch_actions";
export const TOGGLE_PDF = "toggle_pdf";
export const TOGGLE_BILLING = "toggle_billing";
export const ENABLE_BATCH_ACTIONS = "enable_batch_actions";
export const UPDATE_SELECTED_ROWS = "update_selected_rows";
export const ENABLE_INVOICE_ACTIONS = "enable_invoice_actions";
export const ENABLE_PAYMENT_ACTIONS = "enable_payment_actions";
export const ENABLE_STATEMENT_ACTIONS = "enable_statement_actions";
export const ENABLE_AFFIDAVIT_ACTIONS = "enable_affidavit_actions";
export const ENABLE_EFILING_ACTIONS = "enable_efiling_actions";
export const DISABLE_ACTIONS = "disable_actions";
export const ENABLE_DOC_UPLOAD_ACTIONS = "enable_doc_upload_actions";
export const ENABLE_DOC_DISPLAY_MODAL = "enable_doc_display_modal";
export const ENABLE_JOB_CHARGES_MODAL = "enable_job_charges_modal";
export const ENABLE_PRODUCTS_MODAL = "enable_products_modal";
export const APPROVE_CHARGES = "approve_charges";
export const PWD_RESET = "pwd_reset";
export const UPDATE_INDEX = "update_index";
export const ENABLE_VENDOR_PRICING_MODAL = "enable_vendor_pricing_modal";
export const INVOICE_PREVIEW_ON = "invoice_preview_on";
export const CLEAR_DOC = "clear_doc";
export const ADD_PAYMENT = "add_payment";
export const SUBMIT_PAYMENT_DONE = "submit_payment_done";
export const ENABLE_EXCEL_MODAL = "enable_excel_modal";
export const DUPLICATE_JOB = "duplicate_job";
export const EXCEL_ADDED = "add_excel";
export const ENABLE_AFFIDAVIT_UPLOAD = "enable_affidavit_upload";
export const CREATE_AMMENDMENT = "create_ammendment";
export const ENABLE_ADDRESS_MODAL = "enable_address_modal";
export const CANCEL_SELECTION = "cancel_selection";
export const ENABLE_EMAIL_MODAL = "enable_email_modal";
export const ASSIGN_JOBS_MODAL = "assign_jobs_modal";
export const COMPLETE_TASK = "complete_task";
export const SERVE_DOC_MODAL = "serve_doc_modal";
export const OPEN_JOBS_PDF = "open_jobs_pdf";
export const OPEN_SEND_ALERT = "open_send_alert";
export const OPEN_CLIENT_SETTINGS = "open_client_settings";
export const ADD_FILTERS = "add_filters";
export const ADD_ROW = "add_row";

// GET
export const JOBS_RETURNED = "jobs_returned";
export const CASES_RETURNED = "cases_returned";
export const REPORTS_RETURNED = "reports_returned";
export const DOC_PARSING_RETURNED = "doc_parsing_returned";
export const ALERTS_RETURNED = "alerts_returned";
export const EMPLOYEES_RETURNED = "employees_returned";
export const CLIENTS_RETURNED = "clients_returned";
export const VENDORS_RETURNED = "vendors_returned";
export const COURTS_RETURNED = "courts_returned";
export const PRODUCTS_RETURNED = "products_returned";
export const INVOICES_RETURNED = "invoices_returned";
export const STATEMENTS_RETURNED = "statements_returned";
export const STATS_RETURNED = "get_stats";
export const NAMES_RETURNED = "get_names";
export const INVOICE_OPTIONS_RETURNED = "invoice_options_returned";
export const MESSAGES_RETURNED = "messages_returned";
export const DOCUMENTS_RETURNED = "documents_returned";
export const ADDRESSES_RETURNED = "addresses_returned";
export const TASKS_RETURNED = "tasks_returned";
export const DISPLAY_DOCS_RETURNED = "display_docs_returned";
export const ZIP_RETURNED = "zip_returned";
export const RECOMMEND_SERVICE = "recommend_service";

export const SOFT_REFRESH = "soft_refresh";
export const HARD_REFRESH = "hard_refresh";

// ADD
export const JOBS_ADDED = "jobs_added";
export const CASES_ADDED = "cases_added";
export const REPORTS_ADDED = "reports_added";
export const DOC_PARSING_ADDED = "doc_parsing_added";
export const ALERTS_ADDED = "alerts_added";
export const EMPLOYEES_ADDED = "employees_added";
export const CLIENTS_ADDED = "clients_added";
export const VENDORS_ADDED = "vendors_added";
export const COURTS_ADDED = "courts_added";
export const PRODUCTS_ADDED = "products_added";
export const INVOICES_ADDED = "invoices_added";
export const STATEMENTS_ADDED = "statements_added";
export const MESSAGES_ADDED = "messages_added";
export const AFFIDAVITS_ADDED = "affidavits_added";
export const VENDOR_PRICING_ADDED = "vendor_pricing_added";


// DELETE
export const JOBS_DELETED = "jobs_deleted";
export const CASES_DELETED = "cases_deleted";
export const REPORTS_DELETED = "reports_deleted";
export const DOC_PARSING_DELETED = "doc_parsing_deleted";
export const ALERTS_DELETED = "alerts_deleted";
export const EMPLOYEES_DELETED = "employees_deleted";
export const CLIENTS_DELETED = "clients_deleted";
export const VENDORS_DELETED = "vendors_deleted";
export const COURTS_DELETED = "courts_deleted";
export const PRODUCTS_DELETED = "products_deleted";
export const INVOICES_DELETED = "invoices_deleted";
export const STATEMENTS_DELETED = "statements_deleted";
export const DOCS_ADDED = "docs_added";
export const VENDOR_PRICING_DELETED = "vendor_pricing_deleted";

// EDIT
export const JOBS_EDITED = "jobs_edited";
export const CASES_EDITED = "cases_edited";
export const REPORTS_EDITED = "reports_edited";
export const DOC_PARSING_EDITED = "doc_parsing_edited";
export const ALERTS_EDITED = "alerts_edited";
export const EMPLOYEES_EDITED = "employees_edited";
export const CLIENTS_EDITED = "clients_edited";
export const VENDORS_EDITED = "vendors_edited";
export const COURTS_EDITED = "courts_edited";
export const PRODUCTS_EDITED = "products_edited";
export const INVOICES_EDITED = "invoices_edited";
export const STATEMENTS_EDITED = "statements_edited";
export const EDIT_SELECTION = "edit_selection";
export const CHARGES_EDITED = "charges_edited";
export const VENDOR_PRICING_EDITED = "vendor_pricing_edited";
