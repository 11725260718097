import {
	JOBS_DELETED,
  CASES_DELETED,
  REPORTS_DELETED,
  DOC_PARSING_DELETED,
  ALERTS_DELETED,
  EMPLOYEES_DELETED,
  CLIENTS_DELETED,
  VENDORS_DELETED,
  COURTS_DELETED,
  PRODUCTS_DELETED,
  INVOICES_DELETED,
  STATEMENTS_DELETED,
	VENDOR_PRICING_DELETED
} from '../actions/types'

import React from 'react';

const INITIAL_STATE = {
	deletingSuccess: false
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
    case JOBS_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case CASES_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case REPORTS_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case DOC_PARSING_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case ALERTS_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case EMPLOYEES_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case CLIENTS_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case VENDORS_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case COURTS_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case PRODUCTS_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case INVOICES_DELETED:
      return { ...state, deletingSuccess: action.payload };
    case STATEMENTS_DELETED:
      return { ...state, deletingSuccess: action.payload };
		case VENDOR_PRICING_DELETED:
			return { ...state, deletingSuccess: action.payload };
    default:
      return { ...state };
  }
}
