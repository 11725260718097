import {
	JOBS_RETURNED,
  CASES_RETURNED,
  REPORTS_RETURNED,
  DOC_PARSING_RETURNED,
  ALERTS_RETURNED,
  EMPLOYEES_RETURNED,
  CLIENTS_RETURNED,
  VENDORS_RETURNED,
  COURTS_RETURNED,
  PRODUCTS_RETURNED,
  INVOICES_RETURNED,
  STATEMENTS_RETURNED,
	STATS_RETURNED,
	NAMES_RETURNED,
	INVOICE_OPTIONS_RETURNED,
	MESSAGES_RETURNED,
	DOCUMENTS_RETURNED,
	ADDRESSES_RETURNED,
	TASKS_RETURNED,
	DISPLAY_DOCS_RETURNED,
	SOFT_REFRESH,
	HARD_REFRESH,
	ZIP_RETURNED,
	APPROVE_DOC_PARSING,
	RECOMMEND_SERVICE
} from './types'

import axios from 'axios';
import {completeUpdate} from '.'
import { ServerPath, client_types,rule_types, employee_types, rec_types } from '../tempfile';
import moment from "moment";

import printJS from 'print-js'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
var mime = require('mime-types')
const date_format = 'MMMM Do YYYY, h:mm:ss a';

export const getGridData = (type, filters) => {
	return (dispatch, getState) => Promise.resolve().then(() => {
		switch (type){
			case "codes":
				console.log("get names");
				return dispatch(getCodes());
			case "zipcodes":
				console.log("get zipcodes");
				return dispatch(getZipcodes());
			case "jobs":
				console.log("get jobs")
        return dispatch(getJobs(filters));
			case "cases":
				console.log("get cases")
        return dispatch(getCases(filters));
			case "reports":
				console.log("get reports")
        return dispatch(getReports(filters));
			case "doc_parsing":
				console.log("get doc parsing")
        return dispatch(getDocParsing(filters));
			case "alerts":
				console.log("get alerts")
        return dispatch(getAlerts(filters));
			case "employees":
				console.log("get employees")
        return dispatch(getEmployees(filters));
			case "clients":
				console.log("get clients")
        return dispatch(getClients(filters));
			case "vendors":
				console.log("get vendors")
        return dispatch(getVendors(filters));
			case "courts":
				console.log("get courts")
        return dispatch(getCourts(filters));
			case "products":
				console.log("get products")
        return dispatch(getProducts(filters));
			case "invoices":
				console.log("get invoices")
        return dispatch(getInvoices(filters));
			case "invoice_options":
				console.log("get invoice options")
        return dispatch(getInvoiceOptions(filters));
			case "statements":
				console.log("get statements")
        return dispatch(getStatements(filters));
			case "stats":
				console.log("get stats")
				return dispatch(getStats(filters));
			case "documents":
				console.log("get documents");
				return dispatch(getDocuments(filters));
			case "addresses":
				console.log("get addresses");
				return dispatch(getAddresses(filters));
			case "tasks":
				console.log("get tasks");
				return dispatch(getTasks(filters));
			case "job_docs":
				console.log("job docs");
				return dispatch(getJobDocs(filters));
		}
  })
}

export const getCodes = (filters) => {
	return (dispatch, getState) => {
    return axios.post(ServerPath + `/api/get`, {key: "status"}).then(response => {
			return axios.post(ServerPath + `/api/get`, {key: "priority"}).then(response2 => {
				console.log(response.data.codes)
				console.log(response2.data.codes)
				let codes = {"status": response.data.codes, "priority": response2.data.codes}
        dispatch({ type: NAMES_RETURNED, payload: codes });
        return Promise.resolve(true);
			});
    });
  }
}

export const getZipcodes = (filters) => {
	return (dispatch, getState) => {
		console.log('getZip[data]')
    return axios.post(ServerPath + `/api/get`, {key: "zipcodes"}).then(response => {
				console.log('getZip[resp]: ', response)
        dispatch({ type: ZIP_RETURNED, payload: response.data.zipcodes });
        return Promise.resolve(true);
		});
  }
}

export const getTasks = () => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;

		const data = {
			token: apiToken,
		};

		console.log(`getTasks[data]`, data);
		return axios.post(ServerPath + `/api/getTasks`, data).then(response => {

				response.data.tasks = response.data.tasks.map(t => {
					return {...t, max_priority: Math.max(...response.data.tasks.filter(t2 => t2.job_id == t.job_id).map(t => parseInt(t.priority)))}
				})

				console.log(`getTasks[response]`, response);

				dispatch({ type: TASKS_RETURNED, payload: response.data.tasks });
				console.log('tasks: ', response)
				return Promise.resolve({data: response.data.tasks, total: response.data.total});
		});
	}
}

export const getJobs = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const { codes, cases, clients } = getState().get;

		const data = {
			token: apiToken,
			filters: filters
		};
		console.log('getJobs[data]: ', data);
    return axios.post(ServerPath + `/api/adminGetJobs`, data).then(response => {
				console.log(response)
        if (response.data.success) {

					for(let i = 0; i < response.data.jobs.length; i++){
						response.data.jobs[i]["job_status"] = codes.status.filter(obj => {
							return obj.code_id === response.data.jobs[i]["job_status"]
						})[0].name
						response.data.jobs[i]["affidavit_status"] = codes.status.filter(obj => {
							return obj.code_id === response.data.jobs[i]["affidavit_status"]
						})[0].name
						response.data.jobs[i]["invoice_status"] = codes.status.filter(obj => {
							return obj.code_id === response.data.jobs[i]["invoice_status"]
						})[0].name
						response.data.jobs[i]["priority"] = codes.priority.filter(obj => {
							return obj.code_id === response.data.jobs[i]["priority"]
						})[0].name
						response.data.jobs[i]["rec_type"] = rec_types[response.data.jobs[i]["rec_type"]];
						response.data.jobs[i]["type"] = rec_types[response.data.jobs[i]["type"]];

						response.data.jobs[i]["received_date"] = response.data.jobs[i]["received_date"] ? moment(response.data.jobs[i]["received_date"]).format(date_format) : "";
						response.data.jobs[i]["last_update"] = response.data.jobs[i]["last_update"] ? moment(response.data.jobs[i]["last_update"]).format(date_format) : "";
						response.data.jobs[i]["assigned"] = response.data.jobs[i]["assigned"] ? moment(response.data.jobs[i]["assigned"]).format(date_format) : "";
						response.data.jobs[i]["acknowledged"] = response.data.jobs[i]["acknowledged"] ? moment(response.data.jobs[i]["acknowledged"]).format(date_format) : "";
					}

					console.log(response.data.jobs)
          dispatch({ type: JOBS_RETURNED, payload: response.data.jobs });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.jobs, total: response.data.total});
        }else{
          dispatch({ type: JOBS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getCases = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetCases`, data).then(response => {
				console.log(response)
				if (response.data.success) {

					for(let i = 0; i < response.data.cases.length; i++){
						response.data.cases[i]["jurisdiction"] = response.data.cases[i]["jurisdiction"] === 1 ? "State" : "Federal";
						response.data.cases[i]["plt_type"] = rec_types[response.data.cases[i]["plt_type"]];
						response.data.cases[i]["date_filed"] = response.data.cases[i]["date_filed"] ? moment(response.data.cases[i]["date_filed"]).format(date_format) : "";
					}


          dispatch({ type: CASES_RETURNED, payload: response.data.cases });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.cases, total: response.data.total});
        }else{
          dispatch({ type: CASES_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getReports = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetReports`, data).then(response => {
				console.log('all reports: ', response)
				if (response.data.success) {

					for(let i = 0; i < response.data.reports.length; i++){
						response.data.reports[i]["type"] = response.data.reports[i]["type"] === 1 ? "Successful" : "Unsuccessful";
						response.data.reports[i]["completion_date"] = response.data.reports[i]["completion_date"] !== "" ? moment(response.data.reports[i]["completion_date"]).format(date_format) : "";
						response.data.reports[i]["log_date"] = response.data.reports[i]["log_date"] !== "" ? moment(response.data.reports[i]["log_date"]).format(date_format) : "";

					}

          dispatch({ type: REPORTS_RETURNED, payload: response.data.reports });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.reports, total: response.data.total});
        }else{
          dispatch({ type: REPORTS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getDocParsing = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetParsedDocs`, data).then(response => {
				console.log(response)
				if (response.data.success) {
					for(let i = 0; i < response.data.parsed_docs.length; i++){
						//response.data.parsed_docs[i].court_date = response.data.parsed_docs[i].court_date == "" ? "" : new Date(response.data.parsed_docs[i]["court_date"]).toLocaleString("en-US", {dateStyle: "long"});
						response.data.parsed_docs[i].court_date = response.data.parsed_docs[i].court_date ? moment(response.data.parsed_docs[i].court_date).format(date_format) : "";
						//response.data.parsed_docs[i].file_date = new Date(response.data.parsed_docs[i]["file_date"]).toLocaleString("en-US", {dateStyle: "long"});
						response.data.parsed_docs[i].file_date = response.data.parsed_docs[i].file_date ? moment(response.data.parsed_docs[i].file_date).format(date_format) : "";
						//response.data.parsed_docs[i].completion_time = new Date(response.data.parsed_docs[i]["completion_time"]).toLocaleString("en-US", {dateStyle: "long"});
						response.data.parsed_docs[i].completion_time = response.data.parsed_docs[i].completion_time ? moment(response.data.parsed_docs[i].completion_time).format(date_format) : "";
					}
          dispatch({ type: DOC_PARSING_RETURNED, payload: response.data.parsed_docs });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.parsed_docs, total: response.data.total});
        }else{
          dispatch({ type: DOC_PARSING_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getAlerts = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetAlerts`, data).then(response => {
				console.log(response)
				if (response.data.success) {

					for(let i = 0; i < response.data.alerts.length; i++){
						response.data.alerts[i]["type"] = response.data.alerts[i]["type"].toUpperCase()
					}

          dispatch({ type: ALERTS_RETURNED, payload: response.data.alerts });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.alerts, total: response.data.total});
        }else{
          dispatch({ type: ALERTS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getEmployees = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetEmployees`, data).then(response => {
				console.log(response)
				if (response.data.success) {

					for(let i = 0; i < response.data.employees.length; i++){
						response.data.employees[i].type = employee_types[response.data.employees[i].type];
					}

          dispatch({ type: EMPLOYEES_RETURNED, payload: response.data.employees });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.employees, total: response.data.total});
        }else{
          dispatch({ type: EMPLOYEES_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getClients = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetClients`, data).then(response => {
				console.log(response)
				if (response.data.success) {

					for(let i = 0; i < response.data.clients.length; i++){
						let type = response.data.clients[i]["type"];
						response.data.clients[i]["type"] = client_types[type];
						type = response.data.clients[i].address_type;
						response.data.clients[i].address_type = rec_types[type];
						response.data.clients[i].rules = response.data.clients[i].rules.map(x => {return {doc_type: x.doc_type, rule_id: rule_types[x.rule_id-1]}})
					}

          dispatch({ type: CLIENTS_RETURNED, payload: response.data.clients });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.clients, total: response.data.total});
        }else{
          dispatch({ type: CLIENTS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getVendors = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
		console.log('getVendors[data]: ', data);
    return axios.post(ServerPath + `/api/adminGetVendors`, data).then(response => {
				console.log(response)
				if (response.data.success) {
          dispatch({ type: VENDORS_RETURNED, payload: response.data.vendors });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.vendors, total: response.data.total});
        }else{
          dispatch({ type: VENDORS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getCourts = (filters) => {
	console.log("GETTING COURTS")
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetCourts`, data).then(response => {
				console.log(response)
				if (response.data.success) {
          dispatch({ type: COURTS_RETURNED, payload: response.data.courts });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.courts, total: response.data.total});
        }else{
          dispatch({ type: COURTS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getProducts = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetProducts`, data).then(response => {
				console.log(response)
				if (response.data.success) {
          dispatch({ type: PRODUCTS_RETURNED, payload: response.data.products });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.products, total: response.data.total});
        }else{
          dispatch({ type: PRODUCTS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getInvoices = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};

    return axios.post(ServerPath + `/api/adminGetInvoices`, data).then(response => {
				console.log('getInvoices[resp]:', response)
				if (response.data.success) {
					for(let i = 0; i < response.data.invoices.length; i++){
						//response.data.invoices[i].date_issued = new Date(response.data.invoices[i]["date_issued"]).toLocaleString("en-US", {dateStyle: "long"});
						response.data.invoices[i].date_issued = response.data.invoices[i].date_issued ? moment(response.data.invoices[i].date_issued).format(date_format) : "";
					}

					response.data.invoices = response.data.invoices.map((invoice) => {return {...invoice, payment: 0.}})

          dispatch({ type: INVOICES_RETURNED, payload: response.data.invoices});
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.invoices.filter((invoice)=>{return invoice.parent_id == undefined}), total: response.data.total});
        }else{
          dispatch({ type: INVOICES_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getInvoiceOptions = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			type: filters.type
		};
		console.log(data)
    return axios.post(ServerPath + `/api/getInvoiceOptions`, data).then(response => {
				console.log(response)
				if (response.data.success) {
          dispatch({ type: INVOICE_OPTIONS_RETURNED, payload: response.data.invoice_options });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.invoice_options, total: response.data.total});
        }else{
          dispatch({ type: INVOICE_OPTIONS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getStatements = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetStatements`, data).then(response => {
				console.log(response)
				if (response.data.success) {
					for(let i = 0; i < response.data.statements.length; i++){
						response.data.statements[i].date_issued = response.data.statements[i].date_issued ? moment(response.data.statements[i].date_issued).format(date_format) : "";
						response.data.statements[i].start_date = response.data.statements[i].start_date ? moment(response.data.statements[i].start_date).format(date_format) : "";
						response.data.statements[i].end_date = response.data.statements[i].end_date ? moment(response.data.statements[i].end_date).format(date_format) : "";
					}
          dispatch({ type: STATEMENTS_RETURNED, payload: response.data.statements });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.statements, total: response.data.total});
        }else{
          dispatch({ type: STATEMENTS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getStats = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/getStats`, data).then(response => {
				console.log(response)
				if (response.data.success) {
          dispatch({ type: STATS_RETURNED, payload: response.data.stats });
					dispatch(completeUpdate())
          return Promise.resolve({data: response.data.stats, total: response.data.total});
        }else{
          dispatch({ type: STATS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getDocuments = (urls) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			urls: urls
		};

		console.log(data)
    return axios.post(ServerPath + `/api/getDocumentsByURL`, data).then(response => {
				console.log(response)
				if (response.data.success) {
            const extractFilename = url => url.split('/').pop();
            const d = response.data;

            const documents = urls.map((url, i) => {
                return {
                    filename: extractFilename(url),
                    bytes: d.bytes[i],
                    type: (d.types && d.types[i]) || 'application/pdf'
                };
            });
          dispatch({ type: DOCUMENTS_RETURNED, payload: documents });
					dispatch(completeUpdate())
          return Promise.resolve({data: documents, total: response.data.total});
        }else{
          dispatch({ type: DOCUMENTS_RETURNED, payload: null });
          return Promise.resolve(null);
        }
    });
  }
}

export const getJobDocs = (job_id) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			urls: ["docs_to_serve/job_" + job_id + "/*"]
		};

		const data2 = {
			token: apiToken,
			urls: ["miscellaneous/job_" + job_id + "/*"]
		};

		const data3 = {
			token: apiToken,
			urls: ["affidavits/" + job_id + "/Affidavit"]
		};

		console.log(data)
		console.log(data2)
		console.log(data3)
    return axios.post(ServerPath + `/api/getDocumentsByURL`, data).then(response => {
			return axios.post(ServerPath + `/api/getDocumentsByURL`, data2).then(response2 => {
				return axios.post(ServerPath + `/api/getDocumentsByURL`, data3).then(response3 => {
				console.log(response)
				console.log(response2)
				console.log(response3)
				if (response.data.success && response2.data.success) {
          const d = response.data;
					const d2 = response2.data;

          const documents = data.urls.map((url, i) => {
              return {
                  filename: d.names[i],
                  bytes: d.bytes[i],
                  type: mime.extension((d.types && d.types[i]) || 'application/pdf'),
									pages: d.pages[i]
              };
          }).filter(x => x.filename !== undefined);
					console.log('docs1: ', documents)
					const documents2 = data2.urls.map((url, i) => {
              return {
								filename: d2.names[i],
                  bytes: d2.bytes[i],
									type: mime.extension((d2.types && d2.types[i]) || 'application/pdf'),
									pages: d2.pages[i]
              };
          }).filter(x => x.filename !== undefined);
					console.log('docs2: ', documents2)

					let documents3 = [];

					if (response3.data.success) {
						const d3 = response3.data;
						documents3 = data3.urls.map((url, i) => {
								return {
										filename: d3.names[i],
										bytes: d3.bytes[i],
										type: mime.extension((d3.types && d3.types[i]) || 'application/pdf'),
										pages: d3.pages[i]
								};
						}).filter(x => x.filename !== undefined);
					}

					console.log('DOCS: ', {serve: documents, misc: documents2, aff: documents3, job_id: job_id})
          dispatch({ type: DISPLAY_DOCS_RETURNED, payload: {serve: documents, misc: documents2, aff: documents3, job_id: job_id} });
          return Promise.resolve({data: {serve: documents, misc: documents2, aff: documents3, job_id: job_id}, total: response.data.total});
				}else{
					dispatch({ type: DISPLAY_DOCS_RETURNED, payload: null });
          return Promise.resolve(null);
				}
	    });
		});
	 });
  }
}

export const getAddresses = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetAddresses`, data).then(response => {
				console.log('addresses: ', response)
				if (response.data.success) {
          dispatch({ type: ADDRESSES_RETURNED, payload: response.data.addresses });
					dispatch(completeUpdate())
          return Promise.resolve(response.data.addresses);
        }else{
          dispatch({ type: ADDRESSES_RETURNED, payload: response.data.addresses });
          return Promise.resolve(null);
        }
    });
  }
}

export const massPrintAffidavits = (jobs_selected) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: jobs_selected.map(x => x.job_id)
		};

		console.log(data)
    return axios.post(ServerPath + `/api/getPrintAffidavits`, data).then(response => {
			console.log('massPrint[resp]', response.data)
			if (response.data.success) {
				console.log("success")
				const extractFilename = url => url.split('/').pop();
				printJS({printable: response.data.bytes, type: 'pdf', base64: true});
				return Promise.resolve(true);
			}else{
				console.log("failure")
				return Promise.resolve(false);
			}
		})
  }
}

export const massPrintDocsToServe = (jobs_selected) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: jobs_selected.map(x => x.job_id)
		};

		console.log(data)
    return axios.post(ServerPath + `/api/getServeDocs`, data).then(response => {
			console.log('massPrint[resp]', response.data)
			if (response.data.success) {
				console.log("success")
				const extractFilename = url => url.split('/').pop();
				printJS({printable: response.data.bytes, type: 'pdf', base64: true});
				return Promise.resolve(true);
			}else{
				console.log("failure")
				return Promise.resolve(false);
			}
		})
	}
}

export const approveDocParsing = (val) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			parse_id: val
		};
		console.log('docParsing[data]', data);
    return axios.post(ServerPath + `/api/approveDocParsing`, data).then(response => {
			console.log('docParsing[resp]', response)
			if(response.data.success){
      	dispatch({ type: APPROVE_DOC_PARSING, payload: {case: response.data.case, parse_id: val} });
			}
      return Promise.resolve(response.data.success);
    });
  }
};

export const recommendService = (job_ids, vetos) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: job_ids,
			vetos: vetos
		};
		console.log('recommendService[data]', data);
    return axios.post(ServerPath + `/api/recommendService`, data).then(response => {
			console.log('recommendService[resp]', response)
      dispatch({ type: RECOMMEND_SERVICE, payload: response.data.recommendations });
      return Promise.resolve(response.data.recommendations);
    });
  }
}

export const softRefresh = (val) => {
	return (dispatch) => {
		console.log('val', val);
  	dispatch({ type: SOFT_REFRESH, payload: val});
  }
}

export const hardRefresh = (type) => {
	return (dispatch) => {
		if(type == "jobs"){
			console.log("reset")
			dispatch(getJobs({}));
		}else if(type == "cases"){
			dispatch(getCases({}));
		}else if(type == "reports"){
			dispatch(getReports({}));
		}else if(type == "clients"){
			dispatch(getClients({}));
		}else if(type == "vendors"){
			dispatch(getVendors({}));
		}else if(type == "employees"){
			dispatch(getEmployees({}));
		}else if(type == "invoices"){
			dispatch(getInvoices({}));
		}else if(type == "statements"){
			dispatch(getStatements({}));
		}else if(type == "docparsing"){
			dispatch(getDocParsing({}));
		}
  	//dispatch({ type: HARD_REFRESH});
  }
}
