import React from 'react';
import logo from './logo.svg';
import './App.css';

import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { SnackbarProvider } from 'notistack';
import ReduxThunk from 'redux-thunk'
import reducers from './reducers';
import AppRouter from './Routes';


function App() {

  const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

  return (
    <Provider store={store}>
      <SnackbarProvider>
        <AppRouter />
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
