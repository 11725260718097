import {editable_user} from './user_file';

export const Employee_ApiKey = "b4975c56-6822-4742-8a61-4bb0319cfd99";

export const GoogleMaps_API_Key = "AIzaSyBJoPROkw9h1jAd0HLhnUXZ-D2h73LCRBw";

export const client_types = ["Attorney", "Accountant", "Paralegal", "Other"]
export const binary_types = ["No", "Yes"]
export const employee_types = ["Standard", "Accountant", "Admin", "Bot"]
export const rec_types = ["Company", "Physical Person"]
export const report_types = ["Unsuccessful", "Successful"]
export const rule_types = ["E-File", "Manual File", "Send Orig. Only"];

export const NYC_Zipcodes = [10001, 10002, 10003, 10004, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10012, 10013, 10014, 10016, 10017, 10018, 10019, 10020, 10021, 10022, 10023, 10024, 10025, 10026, 10027, 10028, 10029, 10030, 10031, 10032, 10033, 10034, 10035, 10036, 10037, 10038, 10039, 10040, 10041, 10043, 10044, 10045, 10055, 10060, 10065, 10069, 10075, 10080, 10081, 10087, 10090, 10101, 10102, 10103, 10104, 10105, 10106, 10107, 10108, 10109, 10110, 10111, 10112, 10113, 10114, 10115, 10116, 10117, 10118, 10119, 10120, 10121, 10122, 10123, 10124, 10125, 10126, 10128, 10129, 10130, 10131, 10132, 10133, 10138, 10150, 10151, 10152, 10153, 10154, 10155, 10156, 10157, 10158, 10159, 10160, 10161, 10162, 10163, 10164, 10165, 10166, 10167, 10168, 10169, 10170, 10171, 10172, 10173, 10174, 10175, 10176, 10177, 10178, 10179, 10185, 10199, 10203, 10211, 10212, 10213, 10242, 10249, 10256, 10258, 10259, 10260, 10261, 10265, 10268, 10269, 10270, 10271, 10272, 10273, 10274, 10275, 10276, 10277, 10278, 10279, 10280, 10281, 10282, 10285, 10286]

export const app_user = editable_user;
export const theme_color = app_user == "nh" ? "#213675" : "#2196f3"
export const iconColor = theme_color;
export const ServerPath = app_user == "nh" ? "https://nh-services.com" : "https://legalex-services.com";
