import React, {Component} from 'react';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {NYC_Zipcodes, GoogleMaps_API_Key} from '../tempfile'
import Geocode from "react-geocode";
import MapContainer from 'components/google_maps';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

Geocode.setApiKey(GoogleMaps_API_Key);
Geocode.setLanguage("en");
Geocode.setRegion("us");
Geocode.enableDebug();

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(0.5),
  }
});


class MouseOverPopover extends Component {

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      distanceVar: null,
      coor: null,
      gps_coor: null
    };
  }

  componentDidMount() {
    const check = setInterval(() => {
      if(Boolean(this.state.anchorEl)){
        clearInterval(check);
        this.getGPSCoor(`${this.props.rowData.address.address1}, ${this.props.rowData.address.state} ${this.props.rowData.address.zip_code}`).then(coor => {
          const gps_coor = {lat: parseFloat(this.props.rowData.gps_location.split(',')[0]), lng: parseFloat(this.props.rowData.gps_location.split(',')[1])}
          const dist = coor && gps_coor ? this.distance(coor.lat, coor.lng, gps_coor.lat, gps_coor.lng) : null;
          console.log("params")
          console.log(coor)
          console.log(gps_coor)
          this.setState({distanceVar: dist, coor: coor, gps_coor: gps_coor})
        });
      }
    }, 250);
  }

  handlePopoverOpen = event => {
    console.log("open popover")
    this.setState({anchorEl: event.currentTarget});
  };

  handlePopoverClose = () => {
    console.log("close popover")
    this.setState({anchorEl: null});
  };

  getGPSCoor = (address) => {
    return Geocode.fromAddress(address).then(
      response => {
        return Promise.resolve(response.results[0].geometry.location);
      },
      error => {
        console.error(error);
        return Promise.resolve(null)
      }
    );
  }


  getGPSRender = () => {

    return(
      <div>
        {this.state.coor && this.state.distanceVar ?
          <div>
            <Typography>
             Distance of service: {this.state.distanceVar.toFixed(2)} Mi
            </Typography>
            <MapContainer lat={this.state.coor.lat} lng={this.state.coor.lng} lat2={this.state.gps_coor.lat} lng2={this.state.gps_coor.lng}/>
          </div>
          :
          <Typography>
            Service coordinates not found.
          </Typography>
        }
      </div>);
  }

  /**
    Calculates haversine distance between two coordinates
  */
  distance = (lat1, lon1, lat2, lon2) => {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var radlon1 = Math.PI * lon1/180
        var radlon2 = Math.PI * lon2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        return dist
  }

  render() {
    return (
      <div>
        <Typography
          aria-owns={Boolean(this.state.anchorEl) ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={null}
          style={{color: NYC_Zipcodes.includes(parseInt(this.props.rowData.address.zip_code)) ? 'green' : 'gray'}}
        >
          {this.props.rowData.gps_location}
        </Typography>
        <Popover
          id="mouse-over-popover"
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
        <Box p={4}>
          {Boolean(this.state.anchorEl) && this.props.rowData.address ?
              <Box display="flex" alignItems="center">
                  <Box pr={2}>
                      <Typography>{NYC_Zipcodes.includes(parseInt(this.props.rowData.address.zip_code)) ?
                      `NYC service` : 'Non-NYC service'}
                      </Typography>
                      <Typography>
                       Address: {this.props.rowData.address.address1}, {this.props.rowData.address.state} {this.props.rowData.address.zip_code}
                      </Typography>
                      {this.getGPSRender()}
                  </Box>
              </Box>
          :null}
        </Box>
        </Popover>
      </div>
    );
  }
}

MouseOverPopover.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MouseOverPopover);
