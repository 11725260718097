import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {getGridData, addData, deleteData, editData, switchDashboard, createAmmendment, hardRefresh, enterModal, autoAddRow} from '../../actions'
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {OptionalSelect, LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {theme_color} from './../../tempfile'
import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon,
    ForwardIcon,
    TimelineIcon,
    MoreVertIcon,
    AlertIcon
} from 'components/table_icons';
import UploadDialog from "components/upload_dialog"
import CaseModals from 'components/case_modals'
import {rec_types} from '../../tempfile'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
const moment = require('moment');

const StyledMenu = withStyles({
  paper: {
    border: '1px solid ' + theme_color,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme_color,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const minWidth1 = 150;
const minWidth2 = 500;

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class CaseDashboard extends Component {

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      anchorEl: null,
      currentRow: null,
      title: "Cases",
      columns: [
        {"title": "Client Company", "field": "client_company",
        "filterFn" : x => {
          const client_ids = this.props.clients.filter(c => c.company.includes(x)).map(c => c.client_id).join(',');
          return `client_id IN (${client_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.client_company : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledSelect
                rowData={rowData}
                options={this.props.clients.map(c => c.company).filter(onlyUnique).map((c)=> {return {value: c, label: c}})}
                value={rowData.rowData.client_company}
                />
        },
        {"title": "Attorney", "field": "attorney",
        "filterFn" : x => {
          const client_ids = this.props.clients.filter(c => c.name.includes(x)).map(c => c.client_id).join(',');
          return `client_id IN (${client_ids})`;
        },
        render: rowData => <TooltipTypography data={rowData ? rowData.attorney : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledSelect
                rowData={rowData}
                options={this.props.clients.filter((c) => {return c.company == rowData.rowData.client_company}).map(c => c.name).filter(onlyUnique).map(c => {return {value: c, label: c}})}
                value={rowData.rowData.attorney}
                />
        },
        {"title": "Client Ref.", "field": "client_ref",
        "filterFn" : x => `client_ref LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.client_ref : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.client_ref}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Plaintiff", "field": "plt_name",
        "filterFn" : x => `plt_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.plt_name : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.plt_name}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.plt_name == ""}
                                   />
        },
        {"title": "Case No.", "field": "case_no", "defaultFilter": this.props.filters["case_no"] !== undefined ? this.props.filters["case_no"] : null,
        render: rowData => <TooltipTypography data={rowData ? rowData.case_no : ''}/>,
        "filterFn" : x => `docket_num LIKE '%${x.replace('"', '').replace('\'', '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.case_no}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.case_no == ""}
                                   />
        },
        {"title": "Lead Defendant", "field": "lead_defendant",
        "filterFn" : x => `lead_defendant LIKE %${x.replace(/[^a-zA-Z0-9]+/g, '')}%`,
        render: rowData => <TooltipTypography data={rowData ? rowData.lead_defendant : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledCreatableSelect
                                  rowData={rowData}
                                  options={this.props.cases.map(p => p.lead_defendant).filter(onlyUnique).map(p => {return {value: p, label: p}})}
                                  value={rowData.rowData.lead_defendant}
                               />
        },
        {"title": "Ammendment", "field": "ammendment", "editable":false,
        "filterFn" : x => `ammendment LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.ammendment : ''}/>},
        {"title": "Contact Name", "field": "contact_name",
        render: rowData => <TooltipTypography data={rowData ? rowData.contact_name : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledCreatableSelect
                                  rowData={rowData}
                                  options={this.props.clients.filter((c) => {return c.type != "Attorney" && c.type != "Accountant"}).map(p => p.name).filter(onlyUnique).map(p => {return {value: p, label: p}})}
                                  value={rowData.rowData.contact_name}
                               />
        },
        {"title": "Contact Email", "field": "contact_email",
        "filterFn" : x => `contact_email LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.contact_email : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledCreatableSelect
                                  rowData={rowData}
                                  options={this.props.clients.filter((c) => {return rowData.rowData.contact_name == c.name}).map(p => p.email).filter(onlyUnique).map(p => {return {value: p, label: p}})}
                                  value={rowData.rowData.contact_email}
                               />
        },
        {"title": "Date Filed", "field": "date_filed",
        "filterFn" : x => `date_filed LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.date_filed : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData =>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                  name="Date_filed"
                                  variant="inline"
                                  inputVariant="outlined"
                                  label="Date Filed"
                                  format="yyyy-MM-dd"
                                  margin="normal"
                                  autoComplete="completion"
                                  fullWidth
                                  value={rowData.rowData.date_filed}
                                  autoOk
                                  onChange={e => {
                                    return rowData.onChange(e)
                                  }}
                                  error={false}
                              />
                            </MuiPickersUtilsProvider>
        },
        {"title": "Court", "field": "court_name",
        "filterFn" : x => `court_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.court_name : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <LabelledCreatableSelect
                                  rowData={rowData}
                                  options={this.props.courts.map((court)=> {return {value: court.name, label: court.name}})}
                                  value={rowData.rowData.court_name}
                               />
        },
        //{"title": "Client Billing", "field": "client_billing", "editable":false},
        {"title": "County Name", "field": "county_name",
        "filterFn" : x => `county_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.county_name : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.county_name}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "State", "field": "state",
        "filterFn" : x => `state LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.state : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.state}
                                      onChange={e => rowData.onChange(e.target.value.substring(0,Math.min(e.target.value.length, 2)))}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Jurisdiction", "field": "jurisdiction",
        "filterFn" : x => `jurisdiction=${"Federal".includes(x) ? 0 : 1}`,
        render: rowData => <TooltipTypography data={rowData ? rowData.jurisdiction : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <OptionalSelect
                                  rowData={rowData}
                                  options={[{value: "Federal", label: "Federal"}, {value: "State", label: "State"}]}
                                  value={rowData.rowData.jurisdiction }
                               />
        },
        {"title": "District", "field": "district",
        "filterFn" : x => `district LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.district : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.district}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
      ],
      filters: {},
      actions: [
          row => ({
              icon: () =>  <MoreVertIcon/>,
              tooltip: 'More Options',
              onClick: (e, c) => {
                  console.log(e);
                  this.setState({currentRow: c})
                  this.handleClick(e)
              },
              hidden: false
          }),
      ]
    };
  }

  handleClick = (event) => {
    console.log(event.currentTarget)
    this.setState({anchorEl: event.currentTarget})
  }

  handleClose = () => {
    this.setState({anchorEl: null})
  }

  componentDidMount(){

    // Get other data needed
    this.props.getGridData("clients", {});

    if(Object.entries(this.props.filters).length > 0){
      this.setState({columns: [...this.state.columns]})
    }
  }

  render(){

    return (
      <div>
        <div>
        {this.props.clients &&
        <MaterialGrid
          title={this.state.title}
          columns={this.state.columns}
          add={true}
          remove={this.props.employee.type > 1}
          edit={true}
          refresh={true}
          id_column="case_id"
          data_type="cases"
          actions={this.state.actions}
          tableRef={this.myRef}
        />}
        <UploadDialog type="case"/>
        <CaseModals tableRef={this.myRef}/>
        </div>
        <div>
          {this.state.currentRow ?
          <StyledMenu
            id="customized-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <StyledMenuItem onClick={() => this.props.switchDashboard(2, {"case_id": this.state.currentRow.case_id.toString()})}>
              <ListItemIcon>
                <ForwardIcon/>
              </ListItemIcon>
              <ListItemText primary='See Jobs' />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => this.props.autoAddRow(this.state.currentRow.case_id.toString()).then(() => {this.props.switchDashboard(2, {})})}>
              <ListItemIcon>
                <ForwardIcon/>
              </ListItemIcon>
              <ListItemText primary='Create Job Work Order' />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              this.props.createAmmendment(this.state.currentRow.case_id).then((res) => {
                if(res){
                  // add filters so duplication can be seen clearly
                  this.props.switchDashboard(0, {})
                  this.props.switchDashboard(1, {"case_no": this.state.currentRow.case_no})
                  this.props.enqueueSnackbar('Case ammended', {variant: 'success'})
                }else{
                  this.props.enqueueSnackbar('Could not ammend case', {variant: 'error'})
                }
              })
            }}>
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary='Create ammendment' />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => this.props.enterModal("open_send_alert", {type: "case", refId: this.state.currentRow.case_id, client_id: this.state.currentRow.client_id})}>
              <ListItemIcon>
                <AlertIcon />
              </ListItemIcon>
              <ListItemText primary='Add Alert' />
            </StyledMenuItem>
          </StyledMenu>
        : null}
        </div>
       </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cases: state.get.cases,
    courts: state.get.courts,
    clients: state.get.clients,
    filters: state.update.filters,
    employee: state.auth.employee
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData,switchDashboard, createAmmendment, hardRefresh, enterModal, autoAddRow}
)(CaseDashboard))
