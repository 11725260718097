import {
	JOBS_RETURNED,
  CASES_RETURNED,
  REPORTS_RETURNED,
  DOC_PARSING_RETURNED,
  ALERTS_RETURNED,
  EMPLOYEES_RETURNED,
  CLIENTS_RETURNED,
  VENDORS_RETURNED,
  COURTS_RETURNED,
  PRODUCTS_RETURNED,
  INVOICES_RETURNED,
  STATEMENTS_RETURNED,
	STATS_RETURNED,
	NAMES_RETURNED,
	INVOICE_OPTIONS_RETURNED,
	MESSAGES_RETURNED,
	ADD_PAYMENT,
	ADDRESSES_RETURNED,
	SOFT_REFRESH,
	HARD_REFRESH,
	TASKS_RETURNED,
	DISPLAY_DOCS_RETURNED,
	ZIP_RETURNED,
	PWD_RESET,
	APPROVE_DOC_PARSING,
	RECOMMEND_SERVICE
} from '../actions/types'

import 'moment-timezone';
const moment = require('moment');

const INITIAL_STATE = {
	jobs: null,
  cases: null,
  reports: null,
  doc_parsing: null,
  alerts: null,
  employees: null,
  clients: null,
  vendors: null,
  courts: null,
  products: null,
  invoices: null,
  statements: null,
	stats: null,
	stats_update_time: null,
	codes: null,
	invoice_options: null,
	messages: null,
	addresses: null,
	tasks: null,
	display_docs: null,
	zipcodes: null,
	service_recommendations: null
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
		case JOBS_RETURNED:
			return { ...state, jobs: action.payload };
    case CASES_RETURNED:
  		return { ...state, cases: action.payload };
		case REPORTS_RETURNED:
			return { ...state, reports: action.payload };
    case DOC_PARSING_RETURNED:
			return { ...state, doc_parsing: action.payload };
    case ALERTS_RETURNED:
      return { ...state, alerts: action.payload };
    case EMPLOYEES_RETURNED:
      return { ...state, employees: action.payload };
    case CLIENTS_RETURNED:
      return { ...state, clients: action.payload };
    case VENDORS_RETURNED:
      return { ...state, vendors: action.payload };
    case COURTS_RETURNED:
      return { ...state, courts: action.payload };
    case PRODUCTS_RETURNED:
      return { ...state, products: action.payload };
    case INVOICES_RETURNED:
      return { ...state, invoices: action.payload };
    case STATEMENTS_RETURNED:
      return { ...state, statements: action.payload };
		case RECOMMEND_SERVICE:
			console.log('recommendations saved: ', action.payload)
			return { ...state, service_recommendations: action.payload};
		case STATS_RETURNED:
			const date = moment();
			const date_adj = date.tz('America/New_York').format();
      return { ...state, stats: action.payload, stats_update_time: date};
		case NAMES_RETURNED:
			console.log("got codes")
			console.log(action.payload)
			return { ...state, codes: action.payload};
		case INVOICE_OPTIONS_RETURNED:
			console.log("invoice options recorded")
			console.log(action.payload)
			return { ...state, invoice_options: action.payload};
		case MESSAGES_RETURNED:
			return { ...state, messages: action.payload};
		case ADDRESSES_RETURNED:
			return { ...state, addresses: action.payload};
		case ZIP_RETURNED:
			return { ...state, zipcodes: action.payload};
		case APPROVE_DOC_PARSING:
			const updated = [...state.cases];
			const updated2 = [...state.doc_parsing];
			updated.push(action.payload.case);
			updated2.filter(x => x.parse_id != action.payload.parse_id)
			return { ...state, cases: updated, doc_parsing: updated2 };
		case PWD_RESET:
			console.log('pwd_reset called: ')
			if(action.payload.type == "client"){
				let updated = [...state.clients];
				for(let i = 0; i < updated.length; i++){
					if(updated[i].client_id == action.payload.id){
						updated[i].pwd_reset_on = true
						console.log('update: ', updated)
					}
				}
				return { ...state, clients: updated};
			}else if(action.payload.type == "employee"){
				let updated = [...state.employees];
				for(let i = 0; i < updated.length; i++){
					if(updated[i].employee_id == action.payload.id){
						updated[i].pwd_reset_on = true
						console.log('update: ', updated)
					}
				}
				return { ...state, employees: updated};
			}else if(action.payload.type == "vendor"){
				let updated = [...state.vendors];
				for(let i = 0; i < updated.length; i++){
					if(updated[i].vendor_id == action.payload.id){
						updated[i].pwd_reset_on = true
						console.log('update: ', updated)
					}
				}
				return { ...state, vendors: updated};
			}
			return { ...state};
		case SOFT_REFRESH:
			if(action.payload.type == "jobs"){
				return { ...state, jobs: action.payload.data};
			}else if(action.payload.type == "cases"){
				return { ...state, cases: action.payload.data};
			}else if(action.payload.type == "reports"){
				return { ...state, reports: action.payload.data};
			}else if(action.payload.type == "clients"){
				console.log('clients refreshed: ', action.payload.data)
				return { ...state, clients: action.payload.data};
			}else if(action.payload.type == "vendors"){
				return { ...state, vendors: action.payload.data};
			}else if(action.payload.type == "employees"){
				return { ...state, employees: action.payload.data};
			}else if(action.payload.type == "invoices"){
				return { ...state, invoices: action.payload.data};
			}else if(action.payload.type == "statements"){
				return { ...state, statements: action.payload.data};
			}else if(action.payload.type == "docparsing"){
				return { ...state, doc_parsing: action.payload.data};
			}else if(action.payload.type == "alerts"){
				return { ...state, alerts: action.payload.data};
			}
		case ADD_PAYMENT:
			console.log("suggested changes", action.payload)
			let updated_invoices = state.invoices;
			if(action.payload.type == "Invoice"){
				// Find out how many children there are
				let total_children = 0;
				let total = Number(action.payload.payment)
				for(let i = 0; i < updated_invoices.length; i++){
					if(updated_invoices[i].parent_id != undefined && updated_invoices[i].parent_id == action.payload.id){
						total_children++;
					}
				}
				for(let i = 0; i < updated_invoices.length; i++){
					if(updated_invoices[i].parent_id != undefined && updated_invoices[i].parent_id == action.payload.id){
						if(total > 0){
							updated_invoices[i].payment = Math.min(total, updated_invoices[i].total_remaining);
						}
					}
					if(updated_invoices[i].parent_id == undefined && updated_invoices[i].id == action.payload.id){
						updated_invoices[i].payment = Number(action.payload.payment)
					}
				}
			}else{
				let parent_id = -1;
				for(let i = 0; i < updated_invoices.length; i++){
					if(updated_invoices[i].parent_id != undefined && updated_invoices[i].id == action.payload.id){
						parent_id = updated_invoices[i].parent_id
						console.log("update original child")
						updated_invoices[i].payment = Number(action.payload.payment)
					}
				}
				let total_for_parent = 0.
				for(let i = 0; i < updated_invoices.length; i++){
					if(updated_invoices[i].parent_id != undefined && updated_invoices[i].parent_id == parent_id){
						console.log("updated parent total")
						total_for_parent += updated_invoices[i].payment;
					}
				}
				for(let i = 0; i < updated_invoices.length; i++){
					if(updated_invoices[i].parent_id == undefined && updated_invoices[i].id == parent_id){
						console.log("updated parent")
						updated_invoices[i].payment = total_for_parent;
					}
				}
			}
			console.log("updated payments")
			console.log(updated_invoices)
			return { ...state, invoices: updated_invoices};
		case TASKS_RETURNED:
			console.log('tasks registered', action.payload)
			return { ...state, tasks: action.payload};
		case DISPLAY_DOCS_RETURNED:
			return { ...state, display_docs: action.payload}
		default:
			return { ...state };
  }
}
