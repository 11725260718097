import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {getGridData, approveDocParsing, togglePDFOn} from '../../actions'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import PDFDisplay from "components/pdf_display";
import { withSnackbar, closeSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import {LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon,
    AssignmentTurnedInIcon,
    AcknowledgeIcon
} from 'components/table_icons';

const num_edit_lines = 10;
const minWidth1 = 150;
const moment = require('moment');


class DocParsingDashboard extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      title: "Document Parsing",
      columns: [
        {"title": "Sender", "field": "sender_email",
        render: rowData => <TooltipTypography data={rowData ? rowData.sender_email : ''}/>,
        "filterFn" : x => `sender_email LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <TextField
                                      value={rowData.rowData.sender_email}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.sender_email == ""}
                                   />
                                 },
        {"title": "Doc. Name", "field": "file_name",
        render: rowData => <TooltipTypography data={rowData ? rowData.file_name : ''}/>,
        "filterFn" : x => `file_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <TextField
                                      value={rowData.rowData.file_name}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.file_name == ""}
                                      multiline
                                      rows={num_edit_lines}
                                      style={{textAlign: 'left'}}
                                   />
                                 },
        {"title": "Index Num.", "field": "docket_num",
        render: rowData => <TooltipTypography data={rowData ? rowData.docket_num : ''}/>,
        "filterFn" : x => `docket_num LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <TextField
                                      value={rowData.rowData.docket_num}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.docket_num == ""}
                                   />
                                 },
        {"title": "Plaintiffs", "field": "plaintiff",
        render: rowData => <TooltipTypography data={rowData ? rowData.plaintiff : ''}/>,
        "filterFn" : x => `plaintiff LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <TextField
                                      value={rowData.rowData.plaintiff}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.plaintiff == ""}
                                      multiline
                                      rows={num_edit_lines}
                                      style={{textAlign: 'left'}}
                                   />
                                 },
        {"title": "Defendants", "field": "defendant",
        render: rowData => <TooltipTypography data={rowData ? rowData.defendant : ''}/>,
        "filterFn" : x => `defendant LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <TextField
                                      value={rowData.rowData.defendant}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.defendant == ""}
                                      multiline
                                      rows={num_edit_lines}
                                      style={{textAlign: 'left'}}
                                   />
                                 },
        {"title": "Court Name", "field": "court_name",
        render: rowData => <TooltipTypography data={rowData ? rowData.court_name : ''}/>,
        "filterFn" : x => `court_name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <TextField
                                      value={rowData.rowData.court_name}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.court_name == ""}
                                      multiline
                                      rows={num_edit_lines}
                                      style={{textAlign: 'left'}}
                                   />
                                 },
        {"title": "Court Date", "field": "court_date",
        render: rowData => <TooltipTypography data={rowData ? rowData.court_date : ''}/>,
        "filterFn" : x => `court_date LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        name="Court date"
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Court date"
                                        format="MMM dd, yyyy"
                                        margin="normal"
                                        autoComplete="completion"
                                        fullWidth
                                        value={rowData.rowData.court_date}
                                        autoOk
                                        onChange={e => {
                                          return rowData.onChange(moment(e).format('MMM DD, YYYY'))
                                        }}
                                        error={false}
                                    />
                                  </MuiPickersUtilsProvider>
                                 },
        {"title": "File Date", "field": "file_date",
        render: rowData => <TooltipTypography data={rowData ? rowData.file_date : ''}/>,
        "filterFn" : x => `file_date LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        name="File Date"
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="File Date"
                                        format="MMM dd, yyyy"
                                        margin="normal"
                                        autoComplete="filed"
                                        fullWidth
                                        value={rowData.rowData.file_date}
                                        autoOk
                                        onChange={e => {
                                          return rowData.onChange(moment(e).format('MMM DD, YYYY'))
                                        }}
                                        error={false}
                                    />
                                  </MuiPickersUtilsProvider>
                                 },
        {"title": "Parsed On", "field": "completion_time",
        editable: false,
        render: rowData => <TooltipTypography data={rowData ? rowData.completion_date : ''}/>,
        "filterFn" : x => `completion_time LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        editComponent: rowData => <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        name="Completion time"
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Completion time"
                                        format="MMM dd, yyyy"
                                        margin="normal"
                                        autoComplete="completion"
                                        fullWidth
                                        value={rowData.rowData.completion_date}
                                        autoOk
                                        onChange={e => {
                                          return rowData.onChange(moment(e).format('MMM DD, YYYY'))
                                        }}
                                        error={false}
                                    />
                                  </MuiPickersUtilsProvider>
                                 },
      ],
      filters: {},
      actions: [row => ({
          icon: () => {
              return <AcknowledgeIcon />;
          },
          tooltip: 'Add Parsed Data to Records',
          onClick: (e, c) => {
              this.props.approveDocParsing(c.parse_id).then((res) => {
                if(res){
                  this.myRef.current.onQueryChange();
                  this.props.enqueueSnackbar('Doc parsing approved', {variant: 'success'})
                }else{
                  this.props.enqueueSnackbar('Client not found: Create a new client in order to parse this document.', {variant: 'error'})
                }
              })
          }
      }),
      row => ({
        icon: () => {
            return <AssignmentTurnedInIcon />;
        },
        tooltip: this.props.pdf_on ? 'Close PDF' : 'Open PDF',
        onClick: (e, c) => {
            this.props.togglePDFOn(c.url);
            console.log(this.props.doc_url);
        }
      }),
    ]
    };
  }

  componentDidMount(){
    this.props.getGridData("doc_parsing", this.state.filters);
  }

  render(){
    return (
      <div>
        {this.props.pdf_on ?
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
            <MaterialGrid
              title={this.state.title}
              columns={this.state.columns}
              add={false}
              remove={true}
              edit={true}
              refresh={true}
              id_column="parse_id"
              data_type="doc_parsing"
              actions={this.state.actions}
              tableRef={this.myRef}
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <PDFDisplay width={800}/>
            </GridItem>
          </GridContainer> :
          <MaterialGrid
            title={this.state.title}
            columns={this.state.columns}
            add={false}
            remove={true}
            edit={true}
            refresh={true}
            id_column="parse_id"
            data_type="doc_parsing"
            actions={this.state.actions}
            tableRef={this.myRef}
          />}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    doc_parsing: state.get.doc_parsing,
    pdf_on: state.update.pdf_on,
    doc_url: state.update.doc_url
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, approveDocParsing, togglePDFOn}
)(DocParsingDashboard))
