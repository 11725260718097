import {
	JOBS_EDITED,
  CASES_EDITED,
  REPORTS_EDITED,
  DOC_PARSING_EDITED,
  ALERTS_EDITED,
  EMPLOYEES_EDITED,
  CLIENTS_EDITED,
  VENDORS_EDITED,
  COURTS_EDITED,
  PRODUCTS_EDITED,
  INVOICES_EDITED,
  STATEMENTS_EDITED,
	EDIT_SELECTION,
	CHARGES_EDITED,
	VENDOR_PRICING_EDITED
} from '../actions/types'

import React from 'react';

const INITIAL_STATE = {
	editingSuccess: false
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
    case JOBS_EDITED:
      return { ...state, editingSuccess: action.payload };
    case CASES_EDITED:
      return { ...state, editingSuccess: action.payload };
    case REPORTS_EDITED:
      return { ...state, editingSuccess: action.payload };
    case DOC_PARSING_EDITED:
      return { ...state, editingSuccess: action.payload };
    case ALERTS_EDITED:
      return { ...state, editingSuccess: action.payload };
    case EMPLOYEES_EDITED:
      return { ...state, editingSuccess: action.payload };
    case CLIENTS_EDITED:		
      return { ...state, editingSuccess: action.payload };
    case VENDORS_EDITED:
      return { ...state, editingSuccess: action.payload };
    case COURTS_EDITED:
      return { ...state, editingSuccess: action.payload };
    case PRODUCTS_EDITED:
      return { ...state, editingSuccess: action.payload };
    case INVOICES_EDITED:
      return { ...state, editingSuccess: action.payload };
    case STATEMENTS_EDITED:
      return { ...state, editingSuccess: action.payload };
		case EDIT_SELECTION:
			return { ...state, editingSuccess: action.payload };
		case CHARGES_EDITED:
			return { ...state, editingSuccess: action.payload };
		case VENDOR_PRICING_EDITED:
			return { ...state, editingSuccess: action.payload };
    default:
      return { ...state };
  }
}
