import {
	JOBS_ADDED,
  CASES_ADDED,
  REPORTS_ADDED,
  DOC_PARSING_ADDED,
  ALERTS_ADDED,
  EMPLOYEES_ADDED,
  CLIENTS_ADDED,
  VENDORS_ADDED,
  COURTS_ADDED,
  PRODUCTS_ADDED,
  INVOICES_ADDED,
  STATEMENTS_ADDED,
	MESSAGES_ADDED,
	DOCS_ADDED,
	EXCEL_ADDED,
	AFFIDAVITS_ADDED,
	VENDOR_PRICING_ADDED
} from '../actions/types'

import React from 'react';

const INITIAL_STATE = {
	addingSuccess: false
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
    case JOBS_ADDED:
      return { ...state, addingSuccess: action.payload };
    case CASES_ADDED:
      return { ...state, addingSuccess: action.payload };
    case REPORTS_ADDED:
      return { ...state, addingSuccess: action.payload };
    case DOC_PARSING_ADDED:
      return { ...state, addingSuccess: action.payload };
    case ALERTS_ADDED:
      return { ...state, addingSuccess: action.payload };
    case EMPLOYEES_ADDED:
      return { ...state, addingSuccess: action.payload };
    case CLIENTS_ADDED:
      return { ...state, addingSuccess: action.payload };
    case VENDORS_ADDED:
      return { ...state, addingSuccess: action.payload };
    case COURTS_ADDED:
      return { ...state, addingSuccess: action.payload };
    case PRODUCTS_ADDED:
      return { ...state, addingSuccess: action.payload };
    case INVOICES_ADDED:
      return { ...state, addingSuccess: action.payload };
    case STATEMENTS_ADDED:
      return { ...state, addingSuccess: action.payload };
		case MESSAGES_ADDED:
			return { ...state, addingSuccess: action.payload };
		case DOCS_ADDED:
			return { ...state, addingSuccess: action.payload };
		case EXCEL_ADDED:
			return { ...state, addingSuccess: action.payload };
		case AFFIDAVITS_ADDED:
			return { ...state, addingSuccess: action.payload };
		case VENDOR_PRICING_ADDED:
			return { ...state, addingSuccess: action.payload };
    default:
      return { ...state };
  }
}
