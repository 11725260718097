import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {getGridData, addData, deleteData, editData, updateRowSelection, softRefresh} from '../actions'
import { withSnackbar, closeSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import {theme_color} from '../tempfile'


import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon,
    AddBoxIcon,
    ArrowUpwardIcon,
    CheckIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClearIcon,
    DeleteOutlineIcon,
    EditIcon,
    FilterListIcon,
    FirstPageIcon,
    LastPageIcon,
    RemoveIcon,
    SaveAltIcon,
    SearchIcon,
    ViewColumnIcon,
} from 'components/table_icons';

import { forwardRef } from 'react';

export const theme = createMuiTheme({
  palette: {
    primary: {
        main: theme_color
    },
    secondary: {
        main: theme_color
    },
  }
});


class MaterialGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  getDifferenceArray(old_, new_){
    const diff = {  }

    Object.keys(old_).map(function (key) {
        if(old_[key] !== new_[key] && key !== "tableData" && key !== "address"){
          diff[key] = new_[key]
        }
    })
    return diff;
  }


  render(){
    return (
      <div style={{flex: 1, alignItems: 'center', justifyContent: 'center', width: this.props.width}}>
        {this.props.columns !== null && this.props.title !== null ?
          <ThemeProvider theme={theme}>
          <MaterialTable
            tableRef={this.props.tableRef}
            icons={TableIcons}
            columns={this.props.columns}
            title={this.props.title}
            data={query =>
              new Promise((resolve, reject) => {
                //console.log('QUERY: ', query);
                this.props.getGridData(this.props.data_type, {pageSize: query.pageSize, page: query.page, list: query.filters.map(q =>  q.column.filterFn(q.value))}).then(result => {
                  //console.log('result for type ', this.props.data_type, ':', result)
                  resolve({
                    data: result ? result.data : [],
                    page: query.page,
                    totalCount: result ? result.total : 0,
                  })
                })
              })
            }
            options={{
              addRowPosition: 'first',
              selection: this.props.batch_actions_on,
              editable: !this.props.batch_actions_on,
              exportButton: true,
              filtering: true,
              search: false,
              actionsHeaderIndex: 1,
              pageSize: this.props.pageSize ? this.props.pageSize : 50,
              pageSizeOptions: [20, 50, 100, 200, 1000, 10000, 100000],
              headerStyle: {
                backgroundColor: theme_color,
                color: 'white',
                position: 'sticky',
                top: 0
              },
              maxBodyHeight: this.props.maxHeight ? this.props.maxHeight : '650px',
              toolbarButtonAlignment: 'left'
            }}
            onSelectionChange={(rows) => this.props.updateRowSelection(rows)}
            editable={{
              onRowAdd: this.props.add ? (
                newData =>
                new Promise((resolve, reject, onCancel) => {
                  setTimeout(() => {
                    try {
                      this.props.addData(this.props.data_type, newData).then((res) => {
                        if(res){
                          this.props.enqueueSnackbar('Row added successfully', {variant: 'success'})
                          this.props.tableRef.current && this.props.tableRef.current.onQueryChange()
                        }else{
                          this.props.enqueueSnackbar('Could not add row', {variant: 'error'})
                          throw "Could not add row";
                        }
                      });
                    } catch(error) {
                      this.props.enqueueSnackbar('Could not add row', {variant: 'error'})
                      reject({ reason: 'cancelled' });
                    }
                    resolve();
                  }, 600);
                })
              ) : null,
              onRowUpdate: this.props.edit ? (
                (newData, oldData) =>
                new Promise((resolve, reject, onCancel) => {
                  setTimeout(() => {
                    try{
                      const changedData = this.getDifferenceArray(oldData, newData);
                      const id = oldData[this.props.id_column]
                      // array needed because editing endpoint requires batch editing elsewhere
                      this.props.editData(this.props.data_type, changedData, [id]).then((res) => {
                        if(res){
                          this.props.enqueueSnackbar('Row edited successfully', {variant: 'success'})
                          this.props.tableRef.current && this.props.tableRef.current.onQueryChange()
                        }else{
                          this.props.enqueueSnackbar('Could not edit row', {variant: 'error'})
                          throw "Could not edit row";
                        }
                      });
                    } catch(error) {
                      this.props.enqueueSnackbar('Could not edit row', {variant: 'error'})
                      reject({ reason: 'cancelled' });
                    }
                  resolve();
                  }, 600);
                })
              ) : null,
              onRowDelete: this.props.remove ? (
                oldData =>
                new Promise((resolve, reject, onCancel) => {
                  setTimeout(() => {
                    try{
                      //console.log(oldData);
                      oldData['id'] = oldData[this.props.id_column]
                      this.props.deleteData(this.props.data_type, oldData).then((res) => {
                        if(res){
                          this.props.enqueueSnackbar('Row deleted successfully', {variant: 'success'})
                          this.props.tableRef.current && this.props.tableRef.current.onQueryChange()
                        }else{
                          this.props.enqueueSnackbar('Could not delete row', {variant: 'error'})
                          throw "Could not delete row";
                        }
                      });
                    } catch(error) {
                      this.props.enqueueSnackbar('Could not delete row', {variant: 'error'})
                      reject({ reason: 'cancelled' });
                    }
                    resolve();
                  }, 600);
                })
              ): null
            }}
            icons={TableIcons}
            actions={this.props.batch_actions_on ? null : this.props.actions}
          />
          </ThemeProvider>
        :null}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    batch_actions_on: state.update.batch_actions_on,
    rows_selected: state.update.rows_selected
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, updateRowSelection, softRefresh}
)(MaterialGrid))
