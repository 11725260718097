import React, { Component } from 'react';
import { connect } from 'react-redux'
import {getGridData, addData, deleteData, editData, addExcel, enterModal, addAlerts} from '../actions'
import FormControl from '@material-ui/core/FormControl';

import DragAndDrop from './drag_and_drop'
import { withSnackbar, closeSnackbar } from 'notistack';

import {
    Button,
    Link,
    TextField,
    Container,
    CircularProgress,
    Select,
    MenuItem,
    GridItem,
    GridContainer,
    InputLabel
} from '@material-ui/core';
import {theme_color} from '../tempfile'

import Modal from '@material-ui/core/Modal';

import Error from '@material-ui/icons/Error';

import { forwardRef } from 'react';

import PDFDisplay from "components/pdf_display";

import UploadDialog from "components/upload_dialog"

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table';
import { Formik } from 'formik';


import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon
} from 'components/table_icons';

import Divider from '@material-ui/core/Divider';

class VendorModals extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error_message: "",
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      files: [],
      index: 0,
      has_options: false,
      loading: false,
      alert_text: ""
    };
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  handleSubmitAlert = () => {
    this.props.addAlerts({
      message: this.state.alert_text,
      type: "case",
      ref_id: this.props.send_alert_info.refId,
      client_id: this.props.send_alert_info.client_id
    }).then(res => {
      if(res){
        this.props.enqueueSnackbar('Alert posted', {variant: 'success'})
        this.handleClose();
      }else{
        this.props.enqueueSnackbar('Alert not posted', {variant: 'error'})
      }
    })
  }

  handleClose = () => {
    this.setState({
      error_message: "",
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      files: [],
      index: 0,
      has_options: false
    });
    this.props.enterModal("dismiss");
  }

  handleSelectChange = (event) => {
    this.setState({dropdown_value: event.target.value});
  };


  handleInputChange = (event) => {
    this.setState({input_value: event.target.value});
  }

  handleAlertChange = (event) => this.setState({alert_text: event.target.value})

  uploadButtonPressed = () => {

    console.log()

  }

  onDrop = (files) => {
    this.setState({loading: true})
    console.log("onDrop called")
    console.log(files[0])
    const reader = new FileReader();
    reader.onload = (event) => {
      let bytes = event.target.result.split(',')[1]
      console.log("bytes:__")
      console.log(bytes);
      this.props.addExcel({file_name: files[0].name, bytes: bytes}).then((res) => {
        this.setState({loading: false})
        if(res.success){
          this.props.tableRef.current.onQueryChange();
          this.props.enqueueSnackbar(`Excel uploaded successfully: ${res.message}`, {variant: 'success'})
        }else{
          this.props.enqueueSnackbar('Excel upload failed', {variant: 'error'})
        }
      })
    };
    reader.readAsDataURL(files[0]);
  }

  render(){

    return(
      <div>
        {/* Excel Modal */}
        <Modal
            open={this.props.excel_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paper}>
            <h2 style={{marginBottom: 20}}>Upload Excel</h2>
            {this.state.loading ?
              <CircularProgress style={{marginTop: 50}}/>
                :
              <DragAndDrop onDrop={this.onDrop} accept='.xls, .xlsx'/>
            }
          </div>
        </Modal>

        {/* Add alert */}
        <Modal
            open={this.props.open_send_alert}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperMini}>
              <h2>Add Alert</h2>
              {this.props.send_alert_info ?
                <p><b>Case selected:</b> {this.props.send_alert_info.refId}</p>
                :null}
              <div styles={{width: '80%'}}>
                <TextField
                  value={this.state.alert_text}
                  label="Alert Message"
                  fullWidth
                  onChange={this.handleAlertChange} />
                <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{backgroundColor: theme_color, marginTop: 30}}
                    onClick={this.handleSubmitAlert}
                >
                Submit
                </Button>
              </div>
            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cases: state.get.cases,
    index: state.update.index,
    excel_modal_on: state.update.excel_modal_on,
    open_send_alert: state.update.open_send_alert,
    send_alert_info: state.update.send_alert_info
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, addExcel, enterModal, addAlerts}
)(VendorModals))

const contStyles = {
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 700,
    height: 500,
    backgroundColor: '#fff',
    border: '2px solid #000',
    overflowY: 'scroll',
  },
  paperMini: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 400,
    height: 300,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  form: {
    margin: 30,
    minWidth: 120,
  },
  button: {
    marginTop: 60
  },
  dnd: {
    width: 550,
    height: 100,
    margin: 10
  }
}
