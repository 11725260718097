import {
	JOBS_DELETED,
  CASES_DELETED,
  REPORTS_DELETED,
  DOC_PARSING_DELETED,
  ALERTS_DELETED,
  EMPLOYEES_DELETED,
  CLIENTS_DELETED,
  VENDORS_DELETED,
  COURTS_DELETED,
  PRODUCTS_DELETED,
  INVOICES_DELETED,
  STATEMENTS_DELETED,
	VENDOR_PRICING_DELETED,
	SOFT_REFRESH
} from './types'

import axios from 'axios';
import { ServerPath } from '../tempfile';

export const deleteData = (type, item) => {
	return (dispatch, getState) => {
		switch (type){
			case "jobs":
        return dispatch(deleteJobs(item));
				break;
			case "cases":
        return dispatch(deleteCases(item));
				break;
			case "reports":
				return dispatch(deleteReports(item));
				break;
			case "alerts":
        return dispatch(deleteAlerts(item));
				break;
			case "employees":
        return dispatch(deleteEmployees(item));
				break;
			case "clients":
        return dispatch(deleteClients(item));
				break;
			case "vendors":
        return dispatch(deleteVendors(item));
				break;
			case "courts":
        return dispatch(deleteCourts(item));
				break;
			case "products":
        return dispatch(deleteProducts(item));
				break;
			case "invoices":
        return dispatch(deleteInvoices(item));
				break;
			case "statements":
        return dispatch(deleteStatements(item));
				break;
			case "doc_parsing":
				return dispatch(deleteDocParsing(item))
				break;
			case "vendor_pricing":
				return dispatch(deleteVendorPricing(item))
				break;
			case "alerts":
				console.log("this called")
				return dispatch(deleteAlerts(item))
				break;
		}
  }
}

export const deleteJobs = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "job"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteEntity`, data).then(response => {
				console.log(response)
				dispatch({ type: JOBS_DELETED, payload: response.data.success });
        return Promise.resolve(response.data.success);
    });
  }
}

export const deleteCases = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "case"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteEntity`, data).then(response => {
			console.log(response)
			dispatch({ type: CASES_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteReports = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "report"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteEntity`, data).then(response => {
			console.log(response)
			dispatch({ type: REPORTS_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteAlerts = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const { alerts } = getState().get;
		const data = {
			token: apiToken,
			alert_id: item.id
		};
		console.log('delAlerts[data]:', data)
    return axios.post(ServerPath + `/api/completeAlert`, data).then(response => {
			console.log(response)
			dispatch({ type: ALERTS_DELETED, payload: response.data.success });

			let updated = [...alerts];
			updated = updated.filter(x => x.alert_id != item.id);

			dispatch({ type: SOFT_REFRESH, payload: {type: "alerts", data: updated} });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteEmployees = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "employee"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteProfile`, data).then(response => {
			console.log(response)
			dispatch({ type: EMPLOYEES_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteClients = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "client"
		};
		console.log('deleteClient[data]: ', data)
    return axios.post(ServerPath + `/api/deleteProfile`, data).then(response => {
			console.log(response)
			dispatch({ type: CLIENTS_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteVendors = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "vendor"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteProfile`, data).then(response => {
			console.log(response)
			dispatch({ type: VENDORS_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteCourts = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "court"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteEntity`, data).then(response => {
			console.log(response)
			dispatch({ type: COURTS_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteProducts = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "product"
		};
		console.log('deleteProduct: ', data)
    return axios.post(ServerPath + `/api/deleteEntity`, data).then(response => {
			console.log('deleteProduct: ', response)
			dispatch({ type: PRODUCTS_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteInvoices = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "invoice"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteEntity`, data).then(response => {
			console.log(response)
			dispatch({ type: INVOICES_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteStatements = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "statement"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteEntity`, data).then(response => {
			console.log(response)
			dispatch({ type: STATEMENTS_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteDocParsing = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			id: item.id,
			type: "doc_parsing"
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteEntity`, data).then(response => {
			console.log(response)
			dispatch({ type: DOC_PARSING_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteVendorPricing = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			vendor_id: item.id,
			zip_codes: item.zip_codes
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteVendorPricing`, data).then(response => {
			console.log(response)
			dispatch({ type: VENDOR_PRICING_DELETED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const deleteDoc = (path) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			path: path,
		};
		console.log(data)
    return axios.post(ServerPath + `/api/deleteDoc`, data).then(response => {
			console.log(response)
      return Promise.resolve(response.data.success);
    });
  }
}
