import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'
import {getGridData, addData, deleteData, editData, addAlerts, toggleBatchActions, openJobsPDFViewer, toggleInvoiceOrStatement, enterModal, approveCharges, updateIndex,editJobAddress,recommendService, assignVendor, uploadQRAffidavits, deleteDoc, getJobDocs} from '../actions'

import DragAndDrop from './drag_and_drop'
import MaterialGrid from '../components/material_grid'

import Modal from '@material-ui/core/Modal';

import GridItem from "./Grid/GridItem.js";
import GridContainer from "./Grid/GridContainer.js";
import Select from '@material-ui/core/Select';
import Select2 from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';

import { forwardRef } from 'react';
import { withStyles } from '@material-ui/core/styles';

import PDFDisplay from "components/pdf_display";
import PDFDisplayForJobs from "components/jobs_pdf_display"

import UploadDialog from "components/upload_dialog"

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import {theme_color, iconColor} from '../tempfile'

import Error from '@material-ui/icons/Error';
import { Formik } from 'formik';

import { withSnackbar, closeSnackbar } from 'notistack';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon,
    PreviewIcon,
    DeleteIcon,
    DoneActionIcon
} from 'components/table_icons';

import Divider from '@material-ui/core/Divider';
import {rec_types} from '../tempfile'

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const BlueCheckbox = withStyles({
  root: {
    color: iconColor,
    '&$checked': {
      color: iconColor,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

class JobModals extends Component {

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      error_message: "",
      dropdown_value: "",
      dropdown_value2: "case",
      input_value: "",
      keys: [],
      message: "",
      buttonMessage: "Make changes",
      files: [],
      affidavit_file: null,
      dnd_message: "Drop Here! :)",
      efiling_no: "",
      efiling_on: false,
      awaiting_original: false,
      awaiting_efiling: false,
      index: 0,
      has_options: false,
      invalidForm: false,
      current_job_doc: null,
      new_address: {address1: "", address2: "", address3: "", city: "", state: "", zip_code: "", type: ""},
      alert_text: "",
      ignored_vendors: [],
      loading: false,
      aff_type: 0
    };
  }

  componentDidMount(){

    document.addEventListener("keydown", this.handleKeyPress, false);

    if(this.props.clients !== null && this.props.current_clients !== null){
        for(let i = 0; i < this.props.clients.length; i++){
          if(this.props.clients[i].client_id == this.props.current_clients){
            this.setState({awaiting_original: this.props.clients[i].mail_original_default, awaiting_efiling: this.props.clients[i].efiling_default})
          }
        }
    }
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }


  handleClose = () => {
    this.setState({
      error_message: "",
      dropdown_value: "",
      dropdown_value2: "",
      input_value: "",
      keys: [],
      message: "",
      buttonMessage: "Make changes",
      files: [],
      affidavit_file: null,
      dnd_message: "Drop Here! :)",
      efiling_no: "",
      affidavitFileDate: "",
      current_job_doc: null,
      efiling_on: false,
      awaiting_original: false,
      awaiting_efiling: false,
      ignored_vendors: [],
      loading: false,
      aff_type: 0
    })
    this.props.enterModal("dismiss");
  }

  handleDrop = (files) => {
    this.setState({files: files[0], dnd_message: "File Selected"})
  }

  handleSelectChange = (event) => {
    this.setState({dropdown_value: event.target.value});
  };

  handleSubmitAlert = () => {
    this.props.addAlerts({
      message: this.state.alert_text,
      type: "job",
      ref_id: this.props.send_alert_info.refId,
      client_id: this.props.send_alert_info.client_id
    }).then(res => {
      if(res){
        this.props.enqueueSnackbar('Alert posted', {variant: 'success'})
        this.handleClose();
      }else{
        this.props.enqueueSnackbar('Alert not posted', {variant: 'error'})
      }
    })
  }


  handleInputChange = (event) => {
    this.setState({input_value: event.target.value});
  }

  submitAffidavit = (event) => {
    console.log("submit affidavit")
    this.props.enqueueSnackbar('Affidavit uploaded', {variant: 'success'})
  }

  handleEfilingNoChange = (event) => {
    this.setState({efiling_no: event.target.value})
  }

  handleInvoiceTypeSelection = (event) => {
    this.setState({dropdown_value2: event.target.value, has_options: false});
    this.props.getGridData("invoice_options", {type: event.target.value});
    const option_check = setInterval(() => {
      console.log(this.props.invoice_options)
      if(this.props.invoice_options !== null){
				clearInterval(option_check);
        console.log("options")
        console.log(this.props.invoice_options)
        this.setState({
          has_options: true
        });
      }
    }, 250);
  }

  submitNewCharge = (event) => {
    const product_selected = this.props.products.filter((item)=>{
      return this.state.dropdown_value === item.tag
    })[0]
    const job = this.props.jobs[this.props.index];
    this.props.editData("charges", [...job.charges, {name: product_selected.name, value: product_selected.default_price}], [job.job_id])
    this.props.jobs[this.props.index].charges = [...job.charges, {name: product_selected.name, value: product_selected.default_price}]
    this.setState({dropdown_value: ""});
    this.props.enqueueSnackbar('Charge added', {variant: 'success'})
  }

  submitChargesUpdate(charges) {
    const job = this.props.jobs[this.props.index];
    this.props.editData("charges", [...charges], [job.job_id])
    this.props.jobs[this.props.index].charges = [...charges]
    this.setState({dropdown_value: ""});
  }

  handleAffidavitDateEntryChange = (event) => {
    this.setState({affidavitFileDate: event.target.value})
  }

  uploadAffidavit = (event) => {
    this.props.enterModal("docs", this.props.current_jobs);
  }

  approveCharges = () => {
    this.props.approveCharges(this.props.jobs[this.props.index]).then(()=>{
      console.log('ref: ', this.props.myRef);
      this.props.myRef.onQueryChange();
    })
    this.props.enqueueSnackbar('Charges approved', {variant: 'success'})
  }

  setNextAffidavitState(type, event){
    if(type === "efile" && !this.state.efiling_on){
      this.setState({efiling_on: true, awaiting_efiling: false, awaiting_original: false });
    }else if(type === "awaiting_efile" && !this.state.awaiting_efiling){
      this.setState({efiling_on: false, awaiting_efiling: true, awaiting_original: false });
    }else if(type === "awaiting_original" && !this.state.awaiting_original){
      this.setState({efiling_on: false, awaiting_efiling: false, awaiting_original: true });
    }else{
      this.setState({efiling_on: false, awaiting_efiling: false, awaiting_original: false });
    }
  }

  makeEdit = () => {
    if(this.state.buttonMessage == "Make changes"){
      this.setState({buttonMessage: "Confirm", message: `You are about to change column ${this.state.dropdown_value} to value \"${this.state.input_value}\" on ${this.props.rows_selected.length} rows. Do you wish to continue?`})
    }else{
      const changes = [{[this.state.dropdown_value]: this.state.input_value}]
      const ids = this.props.rows_selected.map((row) =>{return row[this.props.id_column]})
      this.props.editData(changes, ids).then((res) => {
        if(res){
          this.props.enqueueSnackbar('Rows updated', {variant: 'success'})
        }else{
          this.props.enqueueSnackbar('Could not update rows', {variant: 'error'})
        }
      })
    }
  }

  handleKeyPress = (event) => {
    console.log(this.props.jobs)
    if(event.which === 37){ // Left
      console.log("left")
      if(this.props.index > 0){
        console.log(this.props.index-1)
        this.props.updateIndex(this.props.index-1);
      }
    }else if(event.which === 39){ // Right
      console.log("right")
      if(this.props.jobs.length - 1 > this.props.index){
        console.log(this.props.index+1)
        this.props.updateIndex(this.props.index+1);
      }
    }
  }

  handleIgnoredVendors = (event) => this.setState({ignored_vendors: event})

  runSearchAlgorithm = (snack, already_chosen) => {

    if(this.props.jobs.filter(x => x.assigned == "" && x.job_status == "Received" && !already_chosen.includes(x.job_id)).length == 0){
      this.props.enqueueSnackbar('No new jobs to assign', {variant: 'info'})
      return;
    }
    // Can only assign received jobs with no assignment
    const job_ids = this.props.jobs.filter(x => x.assigned == "" && x.job_status == "Received" && !already_chosen.includes(x.job_id)).map(x => x.job_id);
    const vetos = this.state.ignored_vendors.map(x => x.value);

    this.props.recommendService(job_ids, vetos).then(res => {
      if(snack){
        if(res){
          this.props.enqueueSnackbar('Vendor search running', {variant: 'success'})
        }else{
          this.props.enqueueSnackbar('Vendor search could not run', {variant: 'error'})
        }
      }
    })
  }

  handleChange = (type, event) => {
    if(type == "aff_normal"){
      this.setState({aff_type: 0})
    }else if(type == "aff_efile"){
      this.setState({aff_type: 1})
    }else{
      this.setState({aff_type: 2})
    }
  }

  handleChangeAddressSelection = (new_address) => {
    console.log("Changed!")
    console.log(new_address.value)
    this.setState({new_address: new_address.value});
  }

  addressChange = () => {
    this.props.enqueueSnackbar('Address updated', {variant: 'success'})
  }

  handleChangeAddress1 = (event) => {
    this.setState({new_address: {...this.state.new_address, address1: event.target.value}})
  }

  handleChangeAddress2 = (event) => {
    console.log("this called")
    this.setState({new_address: {...this.state.new_address, address2: event.target.value}})
  }

  handleChangeAddress3 = (event) => {
    this.setState({new_address: {...this.state.new_address, address3: event.target.value}})
  }

  handleChangeCity = (event) => {
    this.setState({new_address: {...this.state.new_address, city: event.target.value}})
  }

  handleChangeState = (event) => {
    this.setState({new_address: {...this.state.new_address, state: event.target.value}})
  }

  handleChangeZip = (event) => {
    this.setState({new_address: {...this.state.new_address, zip_code: event.target.value}})
  }

  handleChangeType = (event) => {
    this.setState({new_address: {...this.state.new_address, type: event.value}})
  }

  handleAlertChange = (event) => this.setState({alert_text: event.target.value})

  handleSubmitAddress = () => {

      this.props.editJobAddress(this.state.new_address,
        this.props.rows_selected.map((row) => row.job_id)).then((res) => {
          if(res){
            this.props.enqueueSnackbar(`Address changed on ${this.props.rows_selected.length} job${this.props.rows_selected.length > 1 ? 's': ''}`, {variant: 'success'})
          }else{
            this.props.enqueueSnackbar('Failed to change address', {variant: 'error'})
          }
        })

  }

  onDrop = (files) => {
    let bytes = [];

    this.setState({loading: true})
    console.log("onDrop called")
    for(let i = 0; i < files.length; i++){
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log(event)
        bytes = [...bytes, event.target.result.split(',')[1]]
        console.log("bytes:__")
        console.log(bytes);
      }
      reader.readAsDataURL(files[i]);
    }

    const interval = setInterval(() => {
      if(bytes.length == files.length){
        clearInterval(interval);
        this.props.uploadQRAffidavits(bytes, this.state.aff_type).then((res) => {
          this.setState({loading: false})
          if(res.success){
            this.props.enqueueSnackbar(`Affidavit upload successfully: ${res.message}`, {variant: 'success'})
          }else{
            this.props.enqueueSnackbar('Affidavit upload failed', {variant: 'error'})
          }
        })
      }
    }, 200)
  }


  render(){
    let address = this.state.new_address
    let curr_address = (address.address1 == "" ? "" : address.address1 + ", ") +
    (address.address2 == "" ? "" : address.address2 + ", " + (address.address3 == "" ? "" : address.address3 + ", "))
    + address.city + " " + address.state + " " + address.zip_code

    return(
      <div>

      {/* Generate Invoices */}
      <Modal
          open={this.props.invoice_menu_on}
          onClose={this.handleClose}
          style={contStyles.container}
        >
          <div style={contStyles.paperInv}>
            <div style={{
              display: 'flex',
              justifyContent: "center",
              alignItems: "center",
              flexDirection: 'row',
              width: 1400,
              height: 50,
              marginTop: 20
              }} >
              <h2>Invoice Options</h2>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: 'row',
              width: 1360,
              height: 550,
              marginRight: 40,
              marginLeft: 100,
              }}
            >
              <div style={{flex: 1}}>
                <FormControl>
                <InputLabel>Select an invoice category</InputLabel>
                <Select
                  value={this.state.dropdown_value2}
                  onChange={this.handleInvoiceTypeSelection}
                  label="Invoice category"
                  style={{width: 550}}
                  >
                  <MenuItem value="job">by Job</MenuItem>
                  <MenuItem value="case">by Case</MenuItem>
                  <MenuItem value="client">by Client</MenuItem>
                  <MenuItem value="plaintiff">by Case Ref.</MenuItem>
                </Select>
                <MaterialTable
                  columns={[
                    {"title": "Client Name", "field": "client_name"},
                    {"title": "Type", "field": "type", render: rowData => rowData.type.toUpperCase()},
                    {"title": "ID Number", "field": "id"},
                    {"title": "Num. Jobs Included", "field": "num_jobs"},
                  ]}
                  title={"Invoices to Generate"}
                  data={this.state.has_options && this.props.invoice_options ? this.props.invoice_options : []}
                  options={{
                    addRowPosition: 'first',
                    pageSize: 10,
                    pageSizeOptions: [10],
                    search: false,
                    toolbar: true,
                    filtering: true,
                    headerStyle: {
                      width: '100%',
                      backgroundColor: theme_color,
                      color: 'white',
                    },
                  }}
                  style={{width: 550}}
                  icons={TableIcons}
                  actions={[
                    row => ({
                        icon: () => <InvoiceIcon />,
                        tooltip: 'Generate',
                        onClick: (e, c) => {
                            console.log("clicked generate");
                            this.props.addData("invoices", {...c, is_preview: false }).then(res => {
                              if(res){
                                this.handleInvoiceTypeSelection({target: {value: this.state.dropdown_value2}})
                                this.props.enqueueSnackbar('Invoice generated', {variant: 'success'})
                              }else{
                                this.props.enqueueSnackbar('Invoice failed to generate', {variant: 'error'})
                              }
                            })
                        },
                        hidden: false
                    }),
                    row => ({
                        icon: () => <PreviewIcon />,
                        tooltip: 'Preview',
                        onClick: (e, c) => {
                            console.log("clicked preview");
                            this.props.addData("invoices", {...c, is_preview: true }).then(res => {
                              if(res){
                                this.props.enqueueSnackbar('Preview generated', {variant: 'success'})
                              }else{
                                this.props.enqueueSnackbar('Preview failed to generate', {variant: 'error'})
                              }
                            })
                        },
                        hidden: false
                    }),
                  ]}
                />
                </FormControl>
              </div>
              <div style={{flex: 1, marginLeft: 20}}>
                <FormControl>
                    <h5>Preview</h5>
                    {this.props.doc_url ?
                      <PDFDisplay width={550}/>
                      :
                      <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          width={550}
                      >
                          No Selection
                      </Box>}
                </FormControl>
              </div>
            </div>
          </div>
      </Modal>

        {/* Upload Docs */}
        <UploadDialog type="job"/>

        {/* Job Charges */}
        <Modal
            open={this.props.job_charges_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperCh}>
              <h2>Job Charges</h2>
              {this.props.jobs !== null && this.props.jobs.length > 0 && this.props.jobs[this.props.index] !== null ?
                <div>
                  <p><b>Job ID:</b> {this.props.jobs[this.props.index].job_id}</p>
                  <p><b>Case Ref.:</b> {this.props.jobs[this.props.index].case_ref}</p>
                  <p><b>Address:</b> {this.props.jobs[this.props.index].address}</p>
                  <p><b>Billing Status:</b> {this.props.jobs[this.props.index].charges_ready ? "Job Ready for Invoicing" : "Charges not approved"}</p>
                </div>
                :null}
              {this.props.jobs !== null && this.props.jobs.length > 0 && this.props.jobs[this.props.index] !== null ?
                <MaterialTable
                  columns={[
                    {"title": "Name", "field": "name"},
                    {"title": "Price", "field": "value", render: rowData => `$${(rowData.value/100.0).toFixed(2)}`,
                    editComponent: rowData => <TextField
                          name="value"
                          label="Value"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          value={(rowData.rowData.value/100.0).toFixed(2)}
                          onChange={e => rowData.onChange(parseInt(e.target.value*100))}
                      />}
                  ]}
                  data={this.props.jobs[this.props.index].charges}
                  options={{
                    addRowPosition: 'first',
                    pageSize: 5,
                    pageSizeOptions: [5],
                    search: false,
                    toolbar: false,
                    headerStyle: {
                      width: '100%',
                      backgroundColor: theme_color,
                      color: 'white',
                    },
                  }}
                  icons={TableIcons}
                  style={{width: 550}}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          let updatedCharges = this.props.jobs[this.props.index].charges.filter((item) => {
                            return item.name != oldData.name || item.value != oldData.value
                          })
                          updatedCharges = [...updatedCharges, newData]
                          console.log(newData)

                          this.submitChargesUpdate(updatedCharges);
                          this.props.enqueueSnackbar('Charges updated', {variant: 'success'})
                        }, 600);
                      }),
                    onRowDelete: (newData) =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          let updatedCharges = this.props.jobs[this.props.index].charges.filter((item) => {
                            return item.name != newData.name || item.value != newData.value
                          })
                          updatedCharges = [...updatedCharges]

                          this.submitChargesUpdate(updatedCharges);
                          this.props.enqueueSnackbar('Charges updated', {variant: 'success'})
                        }, 600);
                      }),
                  }}
                />
              :null}
              <Divider variant="middle"/>
              <div style={{alignItems: 'center'}}>
                <FormControl>
                  <h4>Add New Charge</h4>
                  {this.props.products &&
                  <Select
                    value={this.state.dropdown_value}
                    onChange={this.handleSelectChange}
                    label="Charges Available"
                    style={{width: 300}}
                    >
                    {this.props.products.map((product) => {
                      const tag = `${product.category} - ${product.name} ($${(product.default_price/100.0).toFixed(2)})`;
                      product['tag'] = tag;
                      return <MenuItem value={product.tag}>{product.tag}</MenuItem>
                    })}
                  </Select>}
                  <Button disabled={this.state.dropdown_value == ""} variant="outlined" style={{width: 300, marginTop: 50}} onClick={this.submitNewCharge}>
                    Add Charge
                  </Button>
                  {this.props.jobs && this.props.jobs[this.props.index] ?
                  <Button variant="outlined" style={{width: 300, marginTop: 50, marginBottom: 60}} onClick={this.approveCharges} disabled={this.props.jobs[this.props.index].charges_ready}>
                    Approve Charges
                  </Button> : null}
                </FormControl>
              </div>
            </div>
        </Modal>

        {/* Affidavit Upload */}
        <Modal
            open={this.props.affidavit_upload_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
          <div style={contStyles.paperAff}>
            <h2 style={{marginBottom: 20}}>Upload Affidavits</h2>
            {this.state.loading ?
              <CircularProgress style={{marginTop: 50}}/>
                :
              <div>
                <DragAndDrop onDrop={this.onDrop} accept='.png, .jpg, .pdf'/>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <BlueCheckbox checked={this.state.aff_type == 0} onChange={(event) => this.handleChange('aff_normal', event)} value="aff_type1" />
                    }
                    label="Plain"
                  />
                  <FormControlLabel
                    control={
                      <BlueCheckbox checked={this.state.aff_type == 1} onChange={(event) => this.handleChange('aff_efile', event)} value="aff_type2" />
                    }
                    label="E-Filed"
                  />
                  <FormControlLabel
                    control={
                      <BlueCheckbox checked={this.state.aff_type == 2} onChange={(event) => this.handleChange('aff_manual', event)} value="aff_type3" />
                    }
                    label="Manually Filed"
                  />
                </FormGroup>
              </div>
            }
          </div>
        </Modal>

        {/* Display docs to be served and misc docs */}
        <Modal
            open={this.props.serve_doc_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
          <div style={contStyles.paperInv}>
            <div style={{
              display: 'flex',
              justifyContent: "center",
              alignItems: "center",
              flexDirection: 'row',
              width: 1400,
              height: 50,
              margin: 40
              }} >
              <h2>Documents for this job</h2>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: "center",
              alignItems: "center",
              flexDirection: 'row',
              width: 1400,
              height: 50,
              }} >
                {this.props.display_docs && this.props.display_docs.aff.length > 0 ?
                  <Button variant="outlined" style={{width: 300, marginTop: 50, marginBottom: 60}} onClick={() => this.setState({current_job_doc: this.props.display_docs.aff[0]})}>
                    Affidavit
                  </Button>
                  :
                  <div>
                    <h4>Affidavit</h4>
                    <p style={{marginLeft: 10}}>No Affidavit available</p>
                  </div>
                }
              </div>
            <div style={{
              display: 'flex',
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: 'row',
              width: 1360,
              height: 550,
              marginRight: 40,
              marginLeft: 100,
              }}
            >
            <div style={{flex:1}}>
              <div>
                <h4>To Serve</h4>
                  <MaterialTable
                  columns={[
                    {"title": "Doc Name", "field": "filename"},
                    {"title": "Pages", "field": "pages"},
                    {"title": "Type", "field": "type"},
                  ]}
                  title={""}
                  data={this.props.display_docs ? this.props.display_docs.serve : []}
                  options={{
                    pageSize: 5,
                    pageSizeOptions: [5,10],
                    editable: false,
                    search: false,
                    toolbar: false,
                  }}
                  style={{width: 550}}
                  icons={TableIcons}
                  actions={[
                    row => ({
                        icon: () => <PreviewIcon />,
                        tooltip: 'View',
                        onClick: (e, c) => {
                          console.log("clicked preview", c);
                          // open doc
                          console.log(c);
                          this.setState({current_job_doc: c});
                        },
                        hidden: false
                    }),
                    row => ({
                        icon: () => <DeleteIcon />,
                        tooltip: 'Delete',
                        onClick: (e, c) => {
                          console.log("clicked delete", c);
                          // open doc
                          this.props.deleteDoc("docs_to_serve/job_" + this.props.display_docs.job_id + "/" + c.filename).then((success) => {
                            if(success){
                              this.props.enqueueSnackbar(`Deleted document: ${c.filename}`, {variant: 'success'})
                              this.props.getJobDocs(this.props.display_docs.job_id)
                            }else{
                              this.props.enqueueSnackbar(`Could not delete ${c.filename}`, {variant: 'error'})
                            }
                          });
                        },
                        hidden: false
                    })
                  ]}
                />
                </div>
            </div>
            <div style={{flex:1, marginLeft: 20}}>
              <h4>Miscellaneous</h4>
              <MaterialTable
                columns={[
                  {"title": "Doc Name", "field": "filename"},
                  {"title": "Pages", "field": "pages"},
                  {"title": "Type", "field": "type"},
                ]}
                title={""}
                data={this.props.display_docs ? this.props.display_docs.misc : []}
                options={{
                  pageSize: 5,
                  pageSizeOptions: [5,10],
                  editable: false,
                  search: false,
                  toolbar: false,
                }}
                style={{width: 550}}
                icons={TableIcons}
                actions={[
                  row => ({
                      icon: () => <PreviewIcon />,
                      tooltip: 'View',
                      onClick: (e, c) => {
                          console.log("clicked preview", c);
                          // open doc
                          console.log(c);
                          this.setState({current_job_doc: c});
                      },
                      hidden: false
                  }),
                  row => ({
                      icon: () => <DeleteIcon />,
                      tooltip: 'Delete',
                      onClick: (e, c) => {
                        console.log("clicked delete", c);
                        // open doc
                        this.props.deleteDoc("miscellaneous/job_" + this.props.display_docs.job_id + "/" + c.filename).then((success) => {
                          if(success){
                            this.props.enqueueSnackbar(`Deleted document: ${c.filename}`, {variant: 'success'})
                            this.props.getJobDocs(this.props.display_docs.job_id)
                          }else{
                            this.props.enqueueSnackbar(`Could not delete ${c.filename}`, {variant: 'error'})
                          }
                        });
                      },
                      hidden: false
                  }),
                ]}
              />
            </div>
            </div>
            <div style={{marginTop: 600}}>
            {this.state.current_job_doc ?
              <div>
                <h5>Preview</h5>
                <PDFDisplayForJobs width={1300} doc={this.state.current_job_doc}/>
              </div>
              : null}
            </div>
          </div>
        </Modal>

        {/* Add alert */}
        <Modal
            open={this.props.open_send_alert}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperMini}>
              <h2>Add Alert</h2>
              {this.props.send_alert_info ?
                <p><b>Job selected:</b> {this.props.send_alert_info.refId}</p>
                :null}
              <div styles={{width: '80%'}}>
                <TextField
                  value={this.state.alert_text}
                  label="Alert Message"
                  fullWidth
                  onChange={this.handleAlertChange} />
                <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{backgroundColor: theme_color, marginTop: 30}}
                    onClick={this.handleSubmitAlert}
                >
                Submit
                </Button>
              </div>
            </div>
        </Modal>

        {/* Assign Jobs */}
        <Modal
            open={this.props.assign_jobs_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperAss}>
              <h2>Job Assignment</h2>
              <div style={{width: 500, marginTop: 20}}>
                <p>Vendors to ignore</p>
                {this.props.vendors ?
                <Select2
                  value={this.state.ignored_vendors}
                  onChange={this.handleIgnoredVendors}
                  style={{margin: 10}}
                  options={this.props.vendors.map((v)=> {return {value: v.vendor_id, label: v.company}})}
                  isMulti={true}
                  styles={selectStyles}
                  ignoreAccents={false}
                />
                :null}
                <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!(this.props.jobs && this.props.vendors)}
                    style={{backgroundColor: theme_color, marginTop: 30, marginBottom: 20}}
                    onClick={() => this.runSearchAlgorithm(true, [])}
                >
                Run Algorithm
                </Button>
                {this.props.service_recommendations ?
                  <MaterialTable
                    title={"Assignment Recommendations"}
                    columns={[
                      {"title": "Address", "field": "address"},
                      {"title": "Total Jobs", "field": "num_jobs"},
                      {"title": "Vendor", "field": "name"},
                      //{"title": "Price", "field": "price", render: rowData => rowData.name !== "No Available Vendors" ? `$${(rowData.price/100.0).toFixed(2)}` : ""},
                      {"title": "Accept Assignment", "field": "vendor_id", render: rowData =>
                      rowData.name !== "No Available Vendors" ?
                        <button style={{borderWidth:0}} onClick={() => {
                          this.props.assignVendor(rowData.job_ids, rowData.vendor_id).then((res) => {
                            console.log("clicked");
                            if(res){
                              this.props.enqueueSnackbar(`Vendor assigned to ${rowData.num_jobs} jobs`, {variant: 'success'})
                              this.props.tableRef.current.onQueryChange();
                              this.runSearchAlgorithm(false, rowData.job_ids);
                            }else{
                              this.props.enqueueSnackbar('Could not assign vendor', {variant: 'error'})
                            }
                          })
                        }}><DoneActionIcon/>
                        </button>
                        : <div/>
                    }]}
                    data={this.props.service_recommendations}
                    actions={null}
                    tableRef={this.myRef}
                    icons={TableIcons}
                    options={{
                      headerStyle: {
                        width: '100%',
                        backgroundColor: theme_color,
                        color: 'white',
                      },
                      filtering: true,
                      search: false,
                      addRowPosition: 'first',
                      selection: false,
                      editable: false,
                      exportButton: true,
                      filtering: true,
                      search: false,
                      actionsHeaderIndex: 1,
                      pageSize: 10,
                      pageSizeOptions: [20, 50, 100, 200, 1000, 10000, 100000],
                      toolbarButtonAlignment: 'left'
                    }}
                  />
                  :null}
              </div>
            </div>
        </Modal>

        {/* PDF Display */}
        <Modal
            open={this.props.doc_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperXL}>
              <h2>Invoice</h2>
              <PDFDisplay width={700}/>
            </div>
        </Modal>

        {/* Change address */}
        <Modal
            open={this.props.address_modal_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperAddr}>
              <h2>Change Job Address</h2>
              {this.props.rows_selected ?
                <p> Address changes will apply to jobs: {this.props.rows_selected.map((row) => row.job_id).join(",")} </p>
              :null}
              <p> You can select an existing address </p>
              <div style={{width: '50%'}}>
              {this.props.addresses &&
              <Select2
                value={{value: curr_address, label: curr_address}}
                onChange={this.handleChangeAddressSelection}
                options={this.props.addresses.map((c)=> {
                  let shortHand = c.address1 + ", " +
                  (c.address2 == "" ? "" : c.address2 + ", " + (c.address3 == "" ? "" : c.address3 + ", "))
                  + c.city + " " + c.state + " " + c.zip_code
                  return {value: c, label: shortHand}
                }).filter(onlyUnique)}
                fullWidth
              />}
              </div>
              <p> or you can enter a new address here </p>
              <div style={{margin: 20}}>
              <Formik
                  wrap
                  render={props =>
                    <form onSubmit={props.handleSubmit}>
                      {console.log(props)}
                        {props.status && this.state.invalidForm && (
                            <Box
                                mt={5}
                                display="flex"
                                alignItems="center"
                                color="error.main"
                            >
                                <Box mr={1}>
                                    <Error />
                                </Box>
                                Your form is incomplete or has invalid entries.
                            </Box>
                        )}
                        <TextField
                            name="address1"
                            label="Address 1"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            autoComplete="address1"
                            value={this.state.new_address.address1}
                            onChange={this.handleChangeAddress1}
                        />
                        <TextField
                            label="Address 2"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            autoComplete="address2"
                            value={this.state.new_address.address2}
                            onChange={this.handleChangeAddress2}
                        />
                        <TextField
                            label="Address 3"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            autoComplete="address3"
                            value={this.state.new_address.address3}
                            onChange={this.handleChangeAddress3}
                        />

                        <TextField
                            label="City"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            autoComplete="city"
                            value={this.state.new_address.city}
                            onChange={this.handleChangeCity}
                        />

                        <TextField
                            label="State"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            autoComplete="state"
                            value={this.state.new_address.state}
                            onChange={this.handleChangeState}
                        />

                        <TextField
                            label="Zipcode"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            autoComplete="zip_code"
                            value={this.state.new_address.zip_code}
                            onChange={this.handleChangeZip}
                        />

                        <p>Address Type</p>
                        <Select2
                          value={{value: this.state.new_address.type, label: rec_types[this.state.new_address.type]}}
                          onChange={this.handleChangeType}
                          options={rec_types.map((c, i)=> {
                            return {value: i, label: c}
                          })}
                          fullWidth
                        />

                        <Button
                            size="large"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={props.isSubmitting}
                            style={{backgroundColor: theme_color, marginTop: 30}}
                        >
                        Confirm New Address
                        </Button>
                    </form>

                  }
                  onSubmit={this.handleSubmitAddress}
              />
              </div>
            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentScreen: state.update.currentScreen,
    pdf_on: state.update.pdf_on,
    mode: state.update.billing_chart,
    rows_selected: state.update.rows_selected,
    vendors: state.get.vendors,

    jobs: state.get.jobs,
    index: state.update.index,
    myRef: state.update.myRef,
    current_jobs: state.update.current_jobs,
    current_clients: state.update.current_clients,
    clients: state.get.clients,
    invoice_menu_on: state.update.invoice_menu_on,
    upload_menu_on: state.update.doc_upload_menu_on,
    efiling_menu_on: state.update.efiling_menu_on,
    affidavit_menu_on: state.update.affidavit_menu_on,
    doc_modal_on: state.update.doc_modal_on,
    job_charges_modal_on: state.update.job_charges_modal_on,
    invoice_options: state.get.invoice_options,
    invoice_preview_on: state.update.invoice_preview_on,
    products: state.get.products,
    doc_url: state.update.doc_url,
    affidavit_upload_on: state.update.affidavit_upload_on,
    assign_jobs_modal_on: state.update.assign_jobs_modal_on,

    address_modal_on: state.update.address_modal_on,
    addresses: state.get.addresses,
    serve_doc_modal_on: state.update.serve_doc_modal_on,
    display_docs: state.get.display_docs,

    jobs_pdf_on: state.update.jobs_pdf_on,
    job_docs: state.update.job_docs,

    open_send_alert: state.update.open_send_alert,
    send_alert_info: state.update.send_alert_info,

    service_recommendations: state.get.service_recommendations
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, addAlerts, toggleBatchActions, openJobsPDFViewer, toggleInvoiceOrStatement, enterModal, approveCharges, updateIndex,editJobAddress,recommendService,assignVendor,uploadQRAffidavits, deleteDoc, getJobDocs}
)(JobModals))

const contStyles = {
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: '50%',
    height: '90%',
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paperCh: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 600,
    height: 600,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paperAss: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 700,
    height: 500,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paperAff: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 700,
    height: 500,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paperMini: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 400,
    height: 300,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paperXL: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: '90%',
    height: '90%',
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paperAddr: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 600,
    height: 600,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  paperInv: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 1400,
    height: 700,
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  form: {
    margin: 30,
    minWidth: 120,
  },
  button: {
    marginTop: 60
  },
}
