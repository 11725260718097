import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {getGridData, addData, deleteData, editData, allowPwdReset, enterModal,checkEmail} from '../../actions'
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import {LabelledSelect, LabelledCreatableSelect, TooltipTypography} from 'components/select_with_label';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    RenewPwdIcon,
    BellIcon,
    AssignmentTurnedInIcon,
    ShoppingIcon
} from 'components/table_icons';

import VendorModals from 'components/vendor_modals';
import {rec_types} from '../../tempfile'

const minWidth1 = 150;

function isValidEmailAddress(address) {
  if(address === undefined){return false}
    return !! address.match(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
}


class VendorDashboard extends Component {

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      title: "Vendors",
      columns: [
        {"title": "Name", "field": "company",
        "filterFn" : x => `name LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.company : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.company}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.company == ""}
                                   />
        },
        {"title": "State", "field": "state",
        "filterFn" : x => `state LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.state : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.state}
                                      onChange={e => rowData.onChange(e.target.value.substring(0,Math.min(e.target.value.length, 2)))}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "City", "field": "city",
        "filterFn" : x => `city LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.city : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.city}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Contact", "field": "contact",
        "filterFn" : x => `contact LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.contact : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.contact}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Email", "field": "email",
        "filterFn" : x => `email LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.email : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.email}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      required
                                      id="standard-required"
                                      label="Required"
                                      error={rowData.rowData.email == "" || !isValidEmailAddress(rowData.rowData.email) || this.props.vendors.filter(x => x.email === rowData.rowData.email && x.vendor_id !== rowData.rowData.vendor_id).length > 0}
                                   />
        },
        {"title": "Phone", "field": "phone",
        "filterFn" : x => `phone LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.phone : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.phone}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Identifier", "field": "identifier",
        "filterFn" : x => `identifier LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.identifier : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.identifier}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Def. Server", "field": "def_server",
        "filterFn" : x => `def_server LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.def_server : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.def_server}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Def. License", "field": "def_license",
        "filterFn" : x => `def_license LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.def_license : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
        "editComponent": rowData => <TextField
                                      value={rowData.rowData.def_license}
                                      onChange={e => rowData.onChange(e.target.value)}
                                      id="standard"
                                      label="Optional"
                                   />
        },
        {"title": "Vendor ID", "field": "vendor_id", editable: false, defaultSort: 'desc',
        "filterFn" : x => `vendor_id LIKE '%${x.replace(/[^a-zA-Z0-9]+/g, '')}%'`,
        render: rowData => <TooltipTypography data={rowData ? rowData.vendor_id : ''}/>,
        "headerStyle": {maxWidth: minWidth1}, "cellStyle": {maxWidth: minWidth1},
      },
      ],
      filters: {},
      actions: [
        row => ({
            icon: () => {
                return <RenewPwdIcon />;
            },
            tooltip: 'Allow password reset?',
            onClick: (e, c) => {
                this.props.allowPwdReset({type: "vendor", id: c.vendor_id}).then((res) => {
                  if(res){
                    this.myRef.current.onQueryChange();
                    this.props.enqueueSnackbar('Password reset allowed', {variant: 'success'})
                  }else{
                    this.props.enqueueSnackbar('Could not allow pwd reset', {variant: 'error'})
                  }
                })
            },
            hidden: row.pwd_reset_on
        }),
        row => ({
            icon: () => {
                return <ShoppingIcon />;
            },
            tooltip: 'See pricing',
            onClick: (e, c) => {
                console.log('Vendors list: ', this.props.vendors.map(x => x.vendor_id).indexOf(c.vendor_id))
                this.props.enterModal("vendor_pricing", this.props.vendors.map(x => x.vendor_id).indexOf(c.vendor_id))
            }
        }),
      ]
    };
  }

  render(){

    return (
      <div>
          <MaterialGrid
            title={this.state.title}
            columns={this.state.columns}
            add={true}
            remove={this.props.employee.type > 1}
            edit={true}
            refresh={true}
            id_column="vendor_id"
            data_type="vendors"
            actions={this.state.actions}
            tableRef={this.myRef}
          />
          <VendorModals tableRef={this.myRef}/>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    vendors: state.get.vendors,
    employee: state.auth.employee
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {getGridData, addData, deleteData, editData, allowPwdReset, enterModal,checkEmail}
)(VendorDashboard))
