import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ResponsiveLine } from '@nivo/line'

class LineChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      raw_data: this.props.data
    };
    console.log("data loaded on chart")
    console.log(this.props.data)
  }

  componentDidMount() {

    let points = this.state.raw_data;

    let data = [{"id": "Received", "data":
      Object.keys(points.received).map(function (key) {
        return {"x": key, "y": parseInt(points.received[key])};
      }).filter((point) => point.y > 0 || points.completed[point.x] > 0)
      .map((point) => {return {x: new Date(point.x).toLocaleString("en-US", {dateStyle: "short"}), y: point.y}})
      .sort(function(a, b){
          if(new Date(a.x) < new Date(b.x)) return -1;
          if(new Date(a.x) > new Date(b.x)) return 1;
          return 0;
      })

    }, {"id": "Completed", "data":
      Object.keys(points.completed).map(function (key) {
        return {"x": key, "y": parseInt(points.completed[key])};
      }).filter((point) => point.y > 0 || points.received[point.x] > 0)
      .map((point) => {return {x: new Date(point.x).toLocaleString("en-US", {dateStyle: "short"}), y: point.y}})
      .sort(function(a, b){
          if(new Date(a.x) < new Date(b.x)) return -1;
          if(new Date(a.x) > new Date(b.x)) return 1;
          return 0;
      })
    }]
    console.log("points")
    console.log(data)

    this.setState({data: data})
  }

  render(){
    return(
      <ResponsiveLine
          data={this.state.data}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Jobs Received vs. Completed',
              legendOffset: 36,
              legendPosition: 'middle',
              "format": () => null,
          }}
          axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'count',
              legendOffset: -40,
              legendPosition: 'middle'
          }}
          colors={{ scheme: 'nivo' }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel="y"
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
              {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemBackground: 'rgba(0, 0, 0, .03)',
                              itemOpacity: 1
                          }
                      }
                  ]
              }
          ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(
  mapStateToProps,
  {}
)(LineChart)
