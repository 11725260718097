import React from "react";
import { connect } from 'react-redux'

import {
    Button
} from '@material-ui/core';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "./Grid/GridItem.js";
import GridContainer from "./Grid/GridContainer.js";
import Table from "./Table/Table.js";
import CustomTabs from "./CustomTabs/CustomTabs.js";
import Danger from "./Typography/Danger.js";
import Card from "./Card/Card.js";
import CardHeader from "./Card/CardHeader.js";
import CardIcon from "./Card/CardIcon.js";
import CardBody from "./Card/CardBody.js";
import CardFooter from "./Card/CardFooter.js";

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import WorkIcon from '@material-ui/icons/Work';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "../variables/charts.js";

import {
  switchDashboard
} from 'actions'

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const jobsDashboardIndex = 2;


function StatsHeader({stats, last_update_stats, dispatch, employee}) {
  const classes = useStyles();

  function openJobs(filters) {
    console.log(filters);
    dispatch(switchDashboard(jobsDashboardIndex, filters))
  }

  return (
    <div>
    {stats ?
      <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h3 className={classes.cardTitle}>Job Status</h3>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <WorkIcon />
              </CardIcon>
              <GridContainer>
                <GridItem xs={12} sm={4} md={2}>
                  <p className={classes.cardCategory}>Received</p>
                  <Button disabled={stats.received_jobs == 0} onClick={() => openJobs({"job_status": "Received"})} className={classes.cardTitle}><h1>{stats.received_jobs}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={4} md={2}>
                  <p className={classes.cardCategory}>Assigned</p>
                  <Button disabled={stats.assigned_jobs == 0} onClick={() => openJobs({"job_status": "Assigned"})} className={classes.cardTitle}><h1>{stats.assigned_jobs}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={4} md={2}>
                  <p className={classes.cardCategory}>Attempted</p>
                  <Button disabled={stats.attempted_jobs == 0} onClick={() => openJobs({"job_status": "Attempted"})} className={classes.cardTitle}><h1>{stats.attempted_jobs}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={4} md={6}/>
                {/*<GridItem xs={12} sm={6} md={3}>
                  <p className={classes.cardCategory}>Served</p>
                  <Button onClick={() => openJobs({"job_status": "Served"})} className={classes.cardTitle}>{stats.served_jobs}</Button>
                </GridItem>*/}
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h3 className={classes.cardTitle}>Affidavit Status</h3>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <AssignmentIndIcon />
              </CardIcon>
              <GridContainer>
                <GridItem xs={12} sm={3} md={2}>
                <p className={classes.cardCategory}>Not Received</p>
                <Button disabled={stats.not_generated == 0} onClick={() => openJobs({"affidavit_status": "Not Received", "job_status": "Served"})} className={classes.cardTitle}><h1>{stats.not_generated}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={3} md={2}>
                <p className={classes.cardCategory}>Aw. Original</p>
                <Button disabled={stats.awaiting_original == 0} onClick={() => openJobs({"affidavit_status": "Awaiting Original"})} className={classes.cardTitle}><h1>{stats.awaiting_original}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={3} md={2}>
                <p className={classes.cardCategory}>Aw. Manual Filing</p>
                <Button disabled={stats.awaiting_manual_filing == 0} onClick={() => openJobs({"affidavit_status": "Awaiting Manual Filing"})} className={classes.cardTitle}><h1>{stats.awaiting_manual_filing}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={3} md={2}>
                <p className={classes.cardCategory}>Aw. E-Filing</p>
                <Button disabled={stats.awaiting_efiling == 0} onClick={() => openJobs({"affidavit_status": "Awaiting E-Filing"})} className={classes.cardTitle}><h1>{stats.awaiting_efiling}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={3} md={2}>
                <p className={classes.cardCategory}>Aw. Mailing</p>
                <Button disabled={stats.awaiting_mailing == 0} onClick={() => openJobs({"affidavit_status": "Awaiting Mailing"})} className={classes.cardTitle}><h1>{stats.awaiting_mailing}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={3} md={2}/>
                {/*<GridItem xs={12} sm={6} md={2}>
                <p className={classes.cardCategory}>Mailed to Client</p>
                <Button onClick={() => openJobs({"affidavit_status": "Mailed to Client"})} className={classes.cardTitle}><h1>{stats.mailed_to_client_affidavits}</h1></Button>
                </GridItem>*/}
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>

      {employee.type > 0 &&
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h3 className={classes.cardTitle}>Invoice Status</h3>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AccountBalanceWalletIcon />
              </CardIcon>
              <GridContainer>
                <GridItem xs={3} sm={3} md={2}>
                <p className={classes.cardCategory}>Not Generated</p>
                <Button onClick={() => openJobs({"invoice_status": "Not Generated", "job_status": "Served", "affidavit_status": "Closed"})} className={classes.cardTitle}><h1>${(stats.not_generated_invoices/100.0).toFixed(2)}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                <p className={classes.cardCategory}>Current</p>
                <Button disabled={stats.open_invoices == 0} onClick={() => openJobs({"invoice_status": "Open"})} className={classes.cardTitle}><h1>${(stats.open_invoices/100.0).toFixed(2)}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                <p className={classes.cardCategory}>PAST-30</p>
                <Button disabled={stats.past30_invoices == 0} onClick={() => openJobs({"invoice_status": "PAST-30"})} className={classes.cardTitle}><h1>${(stats.past30_invoices/100.0).toFixed(2)}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                <p className={classes.cardCategory}>PAST-60</p>
                <Button disabled={stats.past60_invoices == 0} onClick={() => openJobs({"invoice_status": "PAST-60"})} className={classes.cardTitle}><h1>${(stats.past60_invoices/100.0).toFixed(2)}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                <p className={classes.cardCategory}>PAST-90</p>
                <Button disabled={stats.past90_invoices == 0} onClick={() => openJobs({"invoice_status": "PAST-90"})} className={classes.cardTitle}><h1>${(stats.past90_invoices/100.0).toFixed(2)}</h1></Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                <p className={classes.cardCategory}>PAST-120</p>
                <Button disabled={stats.past120_invoices == 0} onClick={() => openJobs({"invoice_status": "PAST-120"})} className={classes.cardTitle}><h1>${(stats.past120_invoices/100.0).toFixed(2)}</h1></Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>}
      </div>
      : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken,
    stats: state.get.stats,
    last_update_stats: state.get.stats_update_time,
    employee: state.auth.employee
  }
}

export default connect(
  mapStateToProps,
)(StatsHeader)
